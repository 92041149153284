  import draftToHtml from "draftjs-to-html";
import { FlatList, Image, Text, View } from "react-native";
import { TouchableOpacity } from "react-native-web";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  RedditShareButton,
  TwitterShareButton,
} from "react-share";

import { MediaListWrapper } from "./styles";
import { Typography } from "../openFlow/style";
import BackButton from "../../commonComponents/BackButton";
import { deleteComment, getPost } from "../../redux/actions/mediaHub/index";
import VimeoPlayer from "../../commonComponents/youtubePlayer/vimeoPlayer";
import YoutubePlayer from "../../commonComponents/youtubePlayer/youtubePlayer";
import Loader from "../../commonComponents/activityIndicator/activityIndicator";
import getPlatformType from "../../helpers/getPlatform";
import { COLORS } from "../../constants/colorPallete";
import CommentBox from "./CommentBox";
import { renderComments } from "./renderComments";
import WideButton from "../../commonComponents/wideButton";
import Like from "./Like";
import CustomPopUp from "../../commonComponents/customPopUp/customPopUp.web";
import Constant from "../openFlow/openFlowRedux/Constant";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Feather } from "@expo/vector-icons";
import { timeAgo } from "../../helpers/functionHelpers";
import { ODSText } from "../../../openbrix-design-system/ui/atoms/ODSText";
import { useTheme } from "@emotion/react";
import ODSButton from "../../../openbrix-design-system/ui/atoms/ODSButton/index.native";


const layout = getPlatformType();

const InfoHubDetails = (props) => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.mediaReducer?.loading);
  const [visibleComments, setVisibleComments] = useState(2);
  const post = useSelector((state) => state.mediaReducer?.post);
  const profile = useSelector((state) => state?.authReducer?.profile?.data);
  const [deleteCommentId, setDeleteComment] = useState(null);
  const { goBack, navigate } = props.navigation;
  const {colors}=useTheme();

  useEffect(() => {
    getPost1();
  }, [props?.id]);

  const getPost1 = () => {
    dispatch(
      getPost(props?.id),
    );
  };
  const shareOptions = {
    url: `${Constant.INFO_HUB}${post?.info_id}`,
    title: "Your Share Title",
    message: "Your Share Message",
  };

  function openUrl(url) {
    // Check if the URL starts with 'http://' or 'https://'
    if (!url.startsWith("http://") && !url.startsWith("https://")) {
      // If not, prepend 'http://'
      url = "http://" + url;
    }

    // Open the URL in a new window
    window.open(url);
  }

  const renderDescription = () => {
    const rawContent =
      typeof post?.description === "string" ? JSON.parse(post?.description) : post?.description;
    const text = draftToHtml(rawContent);
    return <div style={{}} dangerouslySetInnerHTML={{ __html: text }}></div>;
  };
  const onDelete = (e) => {
    setDeleteComment(e);
  };
  const style = {
    root: {
      // background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
      // borderRadius: 3,
      // border: 0,
      // boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
      marginTop: 0,
      fontColor: "pink",
    },
    copyContainer: {
      border: "1px solid white",
      background: "#454545",
    },
  };

  const renderPost = () => {
    const reversedComments = post?.infohubComments?.slice()
    ?.reverse();
    return (
      <View style={{
        width: layout == "phone" ? "95%" : "100%",
        alignSelf: "center",
      }}>
         {post?.detail_image != "" || post?.media_type == "videos" ? (
          <View style={{ width: layout == "web" ? "100%" : "100%",borderRadius:12 }}>
            {post?.media_type == "videos" ? (
              post?.video_type == "Youtube" ? (
                <YoutubePlayer
                  url={post?.link}
                  borderRadius={12}
                  width="100%"
                  style={{
                    height: 200,
                    marginBottom: "20px",
                    borderRadius:12,
                  }}
                />
              ) : (
                <VimeoPlayer
                  url={post?.link}
                  width="100%"
                  style={{
                    height: 200,
                    marginBottom: "20px",
                    borderRadius:12,
                  }}
                />
              )
            ) : (
              <Image
                source={{ uri: post?.detail_image }}
                resizeMode={layout == "phone" ? "contain" : "cover"}
                style={{
                  height: layout == "phone" ? 150 : 200,
                  marginBottom: "20px",
                  width: "100%",
                  alignSelf: "center",
                  borderRadius:12,
                }}
              />
            )}
          </View>
        ) : null}
        {/* <View style={{ marginBottom: 40 }} /> */}
        {/* <Typography
          // style={{ marginLeft: '50px' }}
          bottom={"15px"}
          align={"left"}
          raj
          bold={"bold"}
          color={"#1C1C1C"}
          size={layout == "phone" ? "35px" : "40px"}
          top={"20px"}
          line={"50px"}
        >
          {post?.title}
        </Typography> */}
        <ODSText type="h4" color={colors.text.primary} marginBottom={5}>{post?.title}</ODSText>
        {renderDescription()}
{/* 
        <View style={{
          flexDirection: "row",
          marginBottom: 15,
          alignItems: "center",
        }}>
          <Feather name="clock" size={18} color={COLORS.GREY1} style={{ marginTop: 2 }}/>
          <Typography
            // style={{ marginLeft: '50px' }}
            align={"left"}
            color={COLORS.GREY1}
            size={"14px"}
            left={"6px"}
            // top={'3px'}
          >
            {timeAgo(post?.createdAt)}
          </Typography>
        </View> */}

        {post?.media_type != "videos" && post?.link != "" && (
          <TouchableOpacity onPress={() => openUrl(post?.link)}>
            <ODSText type="md" marginBottom={15} color={colors.button.main} textDecorationLine='underline'>Click here to read more</ODSText>
          </TouchableOpacity>
        )}
       
        <View
          style={{
            borderColor: "#2A2A2A15",
            borderTopWidth: 1,
            flexDirection: layout == "phone" ? "column" : "row",
            justifyContent: "space-between",
            alignItems: layout == "phone" ? "flex-start" : "center",
            flex:1
          }}
        >
          <View
            style={{
              width: layout == "phone" ? "85%" : "30%",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <View style={{
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}>
              <Image
                source={require("../../assets/images/infohub/watch.png")}
                style={{
                  height: 32,
                  width: 32,
                }}
              />
              <ODSText type='md' marginLeft={5} color={colors.text.primary}> {post?.number_of_views}</ODSText>
            </View>
            <View style={{
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}>
              <Like infohub_id={post?.info_id} value={post?.likes?.includes(profile?.app_user_id)}/>
              <ODSText type='md' marginLeft={5} color={colors.text.primary}> {post?.likes?.length}</ODSText>

            </View>
            <View style={{
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}>
              <Image
                source={require("../../assets/images/infohub/comment.png")}
                style={{
                  height: 24,
                  width: 24,
                  resizeMode: "contain",
                }}
              />
              <ODSText type='md' marginLeft={5} color={colors.text.primary}> {post?.infohubComments?.length} comments</ODSText>

              
            </View>
          </View>
        <View>
          <ODSText type='md' marginTop={10} marginBottom={5} color={colors.text.primary}>Share this with a fellow tenant</ODSText>
          <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            marginTop: layout == "phone" ? 10 : 0,
            marginBottom: 40,
          }}
        >
          <FacebookShareButton url={shareOptions.url}>
            <Image
              source={require("../../assets/images/infohub/fb.png")}
              style={{
                height: 28,
                width: 28,
                resizeMode: "contain",
              }}
            />
          </FacebookShareButton>
          <TwitterShareButton url={shareOptions.url}>
            <Image
              source={require("../../assets/images/infohub/x.png")}
              style={{
                height: 28,
                width: 28,
                resizeMode: "contain",
                marginLeft: 15,
              }}
            />
          </TwitterShareButton>
          <LinkedinShareButton url={shareOptions.url}>
            <LinkedinIcon style={{
              marginLeft: 15,
              marginTop: 5,
            }} round={true} size={32}/>
          </LinkedinShareButton>
          <RedditShareButton url={shareOptions.url}>
            <Image
              source={require("../../assets/images/infohub/reddit.png")}
              style={{
                height: 28,
                width: 28,
                resizeMode: "contain",
                marginLeft: 15,
              }}
            />
          </RedditShareButton>
          <CopyToClipboard text={shareOptions.url} onCopy={(e) => alert("Copied")}>
            <Image
              source={require("../../assets/images/infohub/copy.png")}
              style={{
                height: 28,
                width: 28,
                resizeMode: "contain",
                marginLeft: 15,
              }}
            />
          </CopyToClipboard>
        </View>
        </View>


          {/* <View
            style={{
              width: layout == 'phone' ? '100%' : '32%',
              flexDirection: layout == 'phone' ? 'column' : 'row',
              justifyContent: 'space-between',
              alignItems: layout == 'phone' ? 'flex-start' : 'center',
              marginTop: layout == 'phone' ? 15 : 0,
            }}
          >
            <Typography raj color={'#1d1d1d'} align={'left'} size={18}>
              Share this with a fellow tenant
            </Typography>
          </View> */}
        </View>
        <CommentBox info_id={post?.info_id}/>
        <FlatList
          data={reversedComments?.slice(0, visibleComments)}
          contentContainerStyle={{
            padding: 10,
            marginTop: 10,
          }}
          renderItem={({ item }) => renderComments(item, profile, onDelete,colors)}
          ListFooterComponent={() => (
            <View
              style={{
                width: layout == "phone" ? "100%" : "50%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {post?.infohubComments?.length > 2 &&
                visibleComments < post?.infohubComments?.length && (
                  <ODSButton restStyle={{marginTop:30}} type='secondary' onPress={() => setVisibleComments(post?.infohubComments.length)} width={153}>
                    LOAD MORE
                  </ODSButton>
                )}
            </View>
          )}
        />
      </View>
    );
  };

  return (
    <>
      <View
        style={
          layout == "web"
            ? {
              width: "80%",
              alignSelf: "center",
              marginTop: 50,
              height: "100%",
            }
            : {
              marginLeft: 25,
              marginTop: 80,
            }
        }
      >
      </View>
      {/* <BackButton onBackAction={()=>{ goBack()}} styles={{ marginLeft: layout != "web" ? 15 : 50 }} navigation={props.navigation}/> */}

      <MediaListWrapper style={{ width: layout != "web" ? "95%" : 1200 }}>{renderPost()}</MediaListWrapper>
      <Loader loading={isLoading}/>
      <CustomPopUp
        loading={deleteCommentId}
        primaryButtonText={"Sure"}
        secondaryButtonText={"cancel"}
        primaryText={"Delete Comment?"}
        secondaryText={" Are you sure you want to delete comment?"}
        onPrimaryButtonPress={() => {
          setDeleteComment(false);
          dispatch(deleteComment({
            info_id: props?.id,
            id: deleteCommentId,
          }));
        }}
        onSecondaryButtonPress={() => {
          setDeleteComment(false);
        }}
      />
    </>
  );
};

export default InfoHubDetails;
