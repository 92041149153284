import { Hoverable } from "react-native-web-hooks";
import React, { useEffect, useState } from "react";
import { TouchableOpacity } from "react-native-web";
import { FlatList, ImageBackground, Text, View } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { getCcqStatus } from "../../redux/actions/preReferencing";
import { Feather, FontAwesome, MaterialCommunityIcons, MaterialIcons } from "@expo/vector-icons";

import HomeBoxCard from "./HomeBox";
import AppBanner from "../appBanner/index";
import BillingBetter from "./BillingBetter";
import { Typography } from "../openFlow/style";
import { COLORS } from "../../constants/colorPallete";
import getPlatformType, { getImage } from "../../helpers/getPlatform";
import CircularProgress from "../../commonComponents/circle";
import { navigateAction } from "../../helpers/navigationHelpers";
import { tenantActions } from "../openFlow/openFlowRedux/tenant.actions";
import BackGroundGenerator from "../LandingPages/common/BackGroundGenerator";
import { getProfile } from "../../redux/actions/auth";
import {
  ActionText,
  ActionWrapper,
  Background,
  BoxShadow,
  ButtonText,
  CircularWrapper,
  CustomButton,
  Heading1,
  Heading2,
} from "./stylesNew";
import { useIsFocused } from "@react-navigation/native";
import ODSWelcomeTile from "../../../openbrix-design-system/ui/atoms/ODSTiles/WelcomeTile";
import ODSInsuranceTile from "../../../openbrix-design-system/ui/atoms/ODSTiles/InsuranceTile";
import ODSTenancyTile from "../../../openbrix-design-system/ui/atoms/ODSTiles/TenancyTile";
import ODSCombobox from "../../../openbrix-design-system/ui/atoms/ODSComboBox";
import ODSRentReadyTile from "../../../openbrix-design-system/ui/atoms/ODSTiles/RentReadyTile";
import ODSHomeBoxTile from "../../../openbrix-design-system/ui/atoms/ODSTiles/HomeboxTile";
import { useTheme } from "@emotion/react";
import ODSTiles from "../../../openbrix-design-system/ui/atoms/ODSTiles";
import ODSDashboardTiles from "../../../openbrix-design-system/ui/molecules/DashboardTiles";

const Dashboard = (props) => {

  const { navigation } = props;
  const dispatch = useDispatch();
  const [type, setType] = useState("web");
  const layout = getPlatformType();
  const [tiles, setTiles] = useState([]);
  const [flowId, setFlowId] = React.useState(null);
  const [preQualData, setPreQualData] = React.useState([]);
  const [flowIdInfo, setFlowIdInfo] = React.useState(null);
  const globalState = useSelector((state) => state?.tenant);
  const [filterButton, setFilterButton] = React.useState([]);
  const [hoverStartTime, setHoverStartTime] = useState(null);
  const [tdsRoute, setTdsRoute] = useState("TDS Search Deposit");
  const profile = useSelector((state) => state.authReducer?.profile);
  const userData = useSelector((state) => state.authReducer?.profile?.data?.appUser);
  const {
    affordability,
    fcc_pre_reference,
    id_verification,
    right_to_rent,
    tenant_info
  } = preQualData;
  const tdsLocationSelected = useSelector((state) => state.appReducer?.tdsLocationSelected);

  const focus = useIsFocused();
  useEffect(() => {
    fetchTiles();
  }, [focus]);
  const fetchTiles = () => {
    dispatch(tenantActions.getTilesData((data) => {
      if (data) {
        if (data[0].card == "OpenFlow"|| data[0].card == "HomeBox" ) {
          setTiles([...data]);
        } else {
          setTiles([{}, ...data]);
        }
      }
    }));
  };
  useEffect(() => {
    dispatch(getProfile());
    dispatch(getCcqStatus(true));
    dispatch(tenantActions.getFlowInfo());
    dispatch(tenantActions.getPreQualificationStatus());
    return () => {
      setHoverStartTime(0);
    };
  }, []);

  useEffect(() => {
    setFlowId(globalState?.flowIdInfo?.openflow_id);
    console.log("globalState?.flowIdInfo",globalState?.flowIdInfo)
    setFlowIdInfo(globalState?.flowIdInfo);

    const ListArray = [
      {
        buttonStatus: "tenancy_status",
        routeKey: "TenantDetails",
        id: "tenantDetails",
        actionStatus: globalState?.flowIdInfo?.tenancy_status,
        button_text: globalState?.flowIdInfo?.tenancy_button_text,
        navigate:()=>navigation.navigate("TenantDetails")
      },
      {
        buttonStatus: "doc_status",
        routeKey: "PropertyDocs",
        id: "landlord-PropertyDocument",
        actionStatus: globalState?.flowIdInfo?.doc_status,
        button_text: globalState?.flowIdInfo?.doc_button_text,
        navigate:()=>navigation.navigate("PropertyDocs")
      },
      {
        buttonStatus: "rental_offer_status",
        routeKey: "RentalOffers",
        id: "rentalOffer",
        actionStatus: globalState?.flowIdInfo?.rental_offer_status,
        button_text: globalState?.flowIdInfo?.rental_offer_button_text,
        navigate:()=>navigation.navigate("RentalOffers")
      },
      {
        buttonStatus: "holding_deposit_status",
        routeKey: "HoldingDeposit",
        id: "holdingDeposit",
        actionStatus: globalState?.flowIdInfo?.holding_deposit_status,
        button_text: globalState?.flowIdInfo?.holding_deposit_button_text,
        navigate:()=>navigation.navigate("HoldingDeposit")
      },
      {
        buttonStatus: "verification_status",
        routeKey: "IdAndReference",
        id: "id-Referncing",
        actionStatus: globalState?.flowIdInfo?.verification_status,
        button_text: globalState?.flowIdInfo?.verification_button_text,
        navigate:()=>navigation.navigate("IdAndReference")
      },
      {
        buttonStatus: "landlord_status",
        routeKey: "ReviewAST",
        id: "tenancyAgreement",
        actionStatus: globalState?.flowIdInfo?.landlord_status,
        button_text: globalState?.flowIdInfo?.landlord_button_text,
        navigate:()=>navigation.navigate("ReviewAST")
      },
      {
        buttonStatus: "move_in_status",
        routeKey: "DepositInfo",
        id: "moveIn",
        actionStatus: globalState?.flowIdInfo?.move_in_status,
        button_text: globalState?.flowIdInfo?.move_in_button_text,
        navigate:()=>navigation.navigate("DepositInfo")
      },
      {
        buttonStatus: "property_status",
        routeKey: "DepositProtection",
        id: "depositProtect-HomeSetUp",
        actionStatus: globalState?.flowIdInfo?.property_status,
        button_text: globalState?.flowIdInfo?.verification_button_text,
        navigate:()=>navigation.navigate("DepositProtection")
      },
    ];

    let filterList = ListArray.filter((data, indes) => data?.actionStatus === 5);
    setFilterButton(filterList.reverse());

    if (globalState?.preQualData?.preQualStatus) {
      setPreQualData(globalState?.preQualData?.preQualStatus);
    }
  }, [globalState]);

  useEffect(() => {
    if (profile && profile?.data) {
      if (profile?.data?.tdkToken?.user_id) {
        setTdsRoute("Custodial Tenancies");
      } else {
        setTdsRoute(tdsLocationSelected?tdsLocationSelected: "tdsLocation");
      }
    } else {
      setTdsRoute(tdsLocationSelected?tdsLocationSelected: "tdsLocation");
    }
  }, [profile]);

  const NavigateTo = (routesKey) => {
    navigateAction(navigation, routesKey, { id: flowId });
  };

  const renderCreditHistoryStatus = () => {
    if (userData?.creditladder_code == "") {
      return <></>;
    }
    if (userData?.creditladder_code != "expired") {
      return (
        <>
          <Feather name="check" size={24} color="#009688" style={{ marginRight: 9 }}/>{" "}
          <Heading2 color={"#686868"}>Completed</Heading2>
        </>
      );
    } else {
      return (
        <>
          <MaterialCommunityIcons
            name="progress-clock"
            size={24}
            color="#FFC059"
            style={{ marginRight: 9 }}
          />
          <Heading2 color={"#686868"}>Expired</Heading2>
        </>
      );
    }
  };

  const renderPrequalificationButton = () => {
    return (
      <View style={{ marginTop: 24 }}>
        <View style={{
          flexDirection: "row",
          marginBottom: 14,
        }}>
          {getButtons(1, affordability?.affordability_amount ? true : false, "Affordability", "PreQualification")}
        </View>
        <View style={{
          flexDirection: "row",
          marginBottom: 14,
        }}>
          {getButtons(2, (tenant_info?.occupation||tenant_info?.marital_status )? true : false, "Tenant Info", "PreQualification")}
        </View>
        <View style={{
          flexDirection: "row",
          marginBottom: 14,
        }}>
          {getButtons(3, right_to_rent?.right_to_rent_dob ? true : false, "Right-to-Rent", "PreQualification")}
        </View>

        <View style={{
          flexDirection: "column",
          marginBottom: 14,
        }}>
          {getButtons(4, id_verification?.idverification == 2, "ID Verification", "PreQualification", id_verification?.idverification == 1)}
        </View>

        <TouchableOpacity style={{
          flexDirection: "row",
          marginBottom: 14,
        }}>
          <View style={{ flexDirection: "column" }}>
            {getButtons(5,
              fcc_pre_reference?.completed_date !== null &&
              fcc_pre_reference?.completed_date !== undefined
                ? true
                : false,
              "Rent Ready Reference",
              "PreQualification",
              fcc_pre_reference?.status === "in_progress" ? true : false,
            )}
          </View>
        </TouchableOpacity>
      </View>
    );
  };

  const getButtons = (id, status, text, nav, pending = false) => {
    return (
      <View testID={text} style={{ flexDirection: "row" }}>
        {status ? (
          <FontAwesome name="check" size={16} color={COLORS.LGREEN}/>
        ) : pending ? (
          <MaterialCommunityIcons name="progress-clock" size={16} color="#FFC059"/>
        ) : (
          <FontAwesome name="check" size={16} color={"#a7a4a447"}/>
        )}
        <Typography
          onPress={() => {
            navigateAction(navigation, nav);
        }}
          size={"14px"}
          left={"8px"}
          align={"left"}
          bold={"300"}
          color={COLORS.BLACK}
        >
          {id + " "}
        </Typography>
        <Typography
          onPress={() => {
            navigateAction(navigation, nav);
          }}
          underline
          size={"14px"}
          align={"left"}
          bold={"300"}
          color={COLORS.BLACK}
        >
          {text}
        </Typography>
      </View>
    );
  };

  //commented out until tom or someone else can finish the backend for this
  const handleHoverIn = () => {
    //  setHoverStartTime(Date.now());
  };

  const handleHoverOut = (type) => {
    // if (hoverStartTime) {
    //   const duration = (Date.now() - hoverStartTime) / 1000;
//
    //   dispatch(
    //     appUserTrackingProductHover({
    //       app_user_id: userId,
    //       product: type,
    //       hover_time_seconds: duration,
    //       current_page: "Dashboard",
    //     }),
    //   );
    //   // Perform any additional actions with the hover duration here
    // }
    // setHoverStartTime(null);
  };
  

  const renderTeanantMoveIn = () => {
    return (
      <View>
        <Background
          width={userData?.openflow_status == 0 && filterButton && filterButton?.length ? "730px" : "730px"}
          resizeMode="cover"
          source={require("../../assets/images/tlyfeimages/idandflo.png")}
        >
          <View style={{
            position: "absolute",
            top: 16,
            right: 16,
          }}>
            <CircularWrapper>
              <CircularProgress
                size={65}
                strokeWidth={2}
                progressPercent={flowIdInfo?.percentage_completed || 0}
                pgColor={COLORS.GREEN}
                pointerColor={COLORS.GREEN}
                customText={
                  flowIdInfo?.percentage_completed ? flowIdInfo?.percentage_completed + "%" : 0
                }
                textColor={"white"}
                textSize={"14"}
              ></CircularProgress>
            </CircularWrapper>
          </View>
          <Heading1 top={"30px"}>Tenant move-in</Heading1>
          <Heading2 color={"black"} top={"20px"}>
            <>
              Your letting agent has chosen to use Openflo to help you move into your new
              property. As you progress the through your move in, there will be action to complete
              which will appear here.
            </>
          </Heading2>
          <CustomButton
            onPress={() => {
              navigateAction(navigation, "My Tenancy");
            }}
            height={"40px"}
            bcolor={COLORS.LGREEN}
            color={"white"}
            top={"50px"}
            width={"230px"}
          >
            <ButtonText id={"open-openflow-home-page-botton"} color={"#000000"}>
              Check your progress
            </ButtonText>
            <MaterialIcons name="keyboard-arrow-right" size={24} color={COLORS.LGREEN}/>
          </CustomButton>
          {userData?.openflow_status == 0 && filterButton && filterButton?.length ? (
            <ActionWrapper>
              {flowIdInfo?.percentage_completed == 100 ? (
                <TouchableOpacity
                  onPress={() => navigateAction(navigation, "My Tenancy")}
                  style={{
                    flex: 1,
                    alignItems: "center",
                  }}
                >
                  <Heading2 id={"open-openflow-home-page-botton"} color={"#009688"}>
                    Congrats, you’ve moved in!
                  </Heading2>
                  <View
                    style={{
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "row",
                      marginTop: 5,
                    }}
                  >
                    <Heading2 color={"black"}>Check your details and documents</Heading2>
                    <MaterialIcons name="keyboard-arrow-right" size={24} color="#FFB580"/>
                  </View>
                </TouchableOpacity>
              ) : (
                <ActionText>
                  Action required:{" "}
                  <Text style={{ color: "#000000" }}>{flowIdInfo?.property?.display_address}</Text>
                </ActionText>
              )}
              {filterButton && filterButton?.length && filterButton?.length
                ? filterButton.map((item, index) => {
                  if (index == 0) {
                    return (
                      <CustomButton
                        onPress={() => {
                          NavigateTo(item?.routeKey);
                        }}
                        color={"#FF5A51"}
                        top={"20px"}
                      >
                        <ButtonText>{item?.button_text || "-"}</ButtonText>
                        <MaterialIcons name="keyboard-arrow-right" size={24} color="white"/>
                      </CustomButton>
                    );
                  } else {
                    return (
                      <CustomButton
                        onPress={() => {
                          NavigateTo(item?.routeKey);
                        }}
                        bcolor={"#FF5A51"}
                        color={"##FFFFFF"}
                        top={"20px"}
                      >
                        <ButtonText color={"#000000"}>{item?.button_text || "-"}</ButtonText>
                        <MaterialIcons name="keyboard-arrow-right" size={24} color="#FF5A51"/>
                      </CustomButton>
                    );
                  }
                })
                : null}
            </ActionWrapper>
          ) : null}
        </Background>
      </View>

    );
  };
  const getIdVerificationStatus = () => {
    switch (id_verification?.idverification) {
      case 2:
        return 'Completed';
        break;
      case 9:
        return 'Failed';
        break;
      case 1:
        return 'Pending';
        break;
      default:
        return null;
        break;
    }
  };
  
  const renderPrequalification = (backGroundImage) => {
    return (
      <Background
        padding="0px 75px 0px 18px"
        source={{ uri: backGroundImage }}
        height={"263px"}
        width={"344px"}
        resizeMode="cover"
        imageStyle={{ borderRadius: 10 }}
      >
        <View style={{ width: "63%" }}>
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              marginTop: 16,
            }}
          ></View>
          <Heading1
            onPress={() => {
              navigateAction(navigation, "PreQualification");
            }}
            color={COLORS.LGREEN}
            top={"10px"}
          >
            Rent Ready
          </Heading1>
          <Heading2 color={"#000000"} top={"13px"}>
            Become a more desirable tenant by using our pre-qualification tool.
          </Heading2>
        </View>

        {renderPrequalificationButton()}
      </Background>
    );
  };

  const renderTDS = (backGroundImage) => {
    return (
      <Background
        padding="0px 75px 0px 18px"
        source={{ uri: backGroundImage }}
        height={"263px"}
        width={"344px"}
        resizeMode="cover"
        imageStyle={{ borderRadius: 10 }}
      >
        <View style={{ width: "63%" }}>
          <View style={{
            flexDirection: "row",
            alignItems: "center",
            marginTop: 16,
          }}>
          </View>
          <Heading1
            onPress={() => {
              navigateAction(navigation, tdsRoute);
            }}
            color={COLORS.LGREEN}
            top={"10px"}
          >
            Tenancy Deposit
          </Heading1>
          <Heading2 color={"#000000"} top={"13px"}>
            View and manage your tenancy deposit with <Text style={{ fontWeight: 800 }}>TDS</Text>.
          </Heading2>


        </View>
        <CustomButton
          onPress={() => {
            navigateAction(navigation, tdsRoute);
          }}
          bcolor={COLORS.LGREEN}
          color={"white"}
          style={{
            marginTop: "auto",
            marginBottom: 20,
          }}
          width={"170px"}
          // top={"86px"}
          id={"TDS-SEARCH-DEPOSIT-GET-STARTED"}
        >
          <ButtonText id={"TDS-SEARCH-DEPOSIT-GET-STARTED"} color={"#000000"}>
            Get started
          </ButtonText>
          <MaterialIcons name="keyboard-arrow-right" size={24} color={COLORS.LGREEN}/>
        </CustomButton>
      </Background>
    );
  };

  const renderCreditHistory = (backGroundImage) => {
    return (
      <Background
        source={{ uri: backGroundImage }}
        height={"263px"}
        width={"344px"}
        padding="0px 75px 0px 18px"
        resizeMode="cover"
        imageStyle={{ borderRadius: 10 }}
      >
        <View style={{
          width: "63%",
          position: "relative",
        }}>
          <View style={{
            flexDirection: "row",
            alignItems: "center",
            marginTop: 16,
          }}>
            {renderCreditHistoryStatus()}
          </View>
          <Heading1
            onPress={() => {
              navigateAction(navigation, "Credit Builder");
            }}
            color={COLORS.LGREEN}
            top={"10px"}
          >
            Rent Record and Credit Builder
          </Heading1>
          <Heading2 color={"#000000"} top={"13px"}>
            Keep a record of your rent payments and use these to help build your {" "}
            <Text style={{ fontWeight: "800" }}>credit score</Text>.
          </Heading2>


        </View>
        {userData?.creditladder_code == "" ? (
          <CustomButton
            onPress={() => {
              navigateAction(navigation, "Credit Builder");
            }}
            bcolor={COLORS.LGREEN}
            color={"white"}
            top={"86px"}
            style={{
              marginTop: "auto",
              marginBottom: 20,
            }}
            width={"170px"}
            id={"CREDIT_GETSTARTED"}
          >
            <ButtonText color={"#000000"}>Get started</ButtonText>
            <MaterialIcons name="keyboard-arrow-right" size={24} color={COLORS.LGREEN}/>
          </CustomButton>
        ) : (
          <Heading2
            onPress={() => {
              navigateAction(navigation, "Credit Builder");
            }}
            color={"#000000"}
            underline
            top={"86px"}
            id={"CREDIT_CHECKPROGRESS"}
            style={{
              marginTop: "auto",
              marginBottom: 20,
            }}
          >
            Check your progress
          </Heading2>
        )}
      </Background>
    );

  };

  const renderFcc = (backGroundImage) => {
    return (
      <Background
        source={{ uri: backGroundImage }}
        height={"280px"}
        width={"344px"}
        padding="0px 75px 0px 18px"
        resizeMode="cover"
        imageStyle={{ borderRadius: 10 }}
      >
        <View style={{ width: "63%" }}>
          <View style={{
            flexDirection: "row",
            alignItems: "center",
          }}>
          </View>
          <Heading1
            color={COLORS.LGREEN}
            onPress={() => {
              // dispatch(tenantActions.getInsuranceClicked());
              // openUrlHelpers("https://paragonadvance.com/tlyfe-from-openbrix/");
              navigateAction(navigation, "TenantInsurance");
            }}
            top={"20px"}
          >
            Tenant Insurance
          </Heading1>
          <Heading2 color={"#000000"} top={"20px"}

          >
            Tenant Contents{" "}
            <Text style={{ fontWeight: "800" }}>Insurance</Text>.
            {" "}A small price to pay for big protection!
          </Heading2>
        </View>
        <CustomButton
          onPress={() => {
            // dispatch(tenantActions.getInsuranceClicked());
            // openUrlHelpers("https://paragonadvance.com/tlyfe-from-openbrix/");
            navigateAction(navigation, "TenantInsurance");
          }}
          bcolor={COLORS.LGREEN}
          color={"white"}
          // top={"91px"}
          id={"HESTIA_GET_STARTED"}
          style={{
            marginTop: "auto",
            marginBottom: 20,
          }}
          width={"170px"}
        >
          <ButtonText color={"#000000"}>More info.</ButtonText>
          <MaterialIcons name="keyboard-arrow-right" size={24} color={COLORS.LGREEN}/>
        </CustomButton>
      </Background>
    );
  };
  const renderIdVerfication = (backGroundImage) => {
    return (
      <Background
        source={{ uri: backGroundImage }}
        height={"280px"}
        width={"344px"}
        padding="0px 75px 0px 18px"
        resizeMode="cover"
        imageStyle={{ borderRadius: 10 }}
      >
        <View style={{ width: "63%" }}>
        <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                marginTop: 16,
              }}
            >
              {renderIDVerStatus()}
            </View>
          <Heading1
            color={COLORS.LGREEN}
            onPress={() => {
              // dispatch(tenantActions.getInsuranceClicked());
              // openUrlHelpers("https://paragonadvance.com/tlyfe-from-openbrix/");
              navigateAction(navigation, "PreQualificationIdAndVerification");
            }}
            top={"20px"}
          >
            ID Verification
          </Heading1>
          <Heading2 color={"#000000"} top={"20px"}

          >
            Verify your ID digitally in a safe environment with our partner Yoti.
          </Heading2>
        </View>
        <CustomButton
          onPress={() => {
            // dispatch(tenantActions.getInsuranceClicked());
            // openUrlHelpers("https://paragonadvance.com/tlyfe-from-openbrix/");
            navigateAction(navigation, "PreQualificationIdAndVerification");
          }}
          bcolor={COLORS.LGREEN}
          color={"white"}
          // top={"91px"}
          id={"HESTIA_GET_STARTED"}
          style={{
            marginTop: "auto",
            marginBottom: 20,
          }}
          width={"170px"}
        >
          <ButtonText color={"#000000"}>Get Started</ButtonText>
          <MaterialIcons name="keyboard-arrow-right" size={24} color={COLORS.LGREEN}/>
        </CustomButton>
      </Background>
    );
  };

  const rendershareCentre = (backGroundImage) => {
    return (
      <Background
        source={{ uri: backGroundImage }}
        height={"280px"}
        width={"344px"}
        padding="0px 75px 0px 18px"
        resizeMode="cover"
        imageStyle={{ borderRadius: 10 }}
      >
        <View style={{ width: "63%" }}>
          <View style={{
            flexDirection: "row",
            alignItems: "center",
          }}>
          </View>
          <Heading1
            onPress={() => {
              navigateAction(navigation, "Document Share Center");
            }}
            color={COLORS.LGREEN}
            top={"10px"}
          >
            My Documents & Share Centre
          </Heading1>
          <Heading2 color={"#000000"} top={"13px"}>
            View and share your documents with agents and landlords in a{" "}
            <Text style={{ fontWeight: 800 }}>secure</Text> way.
          </Heading2>

        </View>
        <CustomButton
          onPress={() => {
            navigateAction(navigation, "Document Share Center");
          }}
          bcolor={COLORS.LGREEN}
          color={"white"}
          width={"170px"}
          style={{
            marginTop: "auto",
            marginBottom: 20,
          }}
          // top={"66px"}
          id={"TDS-SEARCH-DEPOSIT-GET-STARTED"}
        >
          <ButtonText id={"TDS-SEARCH-DEPOSIT-GET-STARTED"} color={"#000000"}>
            Get started
          </ButtonText>
          <MaterialIcons name="keyboard-arrow-right" size={24} color={COLORS.LGREEN}/>
        </CustomButton>
      </Background>
    );
  };

  const renderRewards = (backGroundImage) => {
    return (
      <Background
        source={{ uri: backGroundImage }}
        height={"280px"}
        width={"344px"}
        padding="0px 75px 0px 18px"
        resizeMode="cover"
        imageStyle={{ borderRadius: 10 }}
      >
        <View style={{ width: "63%" }}>
          <View style={{
            flexDirection: "row",
            alignItems: "center",
          }}>
          </View>
          <Heading1
            onPress={() => {
              navigateAction(navigation, "Rewards");
            }}
            color={COLORS.LGREEN}
            top={"10px"}
          >
           tlyfe Rewards
          </Heading1>
          <Heading2 color={"#000000"} top={"13px"}>
          Access hundreds of savings with National Retailers, including Waitrose, M&S, Ikea, Sainsburys, Pizza Express and many, many more!
          </Heading2>

        </View>
        <CustomButton
          onPress={() => {
            navigateAction(navigation, "Rewards");
          }}
          bcolor={COLORS.LGREEN}
          color={"white"}
          width={"170px"}
          style={{
            marginTop: "auto",
            marginBottom: 20,
          }}
          // top={"66px"}
          id={"TDS-SEARCH-DEPOSIT-GET-STARTED"}
        >
          <ButtonText id={"TDS-SEARCH-DEPOSIT-GET-STARTED"} color={"#000000"}>
            Go
          </ButtonText>
          <MaterialIcons name="keyboard-arrow-right" size={24} color={COLORS.LGREEN}/>
        </CustomButton>
      </Background>
    );
  };
  const renderZopa = (backGroundImage) => {
    return (
      <Background
        source={{ uri: backGroundImage }}
        height={"263px"}
        width={"344px"}
        padding="0px 75px 0px 18px"
        resizeMode="cover"
        imageStyle={{ borderRadius: 10 }}
      >
        <View style={{ width: "63%" }}>
          <View style={{
            flexDirection: "row",
            alignItems: "center",
            marginTop: 16,
          }}>
          </View>
          {/* <Heading1
            onPress={() => {
              navigateAction(navigation, "Money Centre");
            }}
            color={COLORS.LGREEN}
            top={"10px"}
          >
            Money Centre
          </Heading1> */}
          <Heading2 color={"#000000"} top={"8px"}>
            We provide <Text style={{ fontWeight: 800 }}>loans </Text>for your:
          </Heading2>
          <Heading2 color={"#000000"} top={"6px"}>
            - Deposit
          </Heading2>
          <Heading2 color={"#000000"} top={"6px"}>
            - Move-in costs
          </Heading2>
          <Heading2 color={"#000000"} top={"6px"}>
            - Furnishing & renewals
          </Heading2>

          <Heading2 color={"#000000"} top={"12px"}>
            Provided by <Text style={{}}>Hestia Money.</Text> Backed by{" "}
            <Text style={{}}>Zopa.</Text>
          </Heading2>


        </View>
        <CustomButton
          onPress={() => {
            navigateAction(navigation, "Money Centre");
          }}
          bcolor={COLORS.LGREEN}
          color={"white"}
          width={"170px"}
          style={{
            marginTop: "auto",
            marginBottom: 20,
          }}
          // top={layout == "phone" ? "14px" : "14px"}
          id={"HESTIA_GET_STARTED"}
        >
          <ButtonText color={"#000000"}>Get started</ButtonText>
          <MaterialIcons name="keyboard-arrow-right" size={24} color={COLORS.LGREEN}/>
        </CustomButton>
      </Background>
    );
  };

  const renderTiles = (tile, backGroundImage) => {
    let componentToRender;

    switch (tile) {
      case "HomeBox":
        componentToRender = (
          <View style={{
            width: layout !== "phone" ? 550 : "100%",
            height: layout !== "phone"? 330:380,
            alignSelf:'center'
          }}>
            <HomeBoxCard profile={profile?.data}/>
          </View>
        );
        break;
      case "BillingBetter":
        componentToRender = (
          <Background
            source={{ uri: backGroundImage }}
            height={"263px"}
            width={"344px"}
            resizeMode="cover"
            imageStyle={{ borderRadius: 10 }}
            padding={"0px"}
          >
            <BillingBetter profile={profile?.data} backGroundImage={backGroundImage}/>
          </Background>
        );
        break;
      case "OpenFlow":
        componentToRender = (renderTeanantMoveIn());
        break;
      case "Insurance":
        componentToRender = (
          <Hoverable
            onHoverIn={() => handleHoverIn("LandingInsurance")}
            onHoverOut={() => handleHoverOut("LandingInsurance")}
          >
            {renderFcc(backGroundImage)}
          </Hoverable>
        );
        break;
      case "MoneyCenter":
        componentToRender = (
          <Hoverable
            onHoverIn={() => handleHoverIn("Money Centre")}
            onHoverOut={() => handleHoverOut("Money Centre")}
          >
            {renderZopa(backGroundImage)}
          </Hoverable>
        );
        break;
      case "MyDocuments":
        componentToRender = (
          <Hoverable
            onHoverIn={() => handleHoverIn("Document Share Center")}
            onHoverOut={() => handleHoverOut("Document Share Center")}
          >
            {rendershareCentre(backGroundImage)}
          </Hoverable>
        );
        break;
      case "CreditBuilder":
        componentToRender = (
          <Hoverable
            onHoverIn={() => handleHoverIn("Credit Builder")}
            onHoverOut={() => handleHoverOut("Credit Builder")}
          >
            {renderCreditHistory(backGroundImage)}
          </Hoverable>
        );
        break;
      case "TenancyDeposit":
        componentToRender = (
          <Hoverable
            onHoverIn={() => handleHoverIn("TDS")}
            onHoverOut={() => handleHoverOut("TDS Search Deposit")}
          >
            {renderTDS(backGroundImage)}
          </Hoverable>
        );
        break;
      case "PreQualification":
        componentToRender = (
          <Hoverable
            onHoverIn={() => handleHoverIn("PreQualification")}
            onHoverOut={() => handleHoverOut("PreQualification")}
          >
            {renderPrequalification(backGroundImage)}
          </Hoverable>
        );
        break;
        case "IDVerification":
        componentToRender = (
          <Hoverable
            onHoverIn={() => handleHoverIn("IDVerification")}
            onHoverOut={() => handleHoverOut("IDVerification")}
          >
            {renderIdVerfication(backGroundImage)}
          </Hoverable>
        );
        break;
        case "Rewards":
          componentToRender = (
            <Hoverable
              onHoverIn={() => handleHoverIn("Rewards")}
              onHoverOut={() => handleHoverOut("Rewards")}
            >
              {renderRewards(backGroundImage)}
            </Hoverable>
          );
          break;
        
      default:
        componentToRender = <Text></Text>;
        break;
    }
    return componentToRender;
  };

  const renderItem1 = ({
    item,
    index,
  }) => {
    return (
      <View style={{
        flex: 1,
        flexDirection: layout == "phone" ? "column" : "row",
      }}>
        <BoxShadow key={index}>
          {renderTiles(item.card, item.backGroundImage)}
        </BoxShadow>
      </View>
    );
  };

  const renderItem = ({
    item,
    index,
  }) => {
    if (index == 0) {
      return (
        <BoxShadow style={{alignSelf:"center",marginTop:0}} key={index}>
          {renderTiles(item.card, item.backGroundImage)}
        </BoxShadow>
      );
    } else if (index % 2 === 1) {
      return (
        <View style={{
          flex: 1,
          flexDirection: layout == "phone" ? "column" : "row",
        }}>
          <BoxShadow key={index}>
            {renderTiles(item.card, item.backGroundImage)}
          </BoxShadow>
          {index < tiles.length - 1 ?
            <BoxShadow key={index}>
              {renderTiles(tiles[index + 1].card, tiles[index + 1].backGroundImage)}
            </BoxShadow>
            : null}
        </View>
      );
    } else {
      return null;
    }
  };
  const renderIDVerStatus = () => {
    if (id_verification?.idverification == 2) {
      return 2
    } else if (id_verification?.idverification == 9) {
      return 0
    } else if (id_verification?.idverification == 1 || id_verification?.payment_required==false) {
      return 1
    }else{
      return 0
    }
  };

  return (
    <BackGroundGenerator isTLYF props={props}>
      <ImageBackground
        source={
          getImage("dashboard.png")
        }
        style={{
          width: '100%',
          height: '270px',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
        resizeMode="cover"
      ></ImageBackground>
      <View
        style={{ position: 'absolute', top: layout == 'phone' ? 150 : 120, alignSelf: 'center',zIndex:1000 }}
      >
        <ODSWelcomeTile
          title={profile && `Welcome ${profile?.data.first_name}!`}
          subtitle={'Check out your latest updates below!'}
          onPress={()=>navigateAction(navigation, profile?.data?.appUser?.registered_from=="tds"?tdsRoute:"My Account" )}
          registerForm={profile?.data?.appUser?.registered_from}
        />
      </View>
      <View style={{ height: layout == 'phone' ? 150 : 130 }} />

      {tiles.length > 0 && (
        <ODSDashboardTiles
          navigationRoutes={[
            {
              id: 'MyDocuments',
              route: 'Document Share Center',
            },
            {
              id: 'OpenFlow',
              route: 'My Tenancy',
            },
            {
              id: 'CreditBuilder',
              route: 'Credit Builder',
              status:userData?.creditladder_code == ""?null:userData?.creditladder_code != "expired"?"Completed":"Expired"
            },
            {
              id: 'TenancyDeposit',
              route: tdsRoute,
            },
            {
              id: 'PreQualification',
              route: 'PreQualification',
            },
            {
              id: 'IDVerification',
              route: 'PreQualificationIdAndVerification',
              status:id_verification?.idverification && getIdVerificationStatus()
            },
            {
              id: 'Rewards',
              route: 'Rewards',
            },
            {
              id: 'Insurance',
              route: 'TenantInsurance',
            },
          ]}
          rentReadyData={[{
            title: "Affordability",
            state: affordability?.affordability_amount ? 2 : 0,
            onPress:()=>navigateAction(navigation, "PreQualificationAffordability")

        },
        {
            title: "Tenant Info",
            state: (tenant_info?.occupation||tenant_info?.marital_status )?2:0,
            onPress:()=>navigateAction(navigation, "PreQualificationTenantInfo")
        },
        {
            title: "Right to Rent",
            state: right_to_rent?.right_to_rent ? 2 : 0,
            onPress:()=>navigateAction(navigation, "PreQualificationRightToRent")
        },
        {
            title: "ID Verification",
            state: renderIDVerStatus(),
            onPress:()=>navigateAction(navigation, "PreQualificationIdAndVerification")
        }, {
            title: "Rent Ready Reference",
            state: fcc_pre_reference?.status == 'completed'?2:( fcc_pre_reference?.status == 'in_progress'|| fcc_pre_reference?.payment_required==false)?1:0,
            onPress:()=>navigateAction(navigation, "PreQualificationPreReference")
        }]}
          navigation={navigation}
          tiles={tiles}
          renderTenancyTile={<ODSTenancyTile
          movedIn={flowIdInfo?.percentage_completed == 100?true:false}
          actionButtons={filterButton}
          percentage={flowIdInfo?.percentage_completed || 0}
          actionRequired={flowIdInfo?.percentage_completed >0 && flowIdInfo?.percentage_completed <=99 && filterButton && true}
          onPress={()=>navigateAction(navigation, "My Tenancy")}
          />}
        />
      )}

      {/* <FlatList
        data={tiles}
        renderItem={layout == "phone" ? renderItem1 : renderItem}
        extraData={flowIdInfo}
        contentContainerStyle={{
          flex: 1,
          justifyContent: "center",
          marginBottom: 80,
          marginLeft: 10,
          marginRight: 10,
          marginTop: layout == "phone" ? 20 : 20,
          alignSelf: "center",
        }}
      /> */}
      <AppBanner />
    </BackGroundGenerator>
  );
};

export default Dashboard;
