import React, { useState } from 'react';
import { Pressable, Text, StyleSheet } from 'react-native';
import { ODSIcon } from '../ODSIcon';
import { getTokens } from '../../../design-tokens/color-tokens';

const ODSButton = ({
  type,
  onPress,
  disabled,
  children,
  restStyle,
  rIcon,
  lIcon,
  themeColor,
}) => {
  const themeObj = themeColor ? themeColor : getTokens('light').colors.main.blue;
  // console.log('themeObj', themeObj);
  const [isPressed, setIsPressed] = useState(false);
  const buttonStyles = [styles(themeObj).button];
  const textStyles = [styles(themeObj).text];
  let IconColor = 'white';
  switch (type) {
    case 'primary':
      buttonStyles.push(styles(themeObj).primary);
      break;
    case 'secondary':
      buttonStyles.push(styles(themeObj).secondary);
      textStyles.push(styles(themeObj).textSecondary);
      IconColor = themeObj[500];
      break;
    case 'disabled':
      buttonStyles.push(styles(themeObj).disabled);
      break;
    default:
      buttonStyles.push(styles(themeObj).primary);
  }

  if (disabled) {
    buttonStyles.push(styles(themeObj).disabled);
    IconColor = 'white';
  }

  if (isPressed) {
  }
  // console.log('buttonStyles', buttonStyles);
  const handlePressIn = () => {
    if (disabled) {
    } else {
      setIsPressed(true);
    }
  };

  const handlePressOut = () => {
    setIsPressed(false);
  };

  return (
    <Pressable
      onPress={onPress}
      onPressIn={handlePressIn}
      onPressOut={handlePressOut}
      disabled={disabled}
      style={[buttonStyles, restStyle, isPressed ? styles(themeObj, type).hover : {}]} // Pass a single style object here
    >
      {rIcon ? <ODSIcon color={IconColor} icon={rIcon} size={20} /> : null}
      <Text style={textStyles}>{children}</Text>
      {lIcon ? <ODSIcon color={IconColor} icon={lIcon} size={20} /> : null}
    </Pressable>
  );
};

const styles = (theme, type) =>
  StyleSheet.create({
    button: {
      height: 53,
      paddingVertical: 12,
      paddingHorizontal: 20,
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: 1000,
      shadowColor: '#101828',
      shadowOffset: { width: 0, height: 1 },
      shadowOpacity: 0.1,
      shadowRadius: 4,
      elevation: 2,
      backgroundColor: theme[500],
      flexDirection: 'row',
    },
    text: {
      color: '#FFFFFF',
      fontSize: 16,
      fontWeight: 'bold',
      paddingHorizontal: 10,
      textAlign:"center"
    },
    textSecondary: {
      color: 'black',
      fontSize: 16,
      fontWeight: 'bold',
      paddingHorizontal: 10,
    },
    primary: {
      backgroundColor: theme[500],
    },
    secondary: {
      backgroundColor: '#FFF',
      borderWidth: 2,
      borderColor: theme[500],
    },
    disabled: {
      backgroundColor: '#D0D6DD',
    },
    hover: {
      borderRadius: 50,
      backgroundColor: type == 'secondary' ? theme[100] : theme[700],
      shadowColor: '#C5EDF3',
      shadowOffset: { width: 0, height: 0 },
      shadowOpacity: 1,
      shadowRadius: 3,
      elevation: 4,
      borderWidth: 4,
      borderColor: theme[200],
    },
  });

export default ODSButton;
