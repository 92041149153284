import React from 'react';
import {
  TouchableOpacity,
  View,
  Image,
  Text,
  Dimensions,
  StyleSheet,
  Platform,
  ImageBackground,
  Linking,
} from 'react-native';
import { COLORS } from '../../constants/colorPallete';
import { LinearGradient } from 'expo-linear-gradient';
import {
  FooterWrapper,
  Container,
  FooterLogo,
  FooterLinks1,
  FooterLinks2,
  SocialLinks,
  TextContent,
  SocialImg,
  SocialImgBox,
  Vline,
  ObxWhiteImg,
} from './footerStyle';
import * as Device from 'expo-device';
import { navigateAction, popAction } from '../../helpers/navigationHelpers';
import { Link } from '@react-navigation/native';
import getPlatformType from '../../helpers/getPlatform';
import SocialIcons from './socialIcons';
import { Typography } from '../../components/openFlow/style';
import { ODSText } from '../../../openbrix-design-system/ui/atoms/ODSText';
import { ThemeContext } from '@emotion/react';
let deviceType = '';
const layoutType = getPlatformType();
const { height: WINDOW_HEIGHT, width: WINDOW_WIDTH } = Dimensions.get('window');

export default class FooterLanding extends React.Component {
  constructor(props) {
    super(props);
  }
  componentDidMount = async () => {
    deviceType = await Device.getDeviceTypeAsync();
  };

  _redirectSocial = (url) => {
    Linking.canOpenURL(url).then((supported) => {
      if (supported) {
        if (Platform.OS === 'web') {
          window.open(url);
        } else {
          Linking.openURL(url);
        }
      } else {
      }
    });
  };
  static contextType = ThemeContext;

  render() {
    const theme = this.context;
    const {colors}=theme;
    const { navigation, isTLYF=true, paintedWidth, isTds, isLanding, cloudFootor,isLandingOpenbrix } = this.props;
    if (cloudFootor) {
      return (
        <ImageBackground
          source={
            isTLYF
              ? require(layoutType == 'web' ? './footor.png' : './footorMobile.png')
              : require(layoutType == 'web' ? './footorOrange.png' : './footorMobileOrange.png')
          }
          style={{ width: '100%', height: 335, position: 'absolute', bottom: 0 }}
          resizeMode={'stretch'}
        >
          <View
            style={
              layoutType == 'web'
                ? {
                    paddingHorizontal: '10%',
                    marginBottom: 10,
                    position: 'absolute',
                    bottom: 10,
                    flexDirection: 'row',
                  }
                : {
                    paddingHorizontal: '8%',
                    position: 'absolute',
                    bottom: 10,
                    flexDirection: 'column',
                  }
            }
          >
            <View style={layoutType == 'web' ? { flex: 0.7 } : { flex: 1 }}>
              {isTLYF ? (
                <ObxWhiteImg
                  source={require('../../assets/images/tlyfeWhite.png')}
                  style={{ height: 45, width: 100 }}
                />
              ) : (
                <ObxWhiteImg
                  source={require('../../assets/images/obxWhite.png')}
                  style={{ height: 15, width: 100 }}
                />
              )}
              <Typography top={'10px'} size={'12px'} align={'left'}>
                Openbrix Ltd - 2024 All rights reserved. Company Registration No 11305881 Company
                VAT No 32 12 11 960.
              </Typography>
              <Typography align={'left'} top={'5px'} size={'12px'}>
                Openbrix Ltd is authorised and regulated by the Financial Conduct Authority. Our FCA
                Firm Reference Number is 968596.
              </Typography>
              <Typography align={'left'} top={'5px'} size={'12px'}>
                Openbrix Ltd is an Introducer Appointed Representative of Paragon Advance Limited,
                whose FCA Firm Reference is Number is 304595. Our trading address is 4-5 Quay Point,
                Northarbour Road, Portsmouth, PO6 3TD
              </Typography>
            </View>
            <View
              style={
                layoutType == 'web'
                  ? {
                      flex: 0.3,
                      justifyContent: 'flex-end',
                      alignItems: 'center',
                      flexDirection: 'column',
                    }
                  : {
                      flex: 1,
                      justifyContent: 'space-between',
                      marginTop: 20,
                      alignItems: 'flex-start',
                      flexDirection: 'row',
                    }
              }
            >
              <SocialIcons iconSize={'10px'} />
              <View style={{ flexDirection: 'row', marginTop: 10 }}>
                <TextContent
                  id={'ABOUT_US'}
                  testID={'ABOUT_US'}
                  onPress={() => navigateAction(navigation, isTLYF ? 'LandingAbout' : 'LandingAbout')}
                >
                  {isTLYF ? 'About Us' : 'About Us'}
                </TextContent>
                <Vline />
                <TextContent
                  id={'TNC_ID'}
                  testID={'TNC_ID'}
                  onPress={() => navigateAction(navigation, 'LandingTeam')}
                >
                  Team
                </TextContent>
                <Vline />
                <TextContent
                  id={'CONTACT_US'}
                  testID={'CONTACT_US'}
                  onPress={() => navigateAction(navigation, 'LandingContactUs')}
                >
                  Contact Us
                </TextContent>
              </View>
            </View>
          </View>
        </ImageBackground>
      );
    } else
      return (
        <>
          <LinearGradient
            colors={
              isTLYF
                ? isTds
                  ? ['#638ACC', '#C4F5D8']
                  : ['#fff', '#fff']
                :  ['#fff', '#fff']
            }
            style={
              cloudFootor
                ? { position: 'absolute', bottom: 0 }
                : {
                    width: '100%',
                    position: 'relative',
                    shadowColor: '#000', // Color of the shadow
                    shadowOffset: { width: 1, height: 3 }, // Offset of the shadow
                    shadowOpacity: 0.15, 
                    shadowRadius: 8, 
                    elevation: 4,
                  }
            }
            start={[0.25, 0.5]}
            end={[1, 1]}
          >
            <View
              style={{
                width: '100%',
                flexDirection:layoutType=="phone"?'column':'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginVertical: 10,
                paddingHorizontal: 40,
                paddingVertical:5,
               
              }}
            >
              <View style={{ flexDirection: 'row', alignItems: 'center' ,marginLeft:layoutType=="web"?40:0,marginTop:layoutType=="phone"?10:0}}>
                <Link
                  to={isTLYF ? '/home' : '/home'}
                  style={{ width: layoutType == 'phone' ? 100 : 130 }}
                >
                  {isTLYF ? (
                    <ObxWhiteImg
                      source={require('../../assets/images/tlyfe-black-logo.png')}
                      resizeMode="contain"
                      style={{ height: 25, width: 100 }}
                    />
                  ) : (
                    <ObxWhiteImg
                      source={require('../../assets/images/obx_black.png')}
                      resizeMode="contain"
                      style={{ height: 15, width: 130 }}
                    />
                  )}
                </Link>
                {/* {paintedWidth <= 1000 ? null : <SocialIcons iconSize={'10px'} />} */}
              </View>
         {isLandingOpenbrix?
              <View style={{ flexDirection: 'row', marginRight: layoutType=="web"?60:20,gap:layoutType=="phone"?24:32 ,flexWrap:'wrap',justifyContent:'center',marginTop:layoutType=="phone"?16:0}}>

              <TouchableOpacity  onPress={() => navigateAction(navigation, "LandingAbout")}>
              <ODSText type='button' color={colors.text.primary}>About Us</ODSText>
              </TouchableOpacity>
              <TouchableOpacity    onPress={() => navigateAction(navigation, 'LandingContactUs')}>
              <ODSText type='button' color={colors.text.primary}>Contact us</ODSText>
              </TouchableOpacity>
              <TouchableOpacity    onPress={() =>  window.open('https://agent.openbrix.co.uk/privacy-policy','_blank')}>
              <ODSText type='button' color={colors.text.primary}>Privacy Policy</ODSText>
              </TouchableOpacity>
              <TouchableOpacity    onPress={() => window.open('https://agent.openbrix.co.uk/cookies', '_blank')}>
              <ODSText type='button' color={colors.text.primary}>Cookies</ODSText>
              </TouchableOpacity>
             </View>
         :<View style={{ flexDirection: 'row', marginRight: layoutType=="web"?60:20,gap:layoutType=="phone"?24:32 ,flexWrap:'wrap',justifyContent:'center',marginTop:layoutType=="phone"?16:0}}>
{layoutType=="phone"?null:<>
<TouchableOpacity  onPress={() => navigateAction(navigation, isTLYF ? 'About Us' : 'About Us')}>
               <ODSText type='sm' color={colors.text.primary}>About Us</ODSText>
               </TouchableOpacity>
               <TouchableOpacity   onPress={() => navigateAction(navigation, 'Contact Us')}>
               <ODSText type='sm' color={colors.text.primary}>Contact Us</ODSText>
               </TouchableOpacity>
               <TouchableOpacity    onPress={() => navigateAction(navigation, 'Legal Bits',{to:"policy"})}>
               <ODSText type='sm' color={colors.text.primary}>Privacy Policy</ODSText>
               </TouchableOpacity>
               <TouchableOpacity    onPress={() => navigateAction(navigation, 'LandingInfoHub')}>
               <ODSText type='sm' color={colors.text.primary}>Info Hub</ODSText>
               </TouchableOpacity>
               <TouchableOpacity    onPress={() => navigateAction(navigation, 'Legal Bits',{to:"t/c"})}>
               <ODSText type='sm' color={colors.text.primary}>T &amp; C's</ODSText>
               </TouchableOpacity>
               <TouchableOpacity    onPress={() => navigateAction(navigation, 'Charity')}>
               <ODSText type='sm' color={colors.text.primary}>Charity</ODSText>
               </TouchableOpacity>
</>}
              
            



                {/* {paintedWidth <= 767 ? null : (
                  <React.Fragment>
                    <TextContent
                      testID={'MEDIA_HUB'}
                      onPress={() => navigateAction(navigation, 'LandingInfoHub')}
                    >
                      Info Hub
                    </TextContent>

                    <TextContent
                      testID={'TNC_ID'}
                      onPress={() => navigateAction(navigation, 'Legal Bits',{to:"t/c"})}
                    >
                      T &amp; C's
                    </TextContent>
                    <TextContent
                      testID={'CHARITY'}
                      onPress={() => navigateAction(navigation, 'Charity')}
                    >
                      Charity
                    </TextContent>
                  </React.Fragment>
                )} */}
              </View>}
            </View>
            {isLanding ? (
              <View style={{ paddingHorizontal: '10%', marginBottom: 10 }}>
                <Typography align={"left"} top={'10px'} bold={100} color={"black"} size={'12px'}>
                  Openbrix Ltd - 2024 All rights reserved. Company Registration No 11305881 Company
                  VAT No 32 12 11 960.
                </Typography>
                <Typography align={"left"} color={"black"}bold={100}  top={'5px'} size={'12px'}>
                  Openbrix Ltd is authorised and regulated by the Financial Conduct Authority. Our
                  FCA Firm Reference Number is 968596.
                </Typography>
                <Typography align={"left"} color={"black"}bold={100} top={'5px'} size={'12px'}>
                  Openbrix Ltd is an Introducer Appointed Representative of Paragon Advance Limited,
                  whose FCA Firm Reference is Number is 304595. Our trading address is 4-5 Quay
                  Point, Northarbour Road, Portsmouth, PO6 3TD
                </Typography>
              </View>
            ) : null}
          </LinearGradient>
        </>
      );
  }
}
