import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FlatList, Image, ImageBackground, Text, TouchableOpacity, View,Linking, Dimensions } from 'react-native';
import AutoHeightImage from '../common/AutoImage';
import stylesMobile from './stylesMobile';
import stylesTab from './stylesTab';
import stylesWeb from './stylesWeb';
import { backgroundSearchArr } from '../images/tlyfe/searchArrayTlyfe';
import BackGroundGenerator from '../common/BackGroundGenerator';
import { navigateAction, trackAnalytics } from '../../../helpers/navigationHelpers';
import { useDispatch, useSelector } from 'react-redux';
import { Typography } from '../../openFlow/style';
import WideButton from '../../../commonComponents/wideButton';
import { COLORS } from '../../../constants/colorPallete';
import Form from '../../rentalLandingScreen/form';
import getPlatformType from '../../../helpers/getPlatform';
import CommonAuth from './CommonAuth';
import ImageView from './imageView';
import { saveSearchObject } from '../../../redux/actions/app';
import LandingContact from '../landingContact/landingContact';
import { showToaster } from '../../../redux/actions/toast';
import { callIfAvailable } from '../../../helpers/callEmailHelper';
import ContactusScreen from '../../contactusScreen';
import ContactUsCommon from '../../contactusScreen/contactUsCommon';

const tiles = [
  {
    image: require('./images/about/rc.png'),
    subheading:
      'Info@openbrix.co.uk ',
    heading: 'Email us at',
  }
  
];

const LandingContactUs = (props) => {
  const [type, setType] = useState('web');
  const layoutType = getPlatformType();
  const dispatch = useDispatch();
  const isAnylyticsEnable = useSelector(
    (state) => state?.cookiesReducer?.customiseCookies.Analytics
  );
  const { navigate } = props.navigation;
  const navigateTo = (params, from) => {
    if (from) {
      navigateAction(props?.navigation, params, from);
    } else {
      navigateAction(props?.navigation, params);
    }
  };
  const scrollViewRef = useRef();
  const [property_for, setPropertyFor] = useState(1);
  const styles = type == 'web' ? stylesWeb : type == 'mobile' ? stylesMobile : stylesTab;
  const [state, setState] = useState({
    from: '',
  });

  const _onSearch = (value) => {
    isAnylyticsEnable ? trackAnalytics('Button', 'OnClick', 'Property-Search') : null;
    dispatch(saveSearchObject(value));
    props.navigation.navigate('Search');
  };

  useEffect(() => {
    if (props?.route?.params?.from) {
      setState({
        ...state,
        from: props?.route?.params?.from ? props?.route?.params?.from : '',
      });
    }
  }, [props?.route?.params?.from]);
  console.log('type', type);
  const token = useSelector((state) => state?.appReducer?.loginData?.data?.token);
  let BottomWrapper = type == 'web' ? ImageBackground : View;
 const callAgent = async () => {
    let status = false;
    if ( "0203 740 4721") {
      status = await callIfAvailable( "0203 740 4721");
      if (!status) {
        dispatch(showToaster('error', "Sorry, agent's contact unavailable"));
      }
    } else if (!status) {
      dispatch(showToaster('error', "Sorry, agent's contact unavailable"));
    }
  };
  const handleScrollButtonClick = useCallback(() => {
    const height=Dimensions.get('window').height;
    if (scrollViewRef.current) {
      scrollViewRef.current.scrollTo({ y:layoutType=='web'?height:height/2, animated: true });
    }
  }, []);
  const emailAgent = async () => {
    let email = "info@openbrix.co.uk";
    const url = `mailto: ${email}`;
    Linking.openURL(url)
    .catch((err) => {
      console.error("An error occurred", err);
      return false;
    });
  };
  return (
    <>
      <BackGroundGenerator
        props={props}
        layoutType={(data) => setType(data)}
        backgroundArr={backgroundSearchArr}
        isLanding
        hideAuth={true}
        scrollViewRef={scrollViewRef}
        isTLYF={false}
        isLandingOpenbrix
      >
        <CommonAuth
          heading={'Contact Us'}
          navigation={navigate}
          onScrollButtonClick={handleScrollButtonClick}
          type={type}
          hideAuth={true}
          logoName={' '}
          showRegistrationForm={true}
          subheading={'If you have any queries or if we can be of any further assistance please do not hesitate to contact us by any means preferable to you'.toUpperCase()}
          hideButton={false}
          customSubHeadingStyle={
            type == 'web' ?   {
              fontSize:45,
              width:559,
              lineHeight:48

            }:null
          }
          {...props}
        />

    

        <View style={[styles.imageContainer, { backgroundColor: "white" }]}>
          <Typography
            raj
            bold={'700'}
            color={'#1C1C1C'}
            size={type !== 'web' ? '30px' : '48px'}
            line={type !== 'web' ? '35px' : '52px'}
            align={'center'}
            bottom={'4%'}
          >
           GET IN CONTACT
          </Typography>
          <View
            style={{
              flexDirection: 'row',
              flexWrap: 'wrap',
              justifyContent: 'center',
            }}
          >
            {tiles.map((item, index) => {
              return (
                <TouchableOpacity
                onPress={()=>{
                  if(index==1){
                    callAgent()
                  }else{
                    emailAgent()
                  }
                }}
                  key={index}
                  style={[
                    styles.shawow,
                    {
                      width: 315,
                      padding: 20,
                      paddingVertical: 40,
                      justifyContent: 'center',
                      alignItems: 'center',
                      borderRadius: 20,
                      margin: 20,
                      borderBottomWidth: 3,
                      borderColor: COLORS.LIGHTPINK1,
                    },
                  ]}
                >
                  <Image
                    source={item.image}
                    style={{
                      width: 146,
                      height: 146,
                    }}
                    resizeMode={'contain'}
                  />
                  <Typography
                    line={'35px'}
                    raj
                    bold={'600'}
                    top={'10px'}
                    color={'#1C1C1C'}
                    size={'26px'}
                    align={'center'}
                  >
                    {item.heading}
                  </Typography>
                  <Typography
                    line={'26px'}
                    raj
                    bold={'500'}
                    top={'6px'}
                    color={'#737373'}
                    size={'20px'}
                    align={'center'}
                  >
                    {item.subheading}
                  </Typography>
                </TouchableOpacity>
              );
            })}
          </View>
        </View>
        <View style={[styles.imageContainer, { backgroundColor:'#FAFAFB'  }]}>

          <ContactUsCommon/>
        </View>
 
      </BackGroundGenerator>
    </>
  );
};
export default LandingContactUs;


