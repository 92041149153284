import React, { useEffect, useState } from 'react';
import { View, TouchableOpacity, StyleSheet, Linking, Platform } from 'react-native';
import { useTheme } from '@emotion/react';
import ODSTabs from '../../atoms/ODSTabs';
import { ODSText } from '../../atoms/ODSText';
import { ODSInput } from '../../atoms/ODSInput';
import { ODSIcon } from '../../atoms/ODSIcon';
import moment from 'moment';
import ODSButton from '../../atoms/ODSButton';
import getScreenType from '../../../utils/getScreenType';
import SCSteps from './SCSteps';
import { ODSModal } from '../../atoms/ODSModal/ODSModal';
// import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";

const ShareCenter = ({ globalState, DocumentData, addDoc, onShareSave }) => {
  const { colors } = useTheme();
  const [searchText, setSearchText] = useState('');
  const [active, setActiveShareCode] = React.useState([]);
  const [expire, setExpiredShareCode] = React.useState([]);
  const [showExpire, setShowExpiredShareCode] = React.useState(false);

  const [showModal, setShowModal] = React.useState(false);

  const layout = getScreenType();

  useEffect(() => {
    if (globalState?.sharedCodes?.share_codes?.length) {
      const activeShareCodes = sortObjectsByExpireDate(
        globalState?.sharedCodes?.share_codes
      ).filter((shareCode) => {
        const remainingTime = getRemainingTime(shareCode.expire_date);
        return remainingTime !== false;
      });
      const expireCodes = sortObjectsByExpireDate(globalState?.sharedCodes?.share_codes).filter(
        (shareCode) => {
          const remainingTime = getRemainingTime(shareCode.expire_date);
          return remainingTime == false;
        }
      );
      setActiveShareCode(activeShareCodes);
      setExpiredShareCode(expireCodes);
      console.log('EXPIRED', expireCodes);
      console.log('ACTIVE', activeShareCodes);
    }
  }, [globalState]);

  function sortObjectsByExpireDate(objects) {
    return objects.sort((a, b) => new Date(b.expire_date) - new Date(a.expire_date));
  }
  const getRemainingTime = (expire_date) => {
    const now = moment();
    const expiration = moment(expire_date);
    const diff = expiration.diff(now, 'seconds');
    return diff > 0 ? diff : false;
  };
  function getRelativeTime(expire_date) {
    const now = moment();
    const expiration = moment(expire_date);
    const diffInDays = expiration.diff(now, 'days');
    const diffInHours = expiration.diff(now, 'hours') % 24; // Calculate remaining hours
    const diffInMinutes = expiration.diff(now, 'minutes') % 60; // Calculate remaining minutes
    const daysRemaining = diffInDays > 0 ? diffInDays + ' days ' : '';
    const hoursRemaining = diffInHours > 0 ? diffInHours + ' hours ' : '';
    const minutesRemaining = diffInMinutes > 0 ? diffInMinutes + ' minutes ' : '';

    if (diffInDays > 0) {
      return daysRemaining + hoursRemaining + 'remaining';
    }

    if (diffInHours > 0) {
      return hoursRemaining + minutesRemaining + 'remaining';
    }

    if (diffInMinutes > 0) {
      return minutesRemaining + 'remaining';
    }
    return 'Expired';
  }

  const onButtonPress = (unique_string_id) => {
    if (unique_string_id) {
      layout == 'web'
        ? window.open('https://agent.openbrix.co.uk/preview-tenants/' + unique_string_id, '_blank')
        : Linking.openURL('https://agent.openbrix.co.uk/preview-tenants/' + unique_string_id);
    }
  };
  const renderNewShare = () => {
    return (
      <ODSButton
        lIcon={'TB-Add-Plus'}
        restStyle={{ width: 160, alignSelf: 'flex-end', marginTop: 16 }}
        type="primary"
        onPress={() => setShowModal(true)}
        themeColor={colors.main.purple}
        disabled={false}
      >
        New Share
      </ODSButton>
    );
  };
  return (
    <>
      <View style={styles.container}>
        <ODSText type="sm" textAlign="center" marginTop={32} color={colors.text.secondary}>
          Here you can generate a temporary code that will allow the receiver (eg. your agent) to
          view your shared documents in a secure portal.
        </ODSText>
        {renderNewShare()}
        <View
          style={[
            styles.divider,
            {
              backgroundColor: colors.other['border-neutral'],
              marginVertical: layout == 'web' ? 32 : 16,
            },
          ]}
        />
        {(active.length || expire.length) > 0 ? (
          <ODSText type="md" textAlign="center" color={colors.text.secondary}>
            Share Codes Generated
          </ODSText>
        ) : null}

        <View style={[styles.docsContainer, { marginVertical: layout == 'web' ? 20 : 24 }]}>
          {active.length > 0 &&
            active.map((e) => (
              <View
                key={e.id}
                style={[
                  styles.docItem,
                  {
                    borderColor: colors.other['border-neutral'],
                    shadowColor: '#101828',
                    width: layout == 'web' ? 407 : layout == 'tab' ? 298 : '100%',
                  },
                ]}
              >
                <View>
                  <ODSText width="100%" type="md" color={colors.text.primary}>
                    {e?.email}
                  </ODSText>
                  <ODSText marginTop={4} type="xs" color={colors.text.secondary}>
                    {getRelativeTime(e?.expire_date) == 'Expired'
                      ? moment(e?.expire_date).format('DD/MM/YYYY')
                      : getRelativeTime(e?.expire_date)}
                  </ODSText>
                </View>
                <ODSButton
                  restStyle={{ height: 48 }}
                  type="secondary"
                  onPress={() => onButtonPress(e?.unique_string_id)}
                  themeColor={colors.main.purple}
                >
                  Preview
                </ODSButton>
              </View>
            ))}
        </View>
        {expire.length > 0 ? (
          <TouchableOpacity
            onPress={() => setShowExpiredShareCode(!showExpire)}
            style={{
              flexDirection: 'row',
              padding: 20,
              borderWidth: 1,
              borderColor: colors.neutral[300],
              borderRadius: 8,
              alignItems: 'center',
            }}
          >
            <ODSIcon
              style={{ marginRight: 20 }}
              color={colors.main.blue[400]}
              icon={showExpire ? 'TB-Caret-Down' : 'TB-Caret-Up'}
              size={26}
            />

            <ODSText marginLeft={10} textAlign={'center'} color="black" type={'sm'}>
              {showExpire ? 'Hide expired code status' : 'Show expired code status'}
            </ODSText>
          </TouchableOpacity>
        ) : null}

        {showExpire ? (
          <View style={styles.docsContainer}>
            <View
              style={{
                width: '100%',
                height: 1,
                backgroundColor: colors.neutral[300],
                marginVertical: 24,
              }}
            />
            {expire.length > 0 &&
              expire.map((e) => (
                <View
                  key={e.id}
                  style={[
                    styles.docItem,
                    {
                      borderColor: colors.other['border-neutral'],
                      shadowColor: '#101828',
                      width: layout == 'web' ? 407 : layout == 'tab' ? 298 : '100%',
                    },
                  ]}
                >
                  <View style={{ flex:1,marginRight:9 }}>
                    <ODSText width="100%" type="md" color={colors.text.primary}>
                      {e?.email}
                    </ODSText>
                  </View>
                  <ODSButton
                    restStyle={{ width: 120, height: 48 }}
                    type="secondary"
                    onPress={() => onButtonPress(e?.unique_string_id)}
                    themeColor={colors.feedback.error}
                  >
                    Expired
                  </ODSButton>
                </View>
              ))}
          </View>
        ) : (
          false
        )}

        {active?.length || expire?.length?  renderNewShare():
         <ODSText type="md" textAlign="center" color={colors.text.secondary}>
        Please click "New Share" to begin sharing your data.
       </ODSText>
        
        }

        <ODSModal
          visible={showModal}
          backdropOpacity={0}
          onBackdropPress={() => setShowModal(false)}
        >
          <View
            style={{
              width: layout == 'phone' ? '95%' : 450,
              height: layout == 'phone' && 680,
              alignSelf: 'center',
              backgroundColor: 'white',
              padding: 24,
              borderRadius: 12,
              shadowColor: '#101828',
              shadowOffset: { width: 0, height: 1 },
              shadowOpacity: 0.1,
              shadowRadius: 4,
              elevation: 2,
            }}
          >
            <SCSteps
              DocumentData={DocumentData}
              onModalClose={() => setShowModal(false)}
              onShareSave={onShareSave}
            />
          </View>
        </ODSModal>
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    width: '100%',
  },
  divider: {
    width: '100%',
    height: 1,
  },
  docsContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  docItem: {
    flexDirection: 'row',
    borderRadius: 12,
    paddingHorizontal: 12,
    paddingVertical: 24,
    borderWidth: 1,
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.1,
    shadowRadius: 4,
    elevation: Platform.OS == 'android' ? 0 : 5,
    marginBottom: 16,
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  docTextContainer: {
    marginLeft: 16,
  },
  deleteButton: {
    marginLeft: 'auto',
    width: 32,
    height: 32,
    borderRadius: 20,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default ShareCenter;
