import React, { useState } from 'react';
import { StyleSheet, View, Platform } from 'react-native';
import { useTheme } from '@emotion/react';
import { ODSIcon } from '../../../atoms/ODSIcon';
import { ODSText } from '../../../atoms/ODSText';
import { ODSInput } from '../../../atoms/ODSInput';
import ODSButton from '../../../atoms/ODSButton';

const ChangePasswordCard = ({ onClose, onChangePassword }) => {
  const { colors } = useTheme();

  const [form, setForm] = useState({
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
  });

  const [errors, setErrors] = useState({});

  const validateForm = () => {
    let valid = true;
    let tempErrors = {};

    if (!form.currentPassword) {
      tempErrors.currentPassword = 'Current password is required.';
      valid = false;
    }

    if (form.newPassword.length < 6) {
      tempErrors.newPassword = 'New password must be at least 6 characters long.';
      valid = false;
    }

    if (form.newPassword !== form.confirmPassword) {
      tempErrors.confirmPassword = 'New password and confirm password do not match.';
      valid = false;
    }

    setErrors(tempErrors);
    return valid;
  };

  const handleChangePassword = () => {
    if (validateForm()) {
      onChangePassword(form);
      onClose();
    }
  };

  return (
    <View style={{ flex: 1 }}>
      <View style={{ justifyContent: 'center', alignItems: 'center', flexDirection: 'row', marginBottom: 24 }}>  
        <ODSIcon icon={'TB-Lock'} size={24} color={colors.button.main} />
        <ODSText type='h5' marginLeft={12} color={colors.text.primary}>Change Password</ODSText>
      </View>
      <ODSInput
        label='Current Password'
        secureTextEntry={true}
        value={form.currentPassword}
        onChange={(value) => setForm({ ...form, currentPassword: value })}
        hints={errors.currentPassword ? [{ state: 'error', hint: errors.currentPassword }] : []}
      />
      <ODSInput
        label='New Password'
        secureTextEntry={true}
        value={form.newPassword}
        onChange={(value) => setForm({ ...form, newPassword: value })}
        hints={errors.newPassword ? [{ state: 'error', hint: errors.newPassword }] : []}
      />
      <ODSInput
        label='Confirm New Password'
        secureTextEntry={true}
        value={form.confirmPassword}
        onChange={(value) => setForm({ ...form, confirmPassword: value })}
        hints={errors.confirmPassword ? [{ state: 'error', hint: errors.confirmPassword }] : []}
      />
      <ODSButton restStyle={{ marginTop: 32 }} onPress={handleChangePassword}>
        Change Password
      </ODSButton>
      <ODSButton onPress={() => onClose()} restStyle={{ marginTop: 24 }} type='secondary'>
        Close
      </ODSButton>
    </View>
  );
};

const styles = StyleSheet.create({
  shadowBox: {
    backgroundColor: '#fff',
    marginHorizontal: 20,
    padding: 32,
    borderRadius: 12,
    ...Platform.select({
      ios: {
        shadowColor: '#000',
        shadowOffset: { width: 1, height: 3 },
        shadowOpacity: 0.15,
        shadowRadius: 8,
      },
      android: {
        elevation: 4,
      },
      web: {
        shadowColor: '#000',
        shadowOffset: { width: 1, height: 3 },
        shadowOpacity: 0.15,
        shadowRadius: 8,
      }
    }),
  },
});

export default ChangePasswordCard;
