import React from "react";
import { Image, TouchableOpacity, View } from "react-native";
import { useTheme } from "@emotion/react";
import { ODSText } from "../../../openbrix-design-system/ui/atoms/ODSText";
import getScreenType from "../../../openbrix-design-system/utils/getScreenType";
import { Body, Content, DoYouKnow, HeaderContent, HeaderImage, Picture1 } from "./styles";
import Wrapper from "./Wrapper";
import { ODSIcon } from "../../../openbrix-design-system/ui/atoms/ODSIcon";
import ODSButton from "../../../openbrix-design-system/ui/atoms/ODSButton/index.native";
import { navigateAction } from "../../helpers/navigationHelpers";
import { useNavigation } from "@react-navigation/native";
const HeaderWeb = require('../../../openbrix-design-system/images/tlyfe-pages/ti-desktop.png');
const HeaderTab = require('../../../openbrix-design-system/images/tlyfe-pages/ti-tab.png');
const HeaderMobile = require('../../../openbrix-design-system/images/tlyfe-pages/ti-mobile.png');
const Picture = require('../../../openbrix-design-system/images/tlyfe-pages/tenant-insurance/picture1.png');
const FooterWeb=require('../../../openbrix-design-system/images/tlyfe-pages/tenant-insurance/footer-web.png');
const FooterTab=require('../../../openbrix-design-system/images/tlyfe-pages/tenant-insurance/footer-tab.png');
const FooterMobile=require('../../../openbrix-design-system/images/tlyfe-pages/tenant-insurance/footer-mobile.png');


const InsuranceLanding = () => {
  const { colors } = useTheme();
  const layout = getScreenType();
  const navigation=useNavigation();
  return (
    <><Wrapper
    theme={colors.transparency.pink}
    drawerColor={colors.theme.pink}
    type={"insurance"}
    headerTitle={'Tenant contents insurance with tlyfe'}
    headerBody={'Enjoy your rental home, knowing your possessions and valuables are protected with our comprehensive cover.'}
    headerImage={layout == "web" ? HeaderWeb : layout == "tab" ? HeaderTab : HeaderMobile}
      rightImage={Picture}
      leftText={'Could you afford to replace your possessions if they were stolen or destroyed by flood or fire?'}
      footerImage={layout == "web" ? FooterWeb : layout == "tab" ? FooterTab : FooterMobile}
    >
      <Body>
        <View style={{ padding: layout == "phone" ? 24 : 0 }}>
          <View style={{ flexDirection: 'row', flexWrap: 'wrap', marginTop: layout == "web" ? 32 : 24, gap: layout == "web" ? 64 : 24 }}>
            <Content>
              <ODSText  type="h6-light" color={colors.text.primary}>{`Our Tenant Contents Insurance policy will protect you against loss or damage to your belongings,

for insurable perils such as fire, theft, escape of water and much more. 

In addition, the policy will automatically provide cover of up to £10,000 for accidental damage to your Landlord’s fixtures and fittings which you may be responsible for under the terms of your tenancy agreement.`}</ODSText>

            </Content>
            <Content>
              <ODSText type="h4" color={colors.text.primary}>Features and benefits</ODSText>

              <View style={{flexDirection:layout=="phone"?'column':'row',justifyContent:'space-between',marginTop:20}}>
                <View style={{gap:16,width:layout=="phone"?'100%':'45%'}}>
                <View style={{flexDirection:'row',gap:16,width:'100%'}}>
                 <Image source={require('../../../openbrix-design-system/images/icons/pink-tick.png')} style={{width:32,height:32}}/>
                  <ODSText width="100%" type="md" color={colors.text.primary}>Tenants liability cover up to £5,000 - This provides cover for accidental damage to landlords  fixtures and fittings which you may be responsible for as noted in your tenancy agreement.</ODSText>
                </View>
                <View style={{flexDirection:'row',gap:16,width:'100%',alignItems:'center'}}>
                  <Image source={require('../../../openbrix-design-system/images/icons/pink-tick.png')} style={{width:32,height:32}}/>
                  <ODSText  type="md" color={colors.text.primary}>Legal Liability cover up to £2,000,000</ODSText>
                </View>
                <View style={{flexDirection:'row',gap:16,width:'100%',alignItems:'center'}}>
                  <Image source={require('../../../openbrix-design-system/images/icons/pink-tick.png')} style={{width:32,height:32}}/>
                  <ODSText  type="md" color={colors.text.primary}>Personal money cover up to £1,000</ODSText>
                </View>
                <View style={{flexDirection:'row',gap:16,width:'100%',alignItems:'center'}}>
                  <Image source={require('../../../openbrix-design-system/images/icons/pink-tick.png')} style={{width:32,height:32}}/>
                  <ODSText  type="md" color={colors.text.primary}>Credit cards cover up to £500</ODSText>
                </View>
                <View style={{flexDirection:'row',gap:16,width:'100%',alignItems:'center'}}>
                  <Image source={require('../../../openbrix-design-system/images/icons/pink-tick.png')} style={{width:32,height:32}}/>
                  <ODSText  type="md" color={colors.text.primary}>Freezer contents cover up to £1,000</ODSText>
                </View>
                <View style={{flexDirection:'row',gap:16,width:'100%',alignItems:'center'}}>
                  <Image source={require('../../../openbrix-design-system/images/icons/pink-tick.png')} style={{width:32,height:32}}/>
                  <ODSText  type="md" color={colors.text.primary}>Key and lock replacement up to £1000</ODSText>
                </View>
                </View>
                <View style={{gap:16,width:layout=="phone"?'100%':'45%',marginTop:layout=="phone"?16:0}}>
                <View style={{flexDirection:'row',gap:16,width:'100%',alignItems:'center'}}>
                  <Image source={require('../../../openbrix-design-system/images/icons/pink-tick.png')} style={{width:32,height:32}}/>
                  <ODSText type="md" color={colors.text.primary}>Alternative accommodation cover up to £3000</ODSText>
                </View>
                <View style={{flexDirection:'row',gap:16,width:'100%',alignItems:'center'}}>
                  <Image source={require('../../../openbrix-design-system/images/icons/pink-tick.png')} style={{width:32,height:32}}/>
                  <ODSText  type="md" color={colors.text.primary}>Optional accidental damage cover</ODSText>
                </View>
                <View style={{flexDirection:'row',gap:16,width:'100%',alignItems:'center'}}>
                  <Image source={require('../../../openbrix-design-system/images/icons/pink-tick.png')} style={{width:32,height:32}}/>
                  <ODSText  type="md" color={colors.text.primary}>Optional pedal cycle cover - Maximum 5 cycles, maximum limit £750 for any one bicycle</ODSText>
                </View>
                <View style={{flexDirection:'row',gap:16,width:'100%',alignItems:'center'}}>
                  <Image source={require('../../../openbrix-design-system/images/icons/pink-tick.png')} style={{width:32,height:32}}/>
                  <ODSText  type="md" color={colors.text.primary}>‘New for old’ cover</ODSText>
                </View>
                <View style={{flexDirection:'row',gap:16,width:'100%',alignItems:'center'}}>
                  <Image source={require('../../../openbrix-design-system/images/icons/pink-tick.png')} style={{width:32,height:32}}/>
                  <ODSText  type="md" color={colors.text.primary}>24 hour, 365 days a year claims helpline</ODSText>
                </View>
                </View>

              </View>
            </Content>
          </View>
        </View>
      </Body>
     
      
      <View style={{width:'100%',height:layout=="phone"?432:190,backgroundColor:colors.theme.purple,justifyContent:'center',alignItems:'center',flexDirection:layout=="phone"?'column':'row',marginTop:80}}>
     <View>
     <DoYouKnow>
        <ODSText type="h5" color={colors.text.primary}>Did you know?</ODSText>
        <ODSText type="md" marginTop={20} color={colors.text.secondary}>For a small additional premium you can also add optional extras to your policy such as Personal Possessions cover. This means that the items you take outside your home and carry with you everyday would be covered if they were lost, damaged or stolen anywhere in the world.</ODSText>
      </DoYouKnow>
     </View>
     <View style={{marginLeft:layout=="phone"?0:30,alignItems:'center'}}>
     <ODSText type="h3" color={colors.text.inverted}>Download today!</ODSText>
      <View>
      <ODSButton type={'secondary'} onPress={()=>navigateAction(navigation, "Sign in")} restStyle={{width:270,marginBottom:12,marginTop:24,alignSelf:'center'}}>Web login / sign up</ODSButton>
        <View style={{ flexDirection: layout == "phone" ? 'column' : 'row', gap: 16, alignItems: 'center' ,marginTop:32}}>
        <TouchableOpacity onPress={() => window.open('https://apps.apple.com/in/app/tlyfe/id6449941281')}>
             <Image source={require('../../../openbrix-design-system/images/appstore.png')} style={{ width: 180, height: 52 }} />
             </TouchableOpacity>
             <TouchableOpacity onPress={() => window.open('https://play.google.com/store/apps/details?id=com.tlyfe&hl=en_IN&gl=US')}>
              <Image source={require('../../../openbrix-design-system/images/playstore.png')} style={{ width: 180, height: 52 }} />
           </TouchableOpacity>
            </View>
            </View>

     </View>
      
      </View>

    </Wrapper>
</>
  )
}
export default InsuranceLanding;