import React, { useState } from 'react';
import { Text, StyleSheet, TouchableOpacity, View, ImageBackground, FlatList, Platform } from 'react-native';

import { useTheme } from '@emotion/react';
import getScreenType from '../../../../utils/getScreenType';
import { ODSIcon } from '../../../atoms/ODSIcon';
import { ODSText } from '../../../atoms/ODSText';


const MyInvoiceCard = (props) => {
  const { colors } = useTheme();
  const layout = getScreenType();

  return (
    <View style={{ flex: 1, ...styles.shadowBox }}>
      <View style={{ justifyContent: 'center', alignItems: 'center', flexDirection: 'row', marginBottom: 32 }}>
        <ODSIcon icon={'TB-Book'} size={24} color={colors.button.main} />
        <ODSText type='h5' marginLeft={12} color={colors.text.primary}>My Invoice</ODSText>
      </View>
      {props.children}
    </View>
  );
};
const styles = StyleSheet.create({
  shadowBox: {
    backgroundColor: '#fff',
    marginHorizontal: 20,
    padding: 32,
    borderRadius: 12,
    ...Platform.select({
      ios: {
        shadowColor: '#000',
        shadowOffset: { width: 1, height: 3 },
        shadowOpacity: 0.15,
        shadowRadius: 8,
      },
      android: {
        elevation: 4,
      },
      web: {
        shadowColor: '#000',
        shadowOffset: { width: 1, height: 3 },
        shadowOpacity: 0.15,
        shadowRadius: 8,
      }
    }),
  },
});


export default MyInvoiceCard;
