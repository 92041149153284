import React, { useEffect, useState } from 'react';
import { Hoverable } from 'react-native-web-hooks';
import { ImageBackground, View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesome, MaterialCommunityIcons, MaterialIcons } from '@expo/vector-icons';
import { Typography } from '../../openFlow/style';
import { TouchableOpacity } from 'react-native-web';
import { COLORS } from '../../../constants/colorPallete';
import getPlatformType, { getImage } from '../../../helpers/getPlatform';
import { navigateAction, replaceAction } from '../../../helpers/navigationHelpers';
import { tenantActions } from '../../openFlow/openFlowRedux/tenant.actions';
import BackGroundGenerator from '../../LandingPages/common/BackGroundGenerator';
import {
  BoxShadow,
  ButtonText,
  CustomButton,
  Heading2,
  Wrapper,
} from './../stylesNew';
import ODSButton from '../../../../openbrix-design-system/ui/atoms/ODSButton';
import { ODSText } from '../../../../openbrix-design-system/ui/atoms/ODSText';
import ODSTiles from '../../../../openbrix-design-system/ui/atoms/ODSTiles';
import { getTokens } from '../../../../openbrix-design-system/design-tokens/color-tokens';
import { navigate } from '../../../navigation/RootNavigation';

const FEATURED = [
  {
    heading: 'Rent Ready',
    heading2: '',
    subheading:
      'Give yourself the best chance of securing your next rental property by using our Pre-Qualification tool to become a ‘Rent Ready’ Tenant. Share the results with your agent to secure priority viewings!',
    imageUrl: require('../../LandingPages/landingTlyfe/images/tlyfe/preq.png'),
  },
];

const PreQualification = (props) => {
  const themeObj = getTokens('light').colors;
  const { navigation } = props;
  const dispatch = useDispatch();
  const layout = getPlatformType();
  const [type, setType] = useState('web');
  const [preQualData, setPreQualData] = React.useState([]);
  const globalState = useSelector((state) => state?.tenant);
  const [hoverStartTime, setHoverStartTime] = React.useState(null);
  const userData = useSelector((state) => state.authReducer?.profile?.data?.appUser);
  const { affordability, fcc_pre_reference, id_verification, right_to_rent, tenant_info } =
    preQualData;
  const userId = useSelector((state) => state.authReducer?.profile?.data?.appUser?.app_user_id);

  useEffect(() => {
    dispatch(tenantActions.getPreQualificationStatus());
  }, []);

  useEffect(() => {
    if (globalState?.preQualData?.preQualStatus) {
      setPreQualData(globalState?.preQualData?.preQualStatus);
    }
  }, [globalState]);

  useEffect(() => {
    return () => {
      setHoverStartTime(0);
    };
  }, []);

  //commented out until tom or someone else can finish the backend for this
  const handleHoverIn = () => {
    //  setHoverStartTime(Date.now());
  };

  const handleHoverOut = (type) => {
    // if (hoverStartTime) {
    //   const duration = (Date.now() - hoverStartTime) / 1000.0;
    //
    //   dispatch(
    //     appUserTrackingProductHover({
    //       app_user_id: userId,
    //       product: type,
    //       hover_time_seconds: duration,
    //       current_page: "PreQualification",
    //     }),
    //   );
    //   // Perform any additional actions with the hover duration here
    // }
    // setHoverStartTime(null);
  };

  const renderIDVerStatus = () => {
    if (id_verification?.idverification == 2) {
      return "Completed"
    } else if (id_verification?.idverification == 9) {
      return "Failed"
    } else if (id_verification?.idverification == 1 || id_verification?.payment_required==false) {
      return "In Progress"
    }else{
      return "Not Started"
    }
  };

  const renderIDVer = () => {

    return (
      <ODSTiles
      isRight
        source={require('../../../../openbrix-design-system/images/id.png')}
        title={'4. ID Verification'}
        subtitle="Verify your ID digitally in a safe environment with our partner Yoti."
        status={
          renderIDVerStatus()
        }
        btnTheme={themeObj.main.pink}
        onPress={() => {
          navigateAction(navigation, 'PreQualificationIdAndVerification');
        }}
      />
      // <Background
      //   source={require("../../../assets/images/tlyfeimages/idverification.png")}
      //   width={"344px"}
      //   padding="0px 75px 0px 18px"
      //   resizeMode="cover"
      //   imageStyle={{
      //     borderRadius: 10,
      //   }}
      // >
      //   <View style={{
      //     width: "53%",
      //     flex: 1,
      //   }}>
      //     <View style={{ flex: 0.8 }}>

      //       <View
      //         style={{
      //           flexDirection: "row",
      //           alignItems: "center",
      //           marginTop: 16,
      //         }}
      //       >
      //         {renderIDVerStatus()}
      //       </View>
      //       <Heading1 color={COLORS.LGREEN} top={"10px"} onPress={() => {
      //         navigateAction(navigation, "PreQualificationIdAndVerification");
      //       }}>
      //         4. ID Verification
      //       </Heading1>
      //       <Heading2 color={"#000000"} top={"13px"}>
      //         Verify your ID digitally in a safe environment with our partner Yoti.
      //       </Heading2>
      //     </View>
      //     <View style={{ flex: 0.2 }}>
      //       <CustomButton
      //         onPress={() => {
      //           navigateAction(navigation, "PreQualificationIdAndVerification");
      //         }}
      //         bcolor={COLORS.LGREEN}
      //         color={"white"}
      //         width={"150px"}
      //         id={"idverGetStated"}
      //       >
      //         <ButtonText color={"#000000"}>Get started</ButtonText>
      //         <MaterialIcons name="keyboard-arrow-right" size={24} color={COLORS.LGREEN}/>
      //       </CustomButton>
      //     </View>
      //   </View>
      // </Background>
    );
  };

  const renderAffordabilityStatus = (affordability) => {
    if (affordability?.affordability_amount) {
      return (
        <>
          <FontAwesome style={{ marginRight: 6 }} name="check" size={16} color={COLORS.LGREEN} />
          <Heading2 color={'#686868'}>Completed</Heading2>
        </>
      );
    }
  };

  const renderRtrStatus = () => {
    if (id_verification?.idverification == 2 || right_to_rent?.right_to_rent) {
      return (
        <>
          <FontAwesome style={{ marginRight: 9 }} name="check" size={16} color={COLORS.LGREEN} />
          <Heading2 color={'#686868'}>Completed</Heading2>
        </>
      );
    } else if (id_verification?.idverification == 1) {
      return (
        <>
          <MaterialCommunityIcons
            name="progress-clock"
            size={24}
            color="#FFC059"
            style={{ marginRight: 9 }}
          />
          <Heading2 color={'#686868'}>Pending</Heading2>
        </>
      );
    }
  };

  const renderInfoStatus = () => {
    if (tenant_info?.occupation || tenant_info?.marital_status) {
      return (
        <>
          <FontAwesome style={{ marginRight: 9 }} name="check" size={16} color={COLORS.LGREEN} />
          <Heading2 color={'#686868'}>Completed</Heading2>
        </>
      );
    }
  };
  const renderRtrButton = () => {
    if (userData?.right_to_rent == null) {
      return (
        <CustomButton
          onPress={() => {
            navigateAction(navigation, 'PreQualificationRightToRent');
          }}
          bcolor={COLORS.LGREEN}
          color={'white'}
          width={'150px'}
          id={'ID_GET_STARTED'}
        >
          <ButtonText color={'#000000'}>Get Started</ButtonText>
          <MaterialIcons name="keyboard-arrow-right" size={24} color={COLORS.LGREEN} />
        </CustomButton>
      );
    } else {
      return (
        <Heading2
          onPress={() => {
            navigateAction(navigation, 'PreQualificationRightToRent');
          }}
          color={'#000000'}
          underline
          id={'ID_CHECK_PROGRESS'}
        >
          Check your progress
        </Heading2>
      );
    }
  };

  const renderRtr = () => {
    return (
      <ODSTiles
        source={require('../../../../openbrix-design-system/images/rtr.png')}
        title={'3. Right to Rent'}
        subtitle="You are required by law to prove you have a Right to Rent in England."
        status={
          right_to_rent?.right_to_rent ? 'Completed' : 'Not Started'
        }
        // btnTheme={themeObj.main.purple}

        onPress={() => {
          navigateAction(navigation, 'PreQualificationRightToRent');
        }}
      />
      // <Background
      //   padding="0px 75px 0px 18px"
      //   source={require("../../../assets/images/tlyfeimages/righttorent.png")}
      //   height={"263px"}
      //   width={"344px"}
      //   resizeMode="cover"
      //   imageStyle={{
      //     borderRadius: 10,
      //   }}
      // >
      //   <View style={{
      //     width: "63%",
      //     flex: 1,
      //   }}>
      //     <View style={{ flex: 0.8 }}>

      //       <View
      //         style={{
      //           flexDirection: "row",
      //           alignItems: "center",
      //           marginTop: 16,
      //         }}
      //       >
      //         {renderRtrStatus()}
      //       </View>
      //       <Heading1 color={COLORS.LGREEN} top={"10px"} onPress={() => {
      //         navigateAction(navigation, "PreQualificationRightToRent");
      //       }}>
      //         3. Right-to-Rent
      //       </Heading1>
      //       <Heading2 color={"#000000"} top={"13px"}>
      //         If you do not hold a British or Irish passport Passport, you are required by law to verify your "right to
      //         rent" in the UK.
      //       </Heading2>
      //     </View>
      //   </View>
      //   <View style={{ flex: 0.25 }}>
      //     {renderRtrButton()}
      //   </View>
      // </Background>
    );
  };
  const renderTenantInfoButton = () => {
    return (
      <CustomButton
        onPress={() => {
          navigateAction(navigation, 'PreQualificationTenantInfo');
        }}
        bcolor={COLORS.LGREEN}
        color={'white'}
        width={'150px'}
        id={'ID_GET_STARTED'}
      >
        <ButtonText color={'#000000'}>Go</ButtonText>
        <MaterialIcons name="keyboard-arrow-right" size={24} color={COLORS.LGREEN} />
      </CustomButton>
    );
  };
  const renderTenantInfo = () => {
    return (
      <ODSTiles
      isRight
        source={require('../../../../openbrix-design-system/images/tinfo.png')}
        title={'2. Tenant Info'}
        subtitle="Allow agents and landlords to get to know you!"
        status={
          tenant_info?.occupation || tenant_info?.marital_status ? 'Completed' : 'Not Started'
        }
        btnTheme={themeObj.main.purple}
        onPress={() => {
          navigateAction(navigation, 'PreQualificationTenantInfo');
        }}
      />
      // <Background
      //   padding="0px 75px 0px 18px"
      //   source={require("../../../assets/images/tlyfeimages/righttorent.png")}
      //   height={"263px"}
      //   width={"344px"}
      //   resizeMode="cover"
      //   imageStyle={{
      //     borderRadius: 10,
      //   }}
      // >
      //   <View style={{
      //     width: "63%",
      //     flex: 1,
      //   }}>
      //     <View style={{ flex: 0.8 }}>

      //       <View
      //         style={{
      //           flexDirection: "row",
      //           alignItems: "center",
      //           marginTop: 16,
      //         }}
      //       >
      //         {renderInfoStatus()}
      //       </View>
      //       <Heading1 color={COLORS.LGREEN} top={"10px"} onPress={() => {
      //         navigateAction(navigation, "PreQualificationTenantInfo");
      //       }}>
      //        2. Tenant Info
      //       </Heading1>
      //       <Heading2 color={"#000000"} top={"13px"}>
      //       Allow agents and landlords to get to know you!
      //       </Heading2>
      //     </View>
      //   </View>
      //   <View style={{ flex: 0.25 }}>
      //     {renderTenantInfoButton()}
      //   </View>
      // </Background>
    );
  };
  const renderAffordablity = (affordability) => {
    console.log('affordability', affordability);
    return (
      <ODSTiles
      
        source={require('../../../../openbrix-design-system/images/aff.png')}
        title={'1. Affordability'}
        subtitle="Understand how much rent you can afford to pay."
        status={affordability?.affordability_amount ? 'Completed' :affordability?.guarantor?.affordbility_amount||affordability?.affordability_amount ? "In Progress": 'Not Started'}
        btnTheme={themeObj.main.pink}
        onPress={() => {
          navigateAction(navigation, 'PreQualificationAffordability');
        }}
      />
      // <Background
      //   padding="0px 75px 0px 18px"
      //   source={require("../../../assets/images/tlyfeimages/afford.png")}
      //   height={"263px"}
      //   width={"344px"}
      //   resizeMode="cover"
      //   imageStyle={{
      //     borderRadius: 10,
      //   }}
      // >
      //   <View style={{
      //     width: "63%",
      //     flex: 1,
      //   }}>
      //     <View style={{ flex: 0.8 }}>
      //       <View
      //         style={{
      //           flexDirection: "row",
      //           alignItems: "center",
      //           marginTop: 16,
      //         }}
      //       >
      //         {renderAffordabilityStatus(affordability)}
      //       </View>

      //       <Heading1
      //         top={"10px"}
      //         color={COLORS.LGREEN}
      //         onPress={() => {
      //           navigateAction(navigation, "PreQualificationAffordability");
      //         }}>
      //         1. Affordability
      //       </Heading1>
      //       <Heading2 color={"#000000"} top={"13px"}>
      //         Understand how much rent you can afford to pay.
      //       </Heading2>
      //     </View>
      //     <View style={{ flex: 0.2 }}>
      //       {affordability?.affordbility_amount ?
      //         <Heading2
      //           onPress={() => {
      //             navigateAction(navigation, "PreQualificationAffordability");
      //           }}
      //           color={"#000000"}
      //           underline
      //         >
      //           Check your progress
      //         </Heading2>
      //         : <CustomButton
      //           onPress={() => {
      //             navigateAction(navigation, "PreQualificationAffordability");
      //           }}
      //           bcolor={COLORS.LGREEN}
      //           color={"white"}
      //           width={"150px"}
      //           id={"TDS-SEARCH-DEPOSIT-GET-STARTED"}
      //         >
      //           <ButtonText id={"TDS-SEARCH-DEPOSIT-GET-STARTED"} color={"#000000"}>
      //             Get started
      //           </ButtonText>
      //           <MaterialIcons name="keyboard-arrow-right" size={24} color={COLORS.LGREEN}/>
      //         </CustomButton>}
      //     </View>
      //   </View>
      // </Background>
    );
  };

  const renderPreRefStatus = () => {
    if (fcc_pre_reference?.status == 'completed') {
      return (
        <>
          <FontAwesome style={{ marginRight: 6 }} name="check" size={16} color={COLORS.LGREEN} />
          <Heading2 color={'#686868'}>Completed</Heading2>
        </>
      );
    }
    if (fcc_pre_reference?.status == 'in_progress') {
      return (
        <>
          <MaterialCommunityIcons
            name="progress-clock"
            size={24}
            color="#FFC059"
            style={{ marginRight: 9 }}
          />
          <Heading2 color={'#686868'}>Pending</Heading2>
        </>
      );
    }
  };

  const renderPreRef = () => {
    return (
      <ODSTiles
        source={require('../../../../openbrix-design-system/images/ref.png')}
        title={'5. Rent Ready Reference'}
        subtitle="Give yourself a head start in the rental process by confirming you meet the required criteria to become a Rent Ready Tenant."
        status={
          fcc_pre_reference?.status == 'completed' ? 'Completed'  :
         ( fcc_pre_reference?.status == 'in_progress'|| fcc_pre_reference?.payment_required==false)?"In Progress":
          'Not Started'
        }
        btnTheme={themeObj.main.purple}
        onPress={() => {
          navigateAction(navigation, 'PreQualificationPreReference');
        }}
      />
      // <Background
      //   source={require("../../../assets/images/tlyfeimages/preref.png")}
      //   height={"263px"}
      //   width={"344px"}
      //   padding="0px 75px 0px 18px"
      //   resizeMode="cover"
      //   imageStyle={{
      //     borderRadius: 10,
      //   }}
      // >
      //   <View style={{
      //     width: "63%",
      //     flex: 1,
      //   }}>
      //     <View style={{ flex: 0.8 }}>
      //       <View
      //         style={{
      //           flexDirection: "row",
      //           alignItems: "center",
      //           marginTop: 16,
      //         }}
      //       >
      //         {renderPreRefStatus()}
      //       </View>
      //       <Heading1 color={COLORS.LGREEN} top={"10px"}
      //                 onPress={() => {
      //                   navigateAction(navigation, "PreQualificationPreReference");
      //                 }}
      //       >
      //         5. Rent Ready Reference
      //       </Heading1>
      //       <Heading2 color={"#000000"} top={"8px"}>
      //         Give yourself a head start in the rental process by confirming you meet the required criteria to become a
      //         Rent Ready Tenant.
      //       </Heading2>
      //     </View>
      //     <View style={{ flex: 0.2 }}>
      //       <CustomButton
      //         onPress={() => {
      //           navigateAction(navigation, "PreQualificationPreReference");
      //         }}
      //         width={"150px"}
      //         bcolor={COLORS.LGREEN}
      //         color={"white"}
      //       >
      //         <ButtonText color={"#000000"}>Get started</ButtonText>
      //         <MaterialIcons name="keyboard-arrow-right" size={24} color={COLORS.LGREEN}/>
      //       </CustomButton>
      //     </View>
      //   </View>
      // </Background>
    );
  };

  return (
    <BackGroundGenerator isTLYF props={props}>
      <ImageBackground
        source={getImage("happy.png")}
        style={{
          width: '100%',
          height: '189px',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
        resizeMode="cover"
      >
        <ODSButton
          rIcon={'TB-Arrow-Left'}
          restStyle={{ marginTop: 20, marginLeft: 20 }}
          type="secondary"
          disabled={false}
          onPress={() => replaceAction(props?.navigation, 'Dashboard')}
        >
          My Dashboard
        </ODSButton>
      </ImageBackground>
      <View
        style={{
          alignSelf: 'center',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: 20,
          paddingHorizontal: 20,
          maxWidth: layout == 'web' ? 843 : '100%',
        }}
      >
        <ODSText marginLeft={6} style={{ alignSelf: 'center' }} type={'h5'}>
          Rent Ready
        </ODSText>
        <ODSText textAlign="center" marginTop={10} type={'button'}>
          By pre-qualifying yourself, you are giving yourself a head start in the rental process.
        </ODSText>
        <ODSText textAlign="center" marginTop={10} type={'sm'}>
          Before you begin viewing properties, you can present your agent with verified evidence of
          your affordability, confirmation you have a Right to Rent and a reference confirming you
          meet the criteria required to rent a property.
        </ODSText>
      </View>

      <Wrapper style={{ flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center',alignItems:"flex-start" }}>
        <Hoverable
          onHoverIn={() => handleHoverIn('PreQualificationAffordability')}
          onHoverOut={() => handleHoverOut('PreQualificationAffordability')}
        >
          <BoxShadow right={'40px'}>{renderAffordablity(affordability)}</BoxShadow>
        </Hoverable>
        <Hoverable
          onHoverIn={() => handleHoverIn('TenantInfo')}
          onHoverOut={() => handleHoverOut('TenantInfo')}
        >
          <BoxShadow>{renderTenantInfo()}</BoxShadow>
        </Hoverable>

        <Hoverable
          onHoverIn={() => handleHoverIn('PreQualificationRightToRent')}
          onHoverOut={() => handleHoverOut('PreQualificationRightToRent')}
        >
          <BoxShadow>{renderRtr()}</BoxShadow>
        </Hoverable>
        <Hoverable
          onHoverIn={() => handleHoverIn('PreQualificationIdAndVerification')}
          onHoverOut={() => handleHoverOut('PreQualificationIdAndVerification')}
        >
          <BoxShadow right={'40px'}>{renderIDVer()}</BoxShadow>
        </Hoverable>

        <Hoverable
          onHoverIn={() => handleHoverIn('PreQualificationPreReference')}
          onHoverOut={() => handleHoverOut('PreQualificationPreReference')}
        >
          <BoxShadow>{renderPreRef()}</BoxShadow>
        </Hoverable>
      </Wrapper>
      <View
        style={{
          alignSelf: 'center',
          justifyContent: 'center',
          alignItems: 'center',
          marginBottom: 20,
          paddingHorizontal: 20,
          maxWidth: layout == 'web' ? 843 : '100%',
        }}
      >
        <ODSText width={278} textAlign="center" marginTop={10} type={'sm'}>
          You can share all your documents on My Share Centre
        </ODSText>
        <ODSButton
          rIcon={'TB-Share'}
          restStyle={{ width: 278, marginTop: 20 }}
          type="secondary"
          disabled={false}
          onPress={() =>  navigate('Document Share Center',{activeIndex: 2 })}
        >
      My Share Centre
        </ODSButton>
      </View>
      <View
        style={{
          paddingHorizontal: 20,
          maxWidth:'100%',
          backgroundColor:themeObj.other["background-green"],
          alignItems:"center"
        }}

      >
        <View
          style={{
            padding: layout == 'phone' ? '5%' : 25,
          }}
        >
          <ODSText width={layout=="web"?800: "100%"} textAlign="center" marginTop={10} type={'xs'}>
            {`Your agent may not insist you provide this information in order to view a property. To do so would be a breach of the Tenant Fee Act. You may elect to pay for this service to make yourself a more desirable applicant, in the same way you might pay for Priority Boarding with an airline. 

There is no guarantee your agent will accept this information. They may require that you follow their established processes which might not afford you any advantage.

If you have already had an offer accepted, you must not be asked to pay for referencing or any other service or fee upon which your tenancy relies.`}
          </ODSText>
          <TouchableOpacity
          onPress={() =>
            window.open(
              'https://fccparagon.com/wp-content/uploads/2023/03/prequalificationreferencetermsandconditions.pdf',
              '_blank'
            )
          }
        >
          <Typography
            top={'15px'}
            underline={true}
            align={'center'}
            size={'18px'}
            bold={'600'}
            color={COLORS.LGREEN}
          >
            FCC Terms and Conditions
          </Typography>
        </TouchableOpacity>
        </View>
      </View>
     
    </BackGroundGenerator>
  );
};

export default PreQualification;
