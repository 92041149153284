import React from 'react';
import styled from '@emotion/native';
import { View, TouchableOpacity, TextInputProps, Platform } from 'react-native';
import { ODSIcon } from '../ODSIcon';
import { ODSText } from '../ODSText';
import { useTheme } from '@emotion/react';
import { typographyStyles } from '../../../design-tokens/base-tokens';
import getScreenType from '../../../utils/getScreenType';

export interface InputProps extends TextInputProps {
  width?: any;
  height?: any;
  leftIcon?: any;
  renderLeftIcon?: any;
  rightIcon?: string;
  renderRightIcon?: any;
  placeholder?: string;
  renderActionItem?: any;
  label?: any;
  renderLabel?: any;
  renderRightLabel?: any;
  error?: any;
  onChange: any;
  disabled?: any;
  hints?: {
    hint: any;
    state: 'success' | 'error' | 'disabled' | 'default';
  }[];
  type?: 'phone' | 'date';
}

const StyledContainer = styled.View<{
  width?: number;
  height?: number;
  disabled?: boolean;
  error?: boolean;
  isFocused?: boolean;
  theme?: { colors: any };
}>`
  flex-direction: row;
  align-items: center;
  border-radius: 4px;
  border: ${(props) =>
    props.error
      ? `1px solid ${props.theme.colors.feedback.error[100]}`
      : props.isFocused
        ? `2px solid ${props.theme.colors.other['border-color']}`
        : `1px solid ${props.theme.colors.other['border-neutral']}`};
  width: ${(props) => (props.width ? `${props.width}px` : '100%')}; /* Default width */
  height: ${(props) => (props.height ? `${props.height}px` : '128px')}; /* Default height */
  ${(props) => {
    if (props.disabled) {
      return `
            background-color:${props.theme.colors.disabled['background']};
            opacity:0.6
        `;
    }
    return `
            background-color: ${props.theme.colors.neutral[100]};
        `;
  }};
`;

const StyledTextInput = styled.TextInput<{ error?: boolean }>`
  flex: 1;
  outline-style: none;
  color: ${(props) => props.theme.colors.text.primary};
  font-size: ${(props) => (props.screenType == 'web' ? '18px' : '16px')};
  font-weight: 400;
  // font-family:Lato-Regular;
  height: 100%;
  padding: 10px;
`;
const StyledRightItemContainer = styled.View`
  margin-right: 8px;
`;
const StyledLeftIconContainer = styled.View`
  margin-left: 8px;
`;

export function ODSTextArea({
  placeholder,
  width,
  height,
  leftIcon,
  renderLeftIcon,
  rightIcon,
  renderRightIcon,
  renderActionItem,
  label,
  renderLabel,
  renderRightLabel,
  error,
  disabled,
  hints,
  type,
  onChange = () => { },
  ...props
}) {
  const [isFocused, setIsFocused] = React.useState(false);
  const theme = useTheme();
  const screenType = getScreenType();

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };
  let leftIconContainer = null;

  if (leftIcon !== null && leftIcon !== undefined) {
    leftIconContainer = (
      <StyledLeftIconContainer>
        <ODSIcon size={22} icon={leftIcon} />
      </StyledLeftIconContainer>
    );
  } else if (renderLeftIcon !== null && renderLeftIcon !== undefined) {
    leftIconContainer = <StyledLeftIconContainer>{renderLeftIcon}</StyledLeftIconContainer>;
  }
  let rightIconContainer = null;

  if (rightIcon !== null && rightIcon !== undefined) {
    rightIconContainer = (
      <StyledRightItemContainer>
        <ODSIcon size={22} icon={rightIcon} />
      </StyledRightItemContainer>
    );
  } else if (renderRightIcon !== null && renderRightIcon !== undefined) {
    rightIconContainer = <StyledRightItemContainer>{renderRightIcon}</StyledRightItemContainer>;
  }
  const getHintTextColor = (state) => {
    switch (state) {
      case 'disabled':
        return theme.colors.disabled.text;
      case 'error':
        return theme.colors.feedback.error[700];
      case 'success':
        return theme.colors.feedback.success[700];
      default:
        return theme.colors.text.secondary;
    }
  };
  return (
    <View {...props}>
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginBottom: 6,
          alignItems: 'center',
        }}>
        {label ? (
          <ODSText
            type="sm-bold"
            color={disabled ? theme.colors.disabled.text : theme.colors.text.primary}
            paddingVertical={4}>
            {label}
          </ODSText>
        ) : (
          <View style={{ paddingVertical: 4 }}>{renderLabel}</View> || null
        )}
        {renderRightLabel && <View style={{ paddingVertical: 4 }}>{renderRightLabel}</View>}
      </View>
      <StyledContainer
        width={width}
        height={height}
        disabled={disabled}
        error={error}
        isFocused={isFocused}
        {...props}>
        {leftIconContainer}
        <StyledTextInput
          onFocus={handleFocus}
          editable={!disabled}
          error={error}
          onBlur={handleBlur}
          placeholder={placeholder}
          onChange={(e) => {
            Platform.OS == 'web' ? onChange(e.target.value) : () => { };
          }}
          onChangeText={(text) =>
            Platform.OS == 'android' || Platform.OS == 'ios' ? onChange(text) : () => { }
          }
          placeholderTextColor="#AFB6C2"
          {...props}
          screenType={screenType}
          multiline
        />
        {rightIconContainer}
        {renderActionItem && (
          <StyledRightItemContainer>{renderActionItem}</StyledRightItemContainer>
        )}
      </StyledContainer>
      {hints !== null &&
        hints !== undefined &&
        hints.map((item, index) => (
          <ODSText paddingVertical={10} type="xs" color={getHintTextColor(item.state)}>
            {item.hint}
          </ODSText>
        ))}
    </View>
  );
}
