import * as React from 'react';
import { Text, View, StyleSheet, Animated } from 'react-native';

class TextAnimator extends React.Component {
    animatedValues = [];
    animations = [];
    constructor(props) {
        super(props);

        const textArr = props.content.trim().split(' ');
        textArr.forEach((_, i) => {
            this.animatedValues[i] = new Animated.Value(0);
        });
        this.textArr = textArr;
    }

    componentDidMount() {
        this.animate(1);
    }

    componentWillUnmount() {
        // If you want to reset the animation when the component unmounts, you can call this.animate(0) here
    }

    animate(toValue = 1) {
        this.animations = this.textArr.map((_, i) => {
            return Animated.timing(this.animatedValues[i], {
                toValue,
                duration: this.props.timing,
                useNativeDriver: true, // Add this line for better performance
            });
        });

        // Start the animation sequence once
        Animated.stagger(this.props.timing / 5, this.animations).start(() => {
            if (this.props.onFinish) {
                this.props.onFinish(toValue === 1);
            }
        });
    }

    render() {
        return (
            <View style={[this.props.style, styles.textWrapper]}>
                {this.textArr.map((v, i) => {
                    return (
                        <Animated.Text
                            key={`${v}-${i}`}
                            style={[
                                this.props.textStyle,
                                styles.textStyle,
                                {
                                    opacity: this.animatedValues[i],
                                    transform: [
                                        {
                                            translateY: this.animatedValues[i].interpolate({
                                                inputRange: [0, 1],
                                                outputRange: [10, 0], // Adjust the range to control the translation
                                            }),
                                        },
                                    ],
                                },
                            ]}>
                            {v}
                            {`${i < this.textArr.length - 1 ? ' ' : ''}`}
                        </Animated.Text>
                    );
                })}
            </View>
        );
    }
}

TextAnimator.defaultProps = {
    timing: 600,
    content: '',
};

export default TextAnimator;

const styles = StyleSheet.create({
    textWrapper: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'center',
    },
});
