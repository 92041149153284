import React, { useEffect, useState } from 'react';
import Loader from './../../commonComponents/activityIndicator/activityIndicator';
import { Dimensions, Image, ImageBackground, Text, TouchableOpacity, View } from 'react-native';
import { Entypo } from '@expo/vector-icons';
import { useDispatch, useSelector } from 'react-redux';
import { showToaster } from '../../redux/actions/toast';
import {
  ButtonLinkBox,
  ButtonLinkText,
  ButtonLinkView,
  FormView,
  WideButtonView,
  WideButtonWrapper,
} from './myAccountStyles';
import WideButton from '../../commonComponents/wideButton';
import CustomTextInput from '../../commonComponents/customTextInput/customTextInput';
import DropDownPicker from '../../commonComponents/DropDownPicker';
import OpenMarket from './openMarket';
import { LogoutApi, contactUs, getProfile, referFriend, updateProfile } from '../../redux/actions/auth';
import getPlatformType from '../../helpers/getPlatform';
import { EmailValidation, MobileValidation } from '../../helpers/validation';
import NotificationList from '../notificationSettings/NotificationList';
import { getDigitalVerificationStatus } from '../openFlow/helpers';
import { COLORS } from '../../constants/colorPallete';
import BackGroundGenerator from '../LandingPages/common/BackGroundGenerator';
import { BoxShadow, TabContainer, Wrapper } from '../idVerification/styles';
import { LinearGradient } from 'expo-linear-gradient';
import { openFlow } from '../../constants/openFlo';
import CustomPopUp from '../../commonComponents/customPopUp/customPopUp';
import { navigateAction, replaceAction } from '../../helpers/navigationHelpers';
import { Typography } from '../openFlow/style';
import { validateEmail } from '../../helpers/regex';
import ShareMedia from '../../commonComponents/shareMedia/shareMedia';
import { ShareOptions } from '../propertyDetails/styles';
import ODSButton from '../../../openbrix-design-system/ui/atoms/ODSButton/index.native';
import { getImage } from '../../../openbrix-design-system/utils/getScreenType';
import { ODSText } from '../../../openbrix-design-system/ui/atoms/ODSText';
import { useTheme } from '@emotion/react';
import MyAccountPanel from '../../../openbrix-design-system/ui/molecules/Account/MyAccountPanel/MyAccountPanel';
import MyAccountCard from '../../../openbrix-design-system/ui/molecules/Account/MyAccount/MyAccount';
import { useRoute } from '@react-navigation/native';
import MyInvoiceCard from '../../../openbrix-design-system/ui/molecules/Account/MyInvoices/MyInvoices';
import ReferAFriendCard from '../../../openbrix-design-system/ui/molecules/Account/ReferAFriend/ReferAFriendCard';
import AlertCard from '../../../openbrix-design-system/ui/molecules/Account/AlertCard/AlertCard';
import SavedPropertiesCard from '../../../openbrix-design-system/ui/molecules/Account/SavedPropertiesCard/SavedPropertiesCard';
import ContactUsCard from '../../../openbrix-design-system/ui/molecules/Account/ContactUsCard/ContactUsCard';

const layoutType = getPlatformType();

const ChildScreen = (props) => {
  const dispatch = useDispatch();
  const [deletePopUpIsVisible, setDeletePopUpIsVisible] = useState(false);
  const [showShare,setShowShare]=useState(false);
  const {params} =useRoute();
  const {id,data,idStatus}=params;

  const {colors}=useTheme();

  const onProfileUpdate=(item)=>{
    dispatch(
        updateProfile({
          user: {
            first_name: item.firstName,
            last_name: item.lastName,
            contact_number: item.contact,
            user_id:data.user_id,
            user_type: 0,
            title: item.title,
          },
        })
      );
  }
  const onReferAFriend=(email)=>{
    dispatch(referFriend({email:email },()=>{
       
      }))
  }
  const onContactUs=(data)=>{
    dispatch(
        contactUs({
            email: data.email,
            topic: data.subject,
            name: data.firstName,
            message: data.message,
        })
      );
  }
  const onCopyPress=(e)=>{
    navigator.clipboard.writeText(e);
    dispatch({ type: types.SHOW_ALERT, alertType: "success", text: "ID Copied" });
}

  return (
    <BackGroundGenerator props={props} isTLYF>
      {/* <Loader loading={profileLoading} /> */}
      <ImageBackground
        source={require('../../../openbrix-design-system/images/banner/web/my-account.png')}
        style={{
          width: '100%',
          height: '246px',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
        resizeMode="cover"
      >
        <ODSButton
          rIcon={'TB-Arrow-Left'}
          restStyle={{ marginTop: 20, marginLeft:layoutType=="web"?60:20 }}
          type="secondary"
          disabled={false}
          onPress={() => replaceAction(props?.navigation, 'My Account')}
        >
         My Account
        </ODSButton>
      </ImageBackground>
     <View style={{width:layoutType=="phone"?'100%':700,top:-60,alignSelf:'center'}}>
    {id=='profile' && <MyAccountCard onCopyPress={onCopyPress} onPress={(e)=>onProfileUpdate(e)} data={data} idStatus={idStatus}/>}
    {id=='invoice' && <MyInvoiceCard>
                        <NotificationList />
                    </MyInvoiceCard>}
    {id=="saved" && <SavedPropertiesCard />}
    {id=="alerts" && <AlertCard />}
    {id=="refer" && <ReferAFriendCard shareComponent={()=><ShareMedia
                              // shareUrl={`Refer a friend https://play.google.com/store/apps/details?id=com.tlyfe&hl=en&gl=US`}
                              shareUrl={`Refer a Friend\nCheck it out!\n\nhttps://play.google.com/store/apps/details?id=com.tlyfe&hl=en&gl=US`}

                              emailSubject={
                                "Refer a friend"
                              }
                              emailBody={`Refer a friend https://play.google.com/store/apps/details?id=com.tlyfe&hl=en&gl=US`}
                            />} onSend={(email)=>onReferAFriend(email)}/>}
    {id=="contact-us" && <ContactUsCard data={data} onSend={(data)=>onContactUs(data)}/>}
     </View>




    </BackGroundGenerator>
  );
};
export default ChildScreen;
