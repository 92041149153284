import { BackendUrl, REACT_APP_API } from "@env"

const Constant = {
    API_URL: BackendUrl || "https://api.openbrix.co.uk/v2.0",
    SERVER_URL: BackendUrl || "https://api.openbrix.co.uk/v2.0",
    IMAGE_URL:  "https://assets.openbrix.co.uk/",
    OPENFLO_URL:"https://vimeo.com/747991960",
    TLYFE_URL:'https://vimeo.com/747992259',
    TDS_HELP_URL:"https://www.tenancydepositscheme.com/learn-more/help-centre",
    INFO_HUB:'https://openbrix.co.uk/landing/InfoHub/'

};

export default Constant;
