import moment from 'moment';
import { Text, View, Image, TouchableOpacity } from 'react-native';
import React, { useState, useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { tdsActions } from './tdsRedux/tds.actions';
import { backgroundTdsArr } from './backgroundTdsArr';
import { navigateAction, replaceAction } from '../../helpers/navigationHelpers';
import BackGroundGenerator from '../LandingPages/common/BackGroundGenerator';
import CustomTextInput from '../../commonComponents/customTextInput/customTextInput';
import DatePickerComponent from '../../commonComponents/datepicker/DatePickerComponent';
import {
  Container,
  ButtonWrapper,
  RowContainer,
  Section,
  FormText,
  Heading,
  SubHeading,
  ActivateButtonWrapper,
  primaryColor,
  Typography,
  BoxShadow,
  DownloadButtonWrapper,
} from './style';
import TdsLogin from './login';
import { MainSection } from '../openFlow/style';
import getPlatformType from '../../helpers/getPlatform';
import { tdsLocationSelected } from '../../redux/actions/app';
import { useIsFocused } from '@react-navigation/native';
import { COLORS } from '../../constants/colorPallete';
const layoutType = getPlatformType();
const searchDeposit = (props) => {
  const { navigation } = props;
  const dispatch = useDispatch();
  const [type, setType] = useState('web');
  const [showError, setShowError] = useState(false);
  const [state, setState] = useState({ deposit_start_date: null });
  const [searchDeposits, setSearchDeposit] = useState();

  const searchDepositResponse = useSelector((state) => state.tds?.searchedDeposit);
  const focus = useIsFocused();
  useEffect(() => {
    setSearchDeposit();
  }, [focus]);

  const handleChange = (value, field) => {
    setState({ ...state, [field]: value });
  };

  const searchDeposit = () => {
    setShowError(false);
    if (state?.deposit_start_date == null) {
    }
    dispatch(tdsActions.searchDeposit(state));
  };

  useEffect(() => {

    if (searchDepositResponse) {
      setSearchDeposit(searchDepositResponse);
    } else if (searchDepositResponse?.status == false && searchDepositResponse?.message) {
      setSearchDeposit({ deposit_protected: false });
    }
  }, [searchDepositResponse]);

  const renderInfoBox = () => {
    const type = searchDeposits?.deposit_protected;
    const text = searchDeposits?.deposit_protected
      ? searchDeposits.scheme == 'custodial'
        ? 'Your Deposit is Protected with TDS Custodial '
        : 'Your Deposit is protected with TDS insured.'
      : 'Deposit not found';
    return (
      <>
        <View
          style={{
            width: '100%',
            backgroundColor: type ? '#C4F5D8' : '#F5C4C4',
            justifyContent: 'center',
            alignItems: 'center',
            paddingVertical: 50,
            marginVertical: 20,
            borderRadius: 10,
            paddingHorizontal: 10,
          }}
          id={'TDS-INFO-BOX'}
        >
          <Text
            style={{
              color: type ? '#4C664B' : '#5E0D0D',
              fontSize: 23,
              fontWeight: '600',
              textAlign: 'center',
            }}
          >
            {' '}
            {text ? text : ''}
          </Text>

          {searchDeposits?.certificate ? (
            <DownloadButtonWrapper
              style={{
                width: layoutType == 'phone' ? '100%' : 250,
                backgroundColor: 'white',
                borderColor: '#4D8761',
                borderWidth: 1,
              }}
              id={'TDS-CERTIFICATE-DOWNLOAD'}
              onPress={() => window.open(searchDeposits?.certificate, '_blank')}
            >
              <Typography color={'#4C664B'} underline size={'16px'} bold={800}>
                DOWNLOAD CERTIFICATE
              </Typography>
            </DownloadButtonWrapper>
          ) : null}
        </View>
        {searchDeposits?.deposit_protected ? (
          searchDeposits.scheme == 'custodial' ? (
            <View style={{alignItems:'center'}}>
              <Text style={{marginTop:10,textAlign:'center',backgroundColor:'454545',fontSize:18 }}>Your deposit is protected with the TDS. </Text>
              <Text style={{marginTop:8,textAlign:'center',backgroundColor:'454545',fontSize:18 }}>Please log in with your email to manage your deposit.</Text>
            </View>
          ) : (
            <View style={{alignItems:'center'}}>
              <Text style={{marginTop:10,textAlign:'center',backgroundColor:'454545',fontSize:18 }}>Your deposit is protected within the TDS insured scheme. </Text>
              </View>
          )
        ) : (
          <View style={{alignItems:'center'}}>
            <Text style={{marginTop:10,textAlign:'center',backgroundColor:'454545',fontSize:18 }}>Please check the information provided is correct.</Text>
            <Text style={{marginTop:8,textAlign:'center',backgroundColor:'454545',fontSize:18 }}>
              Alternatively please contact your Agent or Landlord directly to check your deposit is
              protected with TDS.
            </Text>
            </View>
        )}
      </>
    );
  };
  function checkValue(value) {
    handleChange(handleDecimalsOnValue(value), 'deposit_amount');
  }

  function handleDecimalsOnValue(value) {
    const regex = /([0-9]*[\.|\,]{0,1}[0-9]{0,2})/s;
    return value.match(regex)[0];
  }
  const renderSearchDeposit = () => {
    return (
      <Fragment style={{ alignItems: 'center' }}>
        <Typography size={'24px'} id={'TDS-LOGIN'} color={primaryColor}>
          {' '}
          Search
        </Typography>
        <Typography
          bold={'700'}
          bottom={'30px'}
          top={'20px'}
          color={'black'}
          size={'22px'}
          fontWeight={'300'}
        >
          Check if your deposit is protected by entering your details below.
        </Typography>
        <CustomTextInput
          id="dan"
          width={'100%'}
          title="Certificate Code/DAN Number"
          type="text"
          name="dan"
          prefilledValue={state.dan}
          bColor={'#638ACC'}
          onChangeText={(value) => handleChange(value, 'dan')}
        />

        <Image
          style={{ height: 30, width: '100%', marginVertical: 20 }}
          source={require('./web/orSaperator.svg')}
        />
        <CustomTextInput
          id="depositAmount"
          width={'100%'}
          title="Deposit Amount"
          type="number"
          name="deposit_amount"
          keyboardType={"decimal-pad"}
          prefilledValue={state?.deposit_amount}
          bColor={'#638ACC'}
          onChangeText={(value) => checkValue(value)}
        />
        <CustomTextInput
          id="Postcode"
          width={'100%'}
          title="Postcode"
          type="text"
          name="deposit_postcode"
          prefilledValue={state?.deposit_postcode}
          bColor={'#638ACC'}
          onChangeText={(value) => handleChange(value, 'deposit_postcode')}
        />
        <CustomTextInput
          id="Tenant_Surname"
          width={'100%'}
          title="Tenant Surname"
          type="text"
          name="tenant_surname"
          prefilledValue={state?.tenant_surname}
          bColor={'#638ACC'}
          onChangeText={(value) => handleChange(value, 'tenant_surname')}
        />
        <DatePickerComponent
          key="datepicker"
          placeholder={'Tenancy Start Date'}
          inputLabel={{ fontSize: 16, color: '#000', fontWeight: 'bold' }}
          value={state?.deposit_start_date}
          onUpdateInput={(date) => {
            handleChange(moment(date).format('DD/MM/YYYY'), 'deposit_start_date');
          }}
        />

        <ButtonWrapper 
          style={{width:layoutType=="phone"?"100%":"70%",marginTop: 40}}
        id={'TDS-SEARCH'} onPress={() => searchDeposit()}>
          <Text style={{ color: 'white' }}>SEARCH</Text>
        </ButtonWrapper>

        <View
          style={{
            height: 1,
            width: '100%',
            backgroundColor: primaryColor,
            marginTop: 20,
            marginBottom: 20,
          }}
        />
        {searchDeposits ? renderInfoBox() : null}
      </Fragment>
    );
  };
  const renderHeaderContent = () => {
    return (
      <MainSection
        style={{
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignSelf: 'center',
          marginHorizontal: 16,
          width: layoutType == 'phone' ? '80%' : 652,
        }}
      >
        <View
          style={{
            width: layoutType == 'phone' ? '80%' : 428,
            alignSelf: 'center',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography bold={800} size={'36px'} id={'TDS-LOGIN'} color={'white'}>
            View & Manage your tenancy deposit
          </Typography>
          <Typography bold={'600'} size={'24px'} top={'18px'} id={'TDS-LOGIN'} color={'white'}>
            View the details of your existing deposit protected with the government approved Tenancy
            Deposit Scheme.
          </Typography>
     
          <ButtonWrapper
            color={'white'}
            bcolor={COLORS.tds}
            style={{ marginTop: 20, width: 250, height: 49 }}
            onPress={() => {
              navigateAction(navigation, 'tdsLocation');
              dispatch(tdsLocationSelected(null));
            }}
          >
            <Text style={{ color: COLORS.tds }}> Switch to Scotland</Text>
          </ButtonWrapper>
          <Typography
            style={{ width: layoutType == 'phone' ? '80%' : 428, alignSelf: 'center' }}
            bold={800}
            align={'center'}
            top={'10px'}
            size={'18px'}
            id={'TDS-LOGIN'}
            color={'white'}
          >
            Please click here if your property is located in Scotland and your deposit is protected
            by SafeDeposits Scotland.
          </Typography>
        </View>
      </MainSection>
    );
  };
  return (
    <>
      <BackGroundGenerator
        replace={'Dashboard'}
        isBack={true}
        backNavigation={() => replaceAction(navigation, 'Dashboard')}
        isTds
        props={props}
        layoutType={(data) => {
          setType(data);
        }}
        isTLYF
        paddingtop={layoutType == 'phone' ? 150 : 100}
        backgroundArr={backgroundTdsArr}
      >
        {renderHeaderContent()}
        <MainSection
          style={
            layoutType == 'phone'
              ? {
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  marginTop: 10,
                  alignSelf: 'center',
                  marginHorizontal: 16,
                }
              : {
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  marginTop: 10,
                  width: '70%',
                  alignSelf: 'center',
                }
          }
        >
          <View style={layoutType == 'phone' ? { width: '100%' } : { flex: 0.48 }}>
            <BoxShadow style={{ padding: 16 }}>{renderSearchDeposit()}</BoxShadow>
          </View>
          <View style={layoutType == 'phone' ? { flex: 1, marginTop: 26 } : { flex: 0.48 }}>
            <BoxShadow style={{ padding: 16 }}>
              <TdsLogin navigation={navigation} />
            </BoxShadow>
          </View>
        </MainSection>
      </BackGroundGenerator>
    </>
  );
};

export default searchDeposit;
