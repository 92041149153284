import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Dimensions,
  FlatList,
  Image,
  ImageBackground,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import AutoHeightImage from '../common/AutoImage';
import stylesMobile from './stylesMobile';
import stylesTab from './stylesTab';
import stylesWeb from './stylesWeb';
import { backgroundSearchArr } from '../images/tlyfe/searchArrayTlyfe';
import BackGroundGenerator from '../common/BackGroundGenerator';
import { navigateAction, trackAnalytics } from '../../../helpers/navigationHelpers';
import { useDispatch, useSelector } from 'react-redux';
import { Typography } from '../../openFlow/style';
import WideButton from '../../../commonComponents/wideButton';
import { COLORS } from '../../../constants/colorPallete';
import Form from '../../rentalLandingScreen/form';
import getPlatformType from '../../../helpers/getPlatform';
import CommonAuth from './CommonAuth';
import ImageView from './imageView';
import { saveSearchObject } from '../../../redux/actions/app';
import LandingContact from '../landingContact/landingContact';
import ODSButton from '../../../../openbrix-design-system/ui/atoms/ODSButton/index.native';
import { useTheme } from '@emotion/react';

const tiles = [
  {
    image: require('./images/about/rc.png'),
    subheading:
      'We believe everyone has value to add and their effort and hard work should be recognised and fairly rewarded. ',
    heading: 'RECOGNISED CONTRIBUTION',
  },
  {
    image: require('./images/about/raf.png'),
    subheading:
      'We believe everyone deserves to be respected as an individual and judged on their own merits - free from discrimination. A fair and level playing field.',
    heading: 'RESPECT AND FAIRNESS',
  },
  {
    image: require('./images/about/rc2.png'),
    subheading: "We believe in the power of questions and not being afraid to say 'I dont know'.",
    heading: 'RESTLESS CURIOSITY',
  },
  {
    image: require('./images/about/rac.png'),
    subheading:
      'We believe we should never be unwilling to change – everything constantly evolves and so should we.',
    heading: '(R)EVOLUTION AND CHANGE',
  },
  {
    image: require('./images/about/tfh.png'),
    subheading:
      'We believe that everyone has a contribution to make and everyone is better off when we all work together.',
    heading: 'TOGETHER FOR HARMONY',
  },
  {
    image: require('./images/about/ia.png'),
    subheading:
      'We believe talking should be backed up with doing people should do what they promised.',
    heading: 'INTEGRITY & ACCOUNTABILITY',
  },
];
const tiles2 = [
  {
    name: 'The right to a happy home',
    title:
      'We believe everyone has the right to a happy home. To spend time with family, friends and loved ones undisturbed.',
    image: require('./images/about/trta.png'),
  },
  {
    name: 'The right to privacy',
    title:
      'We believe everyone has the right to privacy. To live free from intrusion and to have control of their data and information.',
    image: require('./images/about/trtp.png'),
  },
  {
    name: 'The right to recognition as an individual, free from discrimination',
    title:
      'We believe everyone has the right to be treated as an individual, recognised for who they are and free from discrimination because of a category they fall into.',
    image: require('./images/about/trt.png'),
  },
];
const LandingAbout = (props) => {
  const { colors } = useTheme();
  const [type, setType] = useState('web');
  const layoutType = getPlatformType();
  const dispatch = useDispatch();
  const isAnylyticsEnable = useSelector(
    (state) => state?.cookiesReducer?.customiseCookies.Analytics
  );
  const { navigate } = props.navigation;
  const navigateTo = (params, from) => {
    if (from) {
      navigateAction(props?.navigation, params, from);
    } else {
      navigateAction(props?.navigation, params);
    }
  };
  const scrollViewRef = useRef();

  const [property_for, setPropertyFor] = useState(1);
  const styles = type == 'web' ? stylesWeb : type == 'mobile' ? stylesMobile : stylesTab;
  const [state, setState] = useState({
    from: '',
  });

  const _onSearch = (value) => {
    isAnylyticsEnable ? trackAnalytics('Button', 'OnClick', 'Property-Search') : null;
    dispatch(saveSearchObject(value));
    props.navigation.navigate('Search');
  };

  useEffect(() => {
    if (props?.route?.params?.from) {
      setState({
        ...state,
        from: props?.route?.params?.from ? props?.route?.params?.from : '',
      });
    }
  }, [props?.route?.params?.from]);
  console.log('type', type);
  const handleScrollButtonClick = useCallback(() => {
    const height = Dimensions.get('window').height;
    if (scrollViewRef.current) {
      scrollViewRef.current.scrollTo({
        y: layoutType == 'web' ? height : height / 2,
        animated: true,
      });
    }
  }, []);
  const token = useSelector((state) => state?.appReducer?.loginData?.data?.token);
  let BottomWrapper = type == 'web' ? ImageBackground : View;
  return (
    <>
      <BackGroundGenerator
        props={props}
        isTLYF={false}
        layoutType={(data) => setType(data)}
        backgroundArr={backgroundSearchArr}
        isLandingOpenbrix
        isLanding
        scrollViewRef={scrollViewRef}
      >
        <CommonAuth
          heading={'MISSION:'}
          onScrollButtonClick={handleScrollButtonClick}
          navigation={navigate}
          type={type}
          showIntro={true}
          logoName={' '}
          showRegistrationForm={true}
          subheading={'TO REVOLUTIONISE, TRUST AND BRING HARMONY TO THE WORLD OF PROPERTY'}
          hideButton={false}
          customSubHeadingStyle={
            type == 'web'
              ? {
                  fontSize: 45,
                  width: 559,
                  lineHeight: 48,
                }
              : null
          }
          {...props}
        />

        <View style={[styles.textContainer, { backgroundColor: '#FAFAFB' }]}>
          <View
            style={[
              styles.shawow,
              {
                width: '100%',
                flexDirection: 'column',
                paddingHorizontal: '6%',
                borderRadius: 23,
                borderBottomWidth: 3,
                borderColor: COLORS.LIGHTPINK1,
                alignItems: 'center',
              },
            ]}
          >
            <Typography
              line={'35px'}
              raj
              bold={'900'}
              top={'48px'}
              color={'#1C1C1C'}
              size={'26px'}
              align={'center'}
            >
              THE OPENBRIX VISION
            </Typography>
            <Typography
              line={'35px'}
              raj
              bold={'500'}
              top={'10px'}
              color={'#737373'}
              size={'26px'}
              align={'center'}
              bottom={'40px'}
            >
              Our vision is to create a world where, through the use of technology, tenants, agents
              and landlords can nurture headache free, harmonious, prosperous relationships
            </Typography>
          </View>
        </View>

        <View style={[styles.imageContainer, { backgroundColor: 'white' }]}>
          <Typography
            raj
            bold={'700'}
            color={'#1C1C1C'}
            size={type !== 'web' ? '30px' : '48px'}
            line={type !== 'web' ? '35px' : '52px'}
            align={'center'}
            bottom={'4%'}
          >
            VALUES AND BELIEFS
          </Typography>
          <View
            style={{
              flexDirection: 'row',
              flexWrap: 'wrap',
              justifyContent: 'center',
              alignSelf: 'center',
              maxWidth: 1400,
            }}
          >
            {tiles.map((item, index) => {
              return (
                <View
                  key={index}
                  style={[
                    styles.shawow,
                    {
                      width: 315,
                      padding: 20,
                      paddingVertical: 40,
                      justifyContent: 'center',
                      alignItems: 'center',
                      borderRadius: 20,
                      margin: 20,
                    },
                  ]}
                >
                  <Image
                    source={item.image}
                    style={{
                      width: 146,
                      height: 146,
                    }}
                    resizeMode={'contain'}
                  />
                  <Typography
                    line={'35px'}
                    raj
                    bold={'600'}
                    top={'10px'}
                    color={'#1C1C1C'}
                    size={'26px'}
                    align={'center'}
                  >
                    {item.heading}
                  </Typography>
                  <Typography
                    line={'26px'}
                    raj
                    bold={'500'}
                    top={'6px'}
                    color={'#737373'}
                    size={'20px'}
                    align={'center'}
                  >
                    {item.subheading}
                  </Typography>
                </View>
              );
            })}
          </View>
        </View>
        <View style={[styles.imageContainer, { backgroundColor: '#FAFAFB' }]}>
          <Typography
            raj
            bold={'700'}
            color={'#1C1C1C'}
            size={type !== 'web' ? '30px' : '48px'}
            line={type !== 'web' ? '35px' : '52px'}
            align={'center'}
            bottom={'4%'}
          >
            CHARTER OF RENTERS RIGHTS – THE OPENBRIX MANIFESTO
          </Typography>
          <View
            style={{
              flexDirection: 'row',
              flexWrap: 'wrap',
              justifyContent: 'center',
            }}
          >
            {tiles2.map((item, index) => {
              return (
                <View
                  key={index}
                  style={[
                    {
                      width: 274,

                      paddingVertical: 40,
                      justifyContent: 'flex-start',

                      borderRadius: 20,
                      margin: 20,
                    },
                  ]}
                >
                  <View
                    style={{
                      height: 146,
                      alignItems: 'center',
                    }}
                  >
                    <Image
                      source={item.image}
                      style={{
                        width: 146,
                        height: 146,
                      }}
                      resizeMode={'contain'}
                    />
                  </View>
                  <Typography
                    line={'30px'}
                    raj
                    bold={'600'}
                    top={'20px'}
                    color={'#1C1C1C'}
                    size={'26px'}
                    align={'center'}
                  >
                    {item.name}
                  </Typography>
                  <Typography
                    line={'26px'}
                    raj
                    bold={'500'}
                    top={'6px'}
                    color={'#737373'}
                    size={'20px'}
                    align={'center'}
                  >
                    {item.title}
                  </Typography>
                </View>
              );
            })}
          </View>
        </View>
        <BottomWrapper source={require('./bottom.png')} style={styles.searchSection}>
          <View
            style={{
              flex: 0.4,
              marginLeft: type !== 'web' ? 0 : '10%',
            }}
          >
            <Typography
              raj
              bold={'700'}
              color={'white'}
              size={type !== 'web' ? '40px' : '60px'}
              line={type !== 'web' ? '48px' : '62px'}
              align={type == 'web' ? 'flex-start' : 'center'}
              top={type == 'web' ? '100px' : '0px'}
            >
              LET’S HAVE A CHAT!
            </Typography>
            <Typography
              raj
              color={'white'}
              bold={'700'}
              size={type !== 'web' ? '28px' : '40px'}
              line={type !== 'web' ? '29px' : '45px'}
              top={'20px'}
              align={type == 'web' ? 'flex-start' : 'center'}
            >
              BOOK A DEMO, BECOME A PARTNER OR GET INSPIRED TODAY!
            </Typography>
          </View>
        
        </BottomWrapper>
      </BackGroundGenerator>
    </>
  );
};
export default LandingAbout;
