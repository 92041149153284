import moment from 'moment';
import { View, TextInput } from 'react-native';
import { types } from "../../redux/actionTypes";
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { tdsActions } from './tdsRedux/tds.actions';
import { backgroundTdsArr } from './backgroundTdsArr';
import { navigateAction } from '../../helpers/navigationHelpers';
import { generalColorsTheme } from './../../constants/colorPallete';
import BackGroundGenerator from '../LandingPages/common/BackGroundGenerator';
import DatePickerComponent from '../../commonComponents/datepicker/DatePickerComponent';
import { TabHeader, Container, BoxShadow, TabsWrapper, Typography, TabBody, LineSaperator, AmountBox, CurrencyBox, InputBox, BlueBox, ButtonWrapper, RowContainer } from './style';

const custodialRepayment = (props) => {

  const dispatch = useDispatch();
  const [type, setType] = useState('web');
  const [tenancy, setTenancy] = useState({});
  const [status, setStatus] = useState('initiate');
  const [activeTab, setActiveTab] = useState('Tenants');
  const [agreedStatus, setAgreedStatus] = useState('dont_agree');
  const [state, setState] = useState({ deposit_end_date: null });
  const repaymentResponse = useSelector((state) => state.tds?.repaymentProposal);
  const createRepaymentSuccess = useSelector((state) => state.tds?.createRepaymentSuccess);

  useEffect(() => {
    if(props.route.params.case_id == 0) {
      setStatus('initiate');
    }
    dispatch(tdsActions.getDepositRepaymentProposal(props.route.params.case_id, props?.route?.params?.tenancy_id, props?.route?.params?.dan));
  }, []);

  useEffect(() => { 
    if (createRepaymentSuccess) {
      navigateAction(props.navigation, 'Custodial Dashboard', { 'tenancy_id': props?.route?.params?.tenancy_id, 'dan': props?.route?.params?.dan })
    }
  }, [createRepaymentSuccess]); 

  useEffect(() => {
    if(repaymentResponse) {
      setTenancy(repaymentResponse.tenancy);
      const tenants = repaymentResponse.tenancy.tenants.map(tenant=>{
        return { ...tenant, repaymentAmount: 0, is_lead: tenant.tenant_classification == 'Lead Tenant'}
      })
      setState({ ...state, ...{tenants, amountToLandlord: 0 }});
    }
  }, [repaymentResponse]);

  const makeRepaymentRequest = () => {

    let tenants = state.tenants;
    if( activeTab == 'Tenants') {
      tenants = tenants.filter(tenant=> tenant.is_lead)
    }

    const payload = {
      tenancy_id: props?.route?.params?.tenancy_id,
      amount_to_tenants: getTotalTenantAmount(),
      tenancy_end_date_tenant: moment(state.deposit_end_date).format('YYYY-MM-DD'),
      amount_to_agent_or_landlord: state.amountToLandlord ? parseFloat(state.amountToLandlord): state.amountToLandlord,
      repayments: tenants.map((tenant)=> { return { id: tenant.line_item_id, repaymentAmount: parseFloat(tenant.repaymentAmount) } }),
    }
    dispatch(tdsActions.createDepositRepaymentProposal(payload));
  };

  const requestRepayment = () => {
    if(state.deposit_end_date){
      let reminder = 0
      if(activeTab == 'Tenant') {
        reminder = getReminderToAllocate1()
      } else {
        reminder = getReminderToAllocate()
      }
      let isDateInFuture = new Date() < state.deposit_end_date 
      if(reminder == 0 && !isDateInFuture) {
        setStatus('review');
      } else {
        let message = [];
        if(reminder > 0) {
          message.push('Full deposit amount must be allocated');
        } else if (reminder < 0) {
          message.push('You cannot repay more than the deposit amount');
        }
        if(isDateInFuture) {
          message.push('End date can not be in future');
        }
        dispatch({ type: types.SHOW_ALERT, alertType: "error", text: message.join(",") });
      }
    }else{
      dispatch({ type: types.SHOW_ALERT, alertType: "error", text:"Please select the date on which the tenancy ended" });
    }
 
  };

  const handleAmountToRepayChange = (tenant, value) => {
    const newTenants = state.tenants.map(obj => {
      if (obj.line_item_id === tenant.line_item_id) {
        return {...obj, repaymentAmount: value};
      }
      return obj;
    });
    setState({ ...state, tenants: newTenants })
  }

  const getTotalTenantAmount = () => {
    let totalAmount = 0;
    state.tenants?.map(tenant=>{
      if(tenant.repaymentAmount) {
        totalAmount += parseFloat(tenant.repaymentAmount)
      }
    });
    return totalAmount;
  }

  const getReminderToAllocate1 = () => {
    let totalAmount = parseFloat(state.amountToLandlord);
    state.tenants?.map(tenant=>{
      if(tenant.repaymentAmount && tenant.is_lead) {
        totalAmount += parseFloat(tenant.repaymentAmount)
      }
    })
    return parseFloat(tenancy?.tenancy?.deposit_amount) - totalAmount;
  };

  const getReminderToAllocate = () =>{
    let totalAmount = parseFloat(state.amountToLandlord);
    state.tenants?.map(tenant=>{
      if(tenant.repaymentAmount) {
        totalAmount += parseFloat(tenant.repaymentAmount)
      }
    })
    return parseFloat(tenancy?.tenancy?.deposit_amount) - totalAmount;
  };

  const renderDepositSection = () => {
    return (
      <BoxShadow>
        <Typography 
        id={"TOTAL_DEPOSIT_HELD"}
        color={'#194592'} size={'23px'} bold={700}>
          Total Deposit Held
        </Typography>
        <Typography bottom={'31px'} top={'27px'} color={'#194592'} size={'38px'} bold={800}>
          £ {tenancy?.tenancy?.deposit_amount}
        </Typography>
        <LineSaperator />
        <Typography top={'34px'} color={'#194592'} size={'23px'} bold={700}>
          Tenancy end date
        </Typography>

        {status == 'initiate' ? (
          <>
            <Typography top={'19px'} color={'#194592'} size={'20px'} bold={500}>
              Please select the date on which
            </Typography>
            <Typography bottom={'27px'} color={'#194592'} size={'20px'} bold={500}>
              the tenancy ended
            </Typography>
          </>
        ) : (
          <Typography top={'19px'} bottom={'27px'} color={'#194592'} size={'20px'} bold={500}>
            Supplied by tenant
          </Typography>
        )}
        <View style={{ width: "100%", alignSelf: 'center' }}>
          <DatePickerComponent
            key="datepicker"
            id={"TDS-TENANCY-ENDDATE"}
            placeholder={'Please select date'}
            inputLabel={{ fontSize: 16, color: '#000', fontWeight: 'bold' }}
            value={state?.deposit_end_date}
            disabled={status == 'review' }
            onUpdateInput={(date) => {
              setState({...state, deposit_end_date: date });
            }}
          />
        </View>
      </BoxShadow>
    );
  };

  const renderRepaymentSection = () => {
    return (
      <BoxShadow top={'24px'}>
        <Typography color={'#194592'} size={'23px'} bold={700}>
          Repayment options
        </Typography>
        <Typography top={'31px'} color={'#454545'} size={'20px'} bold={400}>
          Please request repayment of the deposit below. You can either request
        </Typography>
        <Typography color={'#454545'} size={'20px'} bold={400}>
          repayment of the deposit in full to the lead tenant, or split the deposit
        </Typography>
        <Typography color={'#454545'} size={'20px'} bold={400}>
          between the tenant(s) and landlord using the 'custom' tab.
        </Typography>
      </BoxShadow>
    );
  };

  const renderReSection = () => {
    return (
      <View style={{ width: '100%', padding: 43 }}>

        {state.tenants?.map((otherTenant,index) => {
          return (
            <View
            key={index}
            style={{ flexDirection: 'row', marginTop: 50 }}>
            <View style={{ flexDirection: 'column', flex: 0.7 }}>
              <Typography align={'left'} color={'#194592'} size={'23px'} bold={700}>
                Amount of deposit to be repaid to tenant
              </Typography>
              <Typography top={'16px'} align={'left'} color={'#000000'} size={'20px'} bold={500}>
                {otherTenant?.fullname}
              </Typography>
            </View>
            <View
              style={{
                flexDirection: 'column',
                flex: 0.3,
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <AmountBox>
                <CurrencyBox>
                  <Typography align={'left'} color={'#4C664B'} size={'20px'} bold={500}>
                    £
                  </Typography>
                </CurrencyBox>
                <InputBox>
                  <TextInput
                    style={{
                      margin: 8,
                      padding: 6,
                      width: '96%',
                      height: '100%',
                      borderWidth: 0,
                    }}
                    placeholder=""
                    keyboardType="numeric"
                    id={"TDS-REPAYMENT-AMOUNT"+index}
                    value={otherTenant.repaymentAmount}
                    onChangeText={(value) => {
                      handleAmountToRepayChange(otherTenant, value)
                    }}
                  />
                </InputBox>
              </AmountBox>
            </View>
          </View>  
          )
        })}

        <View style={{ flexDirection: 'row', marginTop: 50 }}>
          <View style={{ flexDirection: 'column', flex: 0.7 }}>
            <Typography align={'left'} color={'#194592'} size={'23px'} bold={700}>
              Amount of deposit to be repaid to landlord
            </Typography>
            <Typography top={'16px'} align={'left'} color={'#000000'} size={'20px'} bold={500}>
              {tenancy?.property?.membername}
            </Typography>
          </View>
          <View
            style={{
              flexDirection: 'column',
              flex: 0.3,
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <AmountBox>
              <CurrencyBox>
                <Typography align={'left'} color={'#4C664B'} size={'20px'} bold={500}>
                  £
                </Typography>
              </CurrencyBox>
              <InputBox>
                <TextInput
                  style={{
                    margin: 8,
                    padding: 6,
                    width: '96%',
                    height: '100%',
                    borderWidth: 0,
                    color:state.amountToLandlord< 0? "red":'black'
                  }}
                  placeholder=""
                  keyboardType="numeric"
                  value={state.amountToLandlord}
                  id={"TDS-REPAYMENT-LANDLORDAMOUNT"}
                  onChangeText={(value) => {
                    setState({...state, amountToLandlord: value });
                  }}
                />
              </InputBox>
            </AmountBox>
          </View>
        </View>

        <BlueBox>
        <View style={{flex:0.65}}>
          <Typography align={'left'} color={'#FFFFFF'} size={'24px'} bold={500}>
            Remainder to allocate
          </Typography>
          </View>
          <View style={{flex:0.35}}>
          <AmountBox height={'42px'}>
            <CurrencyBox color="#FFFFFF">
              <Typography align={'left'} color={'#4C664B'} size={'20px'} bold={500}>
                £
              </Typography>
            </CurrencyBox>
            <InputBox>
              <TextInput
                style={{
                  margin: 8,
                  padding: 6,
                  width: '96%',
                  height: '100%',
                  borderWidth: 0,
                  color:  getReminderToAllocate() <0 ?"red":"black"
                }}
                placeholder=""
                keyboardType="numeric"
                id={"TDS-REPAYMENT-REMAINDERALLOCATE"}
                value={ getReminderToAllocate() }
                onChangeText={() => {}}
              />
            </InputBox>
          </AmountBox>
          </View>
        </BlueBox>
        <ButtonWrapper
          style={{ 'margin-left': '10px', width: '50%', marginTop: 77 }}
          onPress={() => {
            requestRepayment();
          }}
          id={"TDS-REQUEST-REPAYMENT"}
        >
          <Typography color={'#FFFFFF'} size={'19px'} bold={800}>
            CONTINUE
          </Typography>
        </ButtonWrapper>
      </View>
    );
  };

  const renderTeanantSection = () => {
    return (
      <View style={{ width: '100%', padding: 43 }}>
        {state.tenants?.map((otherTenant,index) => {
          return (
            <>
          { otherTenant?.is_lead
            ? 
              <View
              key={index}
              style={{ flexDirection: 'row', marginTop: 50 }}>
                <View style={{ flexDirection: 'column', flex: 0.7 }}>
                  <Typography align={'left'} color={'#194592'} size={'23px'} bold={700}>
                    Amount of deposit to be repaid to tenant
                  </Typography>
                  <Typography top={'16px'} align={'left'} color={'#000000'} size={'20px'} bold={500}>
                    {otherTenant?.fullname}
                  </Typography>
                </View>
                <View
                  style={{
                    flexDirection: 'column',
                    flex: 0.3,
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <AmountBox>
                    <CurrencyBox>
                      <Typography align={'left'} color={'#4C664B'} size={'20px'} bold={500}>
                        £
                      </Typography>
                    </CurrencyBox>
                    <InputBox>
                      <TextInput
                        style={{
                          margin: 8,
                          padding: 6,
                          width: '96%',
                          height: '100%',
                          borderWidth: 0,
                        }}
                        placeholder=""
                        id={"TDS-REPAYMENT-AMOUNT"+index} 
                        id={"TDS-REPAYMENT-AMOUNT"}
                        keyboardType="numeric"
                        value={otherTenant.repaymentAmount}
                        onChangeText={(value) => {
                          handleAmountToRepayChange(otherTenant, value)
                        }}
                      />
                    </InputBox>
                  </AmountBox>
                </View>
              </View>  
            :
            null
          }</>
          )
        })}

        <BlueBox>
          <View style={{flex:0.65}}>
          <Typography align={'left'} color={'#FFFFFF'} size={'24px'} bold={500}>
            Remainder to allocate
          </Typography>
          </View>
          <View style={{flex:0.35}}>
          <AmountBox height={'42px'}>
            <CurrencyBox color="#FFFFFF">
              <Typography align={'left'} color={'#4C664B'} size={'20px'} bold={500}>
                £
              </Typography>
            </CurrencyBox>
            <InputBox>
              <TextInput
                style={{
                  margin: 8,
                  padding: 6,
                  width: '96%',
                  height: '100%',
                  borderWidth: 0,
                  color:getReminderToAllocate1()< 0? "red":'black'
                }}
                id={"TDS-REPAYMENT-REMAINDERALLOCATE"} 
                placeholder=""
                keyboardType="numeric"
                value={ getReminderToAllocate1() }
                onChangeText={() => {}}
              />
            </InputBox>
          </AmountBox>
          </View>
        </BlueBox>
        <ButtonWrapper
          style={{ 'margin-left': '10px', width: '50%', marginTop: 77 }}
          onPress={() => {
            requestRepayment();
          }}
          id={"TDS-REQUEST-REPAYMENT"}
        >
          <Typography color={'#FFFFFF'} size={'19px'} bold={800}>
            CONTINUE
          </Typography>
        </ButtonWrapper>
      </View>
    );
  };

  const renderDetailsTabs = () => {
    return (
      <TabsWrapper>
        <View
          style={{
            flexDirection: 'row',
            width: '100%',
            height: 70,
            borderBottomWidth: 1,
            borderBottomColor: generalColorsTheme.active,
          }}
        >
          <TabHeader
            onPress={() => {
              setActiveTab('Tenants');
              let newTenants = state.tenants.map((tenant)=>{
                if(!tenant.is_lead) {
                  return { ...tenant, repaymentAmount: 0 }
                } else {
                  return tenant
                }
              })
              setState({...state, tenants: newTenants, amountToLandlord: 0})
            }}
            id={"TDS-TAB-LEADTEANANT"}
          >
            <Typography
              color={
                activeTab === 'Tenants' ? generalColorsTheme.active : generalColorsTheme.inactive
              }
              size={'23px'}
              bold={700}
            >
              Lead Tenant
            </Typography>
          </TabHeader>
          <TabHeader 
          id={"TDS-TAB-CREPAYMENT"} 
          hideBoderLeft onPress={() => setActiveTab('Repayment')}>
            <Typography
              color={
                activeTab === 'Repayment' ? generalColorsTheme.active : generalColorsTheme.inactive
              }
              size={'23px'}
              bold={700}
            >
              Custom Repayment
            </Typography>
          </TabHeader>
        </View>
        <TabBody>
          {activeTab === 'Tenants' ? renderTeanantSection() : null}
          {activeTab === 'Repayment' ? renderReSection() : null}
        </TabBody>
      </TabsWrapper>
    );
  };

  const renderPreviewSection = () => {
    return (
      <BoxShadow bottom={"40px"} top={'40px'}>
        <Typography bottom={'3px'} align={'left'} color={'#194592'} size={'23px'} bold={700}>
          Repayment to Tenants
        </Typography>
        <RowContainer>
          <View style={{ flex: 0.6 }}></View>
          <View style={{ flex: 0.4 }}>
            <Typography bottom={'33px'} align={'left'} color={'#000000'} size={'20px'} bold={700}>
              Tenant Request
            </Typography>
          </View>
        </RowContainer>

        {state.tenants?.map(tenant=>{
          return(
          <RowContainer>
          <View style={{ flexDirection: 'column', flex: 0.6, justifyContent: 'center' }}>
            <Typography align={'left'} color={'#000000'} size={'20px'} bold={500}>
              {tenant.fullname}
            </Typography>
          </View>
          <View style={{ flexDirection: 'column', flex: 0.4, justifyContent: 'center' }}>
            <AmountBox>
              <CurrencyBox>
                <Typography align={'left'} color={'#4C664B'} size={'20px'} bold={500}>
                  £
                </Typography>
              </CurrencyBox>
              <InputBox>
                <TextInput
                  style={{ margin: 8, padding: 6, width: '96%', height: '100%', borderWidth: 0 }}
                  placeholder=""
                  keyboardType="numeric"
                  value={tenant.repaymentAmount}
                />
              </InputBox>
            </AmountBox>
          </View>
        </RowContainer>)
        })}
        <RowContainer style={{ marginTop: 20 }}>
          <View style={{ flexDirection: 'column', flex: 0.6, justifyContent: 'center' }}>
            <Typography align={'left'} color={'#194592'} size={'20px'} bold={500}>
              Total
            </Typography>
          </View>
          <View style={{ flexDirection: 'column', flex: 0.4, justifyContent: 'center' }}>
            <AmountBox>
              <CurrencyBox>
                <Typography align={'left'} color={'#4C664B'} size={'20px'} bold={500}>
                  £
                </Typography>
              </CurrencyBox>
              <InputBox>
                <TextInput
                  style={{ margin: 8, padding: 6, width: '96%', height: '100%', borderWidth: 0 }}
                  placeholder=""
                  keyboardType="numeric"
                  value={getTotalTenantAmount()}
                />
              </InputBox>
            </AmountBox>
          </View>
        </RowContainer>
        <Typography
          top={'40px'}
          bottom={'3px'}
          align={'left'}
          color={'#194592'}
          size={'23px'}
          bold={700}
        >
          Repayment to Landlord
        </Typography>
        <RowContainer>
          <View style={{ flex: 0.6 }}></View>
          <View style={{ flex: 0.4 }}>
            <Typography bottom={'33px'} align={'left'} color={'#000000'} size={'20px'} bold={700}>
              Tenant Request
            </Typography>
          </View>
        </RowContainer>
        <RowContainer>
          <View style={{ flexDirection: 'column', flex: 0.6, justifyContent: 'center' }}>
            <Typography align={'left'} color={'#000000'} size={'20px'} bold={500}>
              {tenancy?.property?.membername}
            </Typography>
          </View>
          <View
            style={{
              flexDirection: 'column',
              flex: 0.4,
              justifyContent: 'center',
            }}
          >
            <AmountBox>
              <CurrencyBox>
                <Typography align={'left'} color={'#4C664B'} size={'20px'} bold={500}>
                  £
                </Typography>
              </CurrencyBox>
              <InputBox>
                <TextInput
                  style={{
                    margin: 8,
                    padding: 6,
                    width: '96%',
                    height: '100%',
                    borderWidth: 0,
                  }}
                  placeholder=""
                  keyboardType="numeric"
                  value={state.amountToLandlord}
                />
              </InputBox>
            </AmountBox>
          </View>
        </RowContainer>
        <RowContainer style={{ marginTop: 20 }}>
          <View style={{ flexDirection: 'column', flex: 0.6, justifyContent: 'center' }}>
            <Typography align={'left'} color={'#194592'} size={'20px'} bold={500}>
              Total
            </Typography>
          </View>
          <View
            style={{
              flexDirection: 'column',
              flex: 0.4,
              justifyContent: 'center',
            }}
          >
            <AmountBox>
              <CurrencyBox>
                <Typography align={'left'} color={'#4C664B'} size={'20px'} bold={500}>
                  £
                </Typography>
              </CurrencyBox>
              <InputBox>
                <TextInput
                  style={{
                    margin: 8,
                    padding: 6,
                    width: '96%',
                    height: '100%',
                    borderWidth: 0,
                  }}
                  placeholder=""
                  keyboardType="numeric"
                  value={state.amountToLandlord}
                />
              </InputBox>
            </AmountBox>
          </View>
        </RowContainer>

        {status == 'review' ? (
          <>
            <Typography top={'100px'} align={'left'} color={'#659B63'} size={'23px'} bold={800}>
              Declaration
            </Typography>
            <Typography top={'51px'} align={'left'} color={'#454545'} size={'20px'} bold={400}>
              I consent to TDS copying this form and any supporting documents to the other
              party(ies) to the tenancy; to the appointed adjudicator if necessary, and to anyone
              else involved in resolving a dispute about the deposit.
            </Typography>
            <Typography top={'21px'} align={'left'} color={'#454545'} size={'20px'} bold={400}>
              I acknowledge that i have read and agree to be bound by the TDS rules.
            </Typography>
            <ButtonWrapper
              top={'51px'}
              color={'#FFFFFF'}
              bcolor={agreedStatus == 'agree' ? '#A4D4A2' : '#194592'}
              radius={'11px'}
              height={'45px'}
              align="left"
              onPress={() => {
                setAgreedStatus('agree');
              }}
            >
              <Typography
                color={agreedStatus == 'agree' ? '#A4D4A2' : '#194592'}
                size={'19px'}
                bold={800}
              >
                I agree
              </Typography>
            </ButtonWrapper>
            <ButtonWrapper
              top={'51px'}
              color={'#FFFFFF'}
              bcolor={agreedStatus == 'dont_agree' ? 'red' : '#194592'}
              radius={'11px'}
              height={'45px'}
              align="left"
              onPress={() => {
                setAgreedStatus('dont_agree');
              }}
            >
              <Typography
                color={agreedStatus == 'dont_agree' ? 'red' : '#194592'}
                size={'19px'}
                bold={800}
              >
                I dont agree
              </Typography>
            </ButtonWrapper>
          </>
        ) : null}

        <ButtonWrapper
          top={'75px'}
          disabled={agreedStatus == 'agree' ? false : true}
          onPress={() => {
            makeRepaymentRequest();
          }}
        >
          <Typography color={'#FFFFFF'} size={'19px'} bold={800}>
            {status == 'review' ? 'SUBMIT' : 'OK'}
          </Typography>
        </ButtonWrapper>

        {status == 'review' ? 
          <ButtonWrapper top={'75px'} onPress={() => { setStatus('initiate') }}>
            <Typography color={'#FFFFFF'} size={'19px'} bold={800}>
              CANCEL
            </Typography>
          </ButtonWrapper>
        : null }
      </BoxShadow>
    );
  };

  return (
    <>
      <BackGroundGenerator
        isTds
        props={props}
        layoutType={(data) => {
          setType(data);
        }}
        isTLYF
        infoIcon
        redirectTDSAccount={()=> { navigateAction(props.navigation, 'Custodial Account') }}
        tdsAccountIcon
        backgroundArr={backgroundTdsArr}
        isBack={true}
        backNavigation={()=> props?.navigation?.goBack() }
      >
        <Container>
         
            {renderDepositSection()}
            {status == 'initiate' ? renderRepaymentSection() : null}
            {status == 'initiate' ? renderDetailsTabs() : renderPreviewSection()}
        </Container>
      </BackGroundGenerator>
    </>
  );
};

export default custodialRepayment;
