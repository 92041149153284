import React, { useState } from 'react';
import { View, StyleSheet, TouchableOpacity } from 'react-native';
import CustomPicker from './CustomDropdown';
import { Typography } from '../../../openFlow/style';
import getPlatformType from '../../../../helpers/getPlatform';
import { COLORS } from '../../../../constants/colorPallete';
import { FontAwesome } from '@expo/vector-icons';
import WideButton from '../../../../commonComponents/wideButton';
import { useTheme } from '@emotion/react';
import { ODSText } from '../../../../../openbrix-design-system/ui/atoms/ODSText';
import ODSButton from '../../../../../openbrix-design-system/ui/atoms/ODSButton/index.native';
const InfohubFilters = (props) => {
  const DateValues = [
    { value: '2024', label: '2024' },
    { value: '2023', label: '2023' },
    { value: '2022', label: '2022' },
    { value: '2021', label: '2021' },
    { value: '2020', label: '2020' },
  ];
  const SortValues = [
    { value: 0, label: 'Most views first' },
    { value: 1, label: 'Less views first' },
    { value: 2, label: 'Most recent first' },
    { value: 3, label: 'Oldest first' },
  ];
  const [selectedDate, setSelectedDate] = useState(null);
  const layoutType = getPlatformType();
  const [showFilter, setShowFilter] = useState(false);
  const [filters,setFilters]=useState({
    category:'',
    source:'',
    year:''
  });
  
  const [sort,setSort]=useState({
    sortBy:'',
    sortOrder:''
  });
  const transformedCategory = props?.categories.map(item => ({
    value: item.category_id,
    label: item.name
  }));
  const transformedSources = props?.sources.map(item => ({
    value: item.source_id,
    label: item.name
  }));
  const {colors}=useTheme();

  return (
    <>
      {layoutType == 'phone' && (
        <TouchableOpacity
          onPress={() => setShowFilter(!showFilter)}
          style={{
            width: '100%',
            height: 60,
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            alignItems: 'center',
            backgroundColor:'white',
            flexDirection: 'row',
            marginTop: 10,
            paddingHorizontal:20,
            borderRadius: showFilter ? 0 : 8,
            gap:30,
            ...filterStyles.box
          }}
        >
          <FontAwesome name={showFilter?'minus':"filter"} size={22} color={colors.button.main} />
          <ODSText type='md-bold' color={colors.text.primary}>
           {showFilter?'Hide Filters':'Filter & sorting'}
          </ODSText>
        </TouchableOpacity>
      )}
      {(showFilter || layoutType != 'phone') && (
        <View
          style={{
            borderBottomColor: '#b2b2b2',
            paddingBottom: layoutType == 'phone' ? 0 : 30,
            marginBottom: layoutType == 'phone' ? 10 : 0,
            backgroundColor: layoutType == 'phone' ? 'white' : 'white',
            borderBottomLeftRadius:8,
            borderBottomRightRadius:8,
            width: '100%',
            ...(layoutType === 'phone' ? filterStyles.box : {}),
          }}
        >
          <View
            style={{
              flexDirection: layoutType == 'phone' ? 'column' : 'row',
              width: '100%',
              padding: 10,
              marginTop: 15,
              justifyContent:"space-between"
            }}
          >
            <View style={{ width: layoutType=="phone"?'100%':'75%' }}>
              <ODSText type='md' color={colors.text.primary}>
                Filter by
              </ODSText>
              <View
                style={{
                  flexDirection: layoutType == 'phone' ? 'column' : 'row',
                  justifyContent: 'space-between',
                  width:'100%',
                  marginTop:10
                }}
              >
                <CustomPicker
                  data={DateValues}
                  value={filters?.year}
                  onSelect={(e)=>setFilters({...filters,year:e})}
                  label={'Date'}
                />
                <CustomPicker
                  data={transformedCategory}
                  value={filters?.category}
                  onSelect={(e)=>setFilters({...filters,category:e})}
                  label={'Category'}
                />
                <CustomPicker
                  data={transformedSources}
                  value={filters?.source}
                  onSelect={(e)=>setFilters({...filters,source:e})}
                  label={'Source'}
                />
              </View>
            </View>
            <View style={{ width: '2%' }} />
            <View style={{ width: layoutType=="phone"?'100%':'80%' }}>
            <ODSText type='md' color={colors.text.primary}>
                Sort by
              </ODSText>
              <View
                style={{
                  flexDirection: layoutType == 'phone' ? 'column' : 'row',
                  justifyContent: 'space-between',
                  marginTop:10
          
                }}
              >
                <CustomPicker
                  data={SortValues}
                  onSelect={(e)=>{
                    if (e == 0) {
                      setSort({ ...sort, sortBy: 'number_of_views', sortOrder: 'DESC' })
                  }
                  else if (e == 1) {
                      setSort({ ...sort, sortBy: 'number_of_views', sortOrder: 'ASC' })
                  }
                  else if (e == 2) {
                      setSort({ ...sort, sortBy: 'createdAt', sortOrder: 'DESC' })
                  }
                  else {
                      setSort({ ...sort, sortBy: 'createdAt', sortOrder: 'ASC' })
                  }
                  }}                 
                  label={'Views'}
                />
              
              </View>
            </View>
          </View>
          <View style={{flexDirection:layoutType!="phone"?'row':'column',alignSelf:'flex-end',marginTop:15,marginBottom:20,justifyContent:layoutType=="phone"?'center':'flex-end',width:'100%',gap:20,paddingHorizontal:layoutType=="phone"?20:0}}>
          <ODSButton
          text="RESET"
          onPress={() => {
            setFilters({
             category:'',
             source:'',
             year:''
           });
           setSort({
             sortBy:'',
             sortOrder:''
           })
           props?.onApply({filters:{
             category:'',
             source:'',
             year:''
           },sort:{
             sortBy:'',
             sortOrder:''
           }})
           }}
           type='secondary'
          >Clear filters</ODSButton>
          <ODSButton
            onPress={() => {
              props?.onApply({filters,sort})
             }}
          >Apply</ODSButton>
          
          </View>

       {layoutType!='phone' && <View style={{width:'100%',height:1,backgroundColor:'#D0D6DD'}}/>}
        </View>
      )}
    </>
  );
};
export const filterStyles = StyleSheet.create({
  box: {

    backgroundColor: 'white',
    // iOS
    shadowColor: 'rgba(0, 0, 0, 0.15)',
    shadowOffset: { width: 1, height: 3 },
    shadowOpacity: 1,
    shadowRadius: 8,
    // Android
    elevation: 3,
  },
});
export default InfohubFilters;
