import React, { useState } from 'react';
import { View, StyleSheet, TextInput } from 'react-native';
import { Typography } from '../openFlow/style';
import getPlatformType from '../../helpers/getPlatform';
import NameAvatar from '../LandingPages/common/NameAvatar';
import WideButton from '../../commonComponents/wideButton';
import { COLORS } from '../../constants/colorPallete';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigation } from '@react-navigation/native';
import { types } from '../../redux/actionTypes';
import { postComment } from '../../redux/actions/mediaHub';
import { useTheme } from '@emotion/react';
import { ODSText } from '../../../openbrix-design-system/ui/atoms/ODSText';
import ODSButton from '../../../openbrix-design-system/ui/atoms/ODSButton/index.native';
const CommentBox = (props) => {
  const token = useSelector((state) => state?.appReducer?.loginData?.data?.token);
  const profile = useSelector((state) => state?.authReducer?.profile?.data);
  const dispatch=useDispatch();
  const {colors}=useTheme();
  const layout = getPlatformType();
  const [onFocus, setonFocus] = useState(false);
  const [comment_text,setCommentText]=useState('');
  const navigation=useNavigation();
  const [load,setLoad]=useState(false);
  const onPostComment=()=>{
    setLoad(true);
    const payload={
     info_id:props?.info_id , comment: comment_text 
    }
    dispatch(postComment(payload,(e)=>{
        setLoad(false);
        if(e){
            setCommentText(''); 
            dispatch({
                type: types.SHOW_ALERT,
                alertType: "success",
                text: "Comment posted successfully",
              });
        }
    }))
  }
  return (
    <View style={{ width: layout == 'phone' ? '100%' : '50%' }}>
      <ODSText type='h6' marginTop={10} marginBottom={20} color={colors.text.primary}>Comments</ODSText>
      <View
        style={{
          width: '100%',
          maxHeight: 222,
          borderColor: onFocus ? colors.button.main : '#b2b2b2',
          borderWidth: onFocus ? 1.5 : 1,
          borderRadius: 8,
          shadowColor: 'rgba(76, 78, 100, 0.22)',
          shadowOffset: { width: 0, height: 2 },
          shadowOpacity: 1,
          shadowRadius: 10, // Set the blur radius to 10
          elevation: 5,
          padding: 20,
        }}
      >
        {token == undefined ? (
          <>
            <TextInput
              editable={false}
              placeholderTextColor={'#000'}
              onFocus={() => setonFocus(true)}
              onBlur={() => setonFocus(false)}
              placeholder={'Please login to your account to post a comment'}
              style={{
                outlineColor: 'transparent',
                width: '100%',
                textAlign: 'center',
                height: 50,
                borderBottomColor: '#b2b2b2',
                borderBottomWidth: 1,
                padding: 10,
                fontSize: layout == 'phone' ? 16 : 18,
                fontFamily: 'Raj',
                marginBottom: 15,
              }}
            />
            <WideButton
              onPress={() => {
                navigation.navigate("Sign in")
              }}
              isRaj={true}
              isLGreen={true}
              borderRadius={15}
              childText="LOGIN"
              height={40}
              width={'150px'}
              buttonType={'solid'}
              gradiant
              alignSelf={'center'}
              textSize={'18px'}
            />
          </>
        ) : (
          <>
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              <NameAvatar size={40} name={profile?.first_name + ' ' + profile?.last_name}  verified={profile?.appUser?.idverification==2?true:false}/>
              <ODSText type='sm' marginLeft={10} color={colors.text.primary}>{profile?.first_name} {profile?.last_name}</ODSText>
            </View>
            <TextInput
              multiline={true}
              numberOfLines={2}
              value={comment_text}
              onFocus={() => setonFocus(true)}
              onChangeText={setCommentText}
              onBlur={() => setonFocus(false)}
              placeholder={'Enter your comment'}
              style={{
                outlineColor: 'transparent',
                width: '100%',
                height: 80,
                borderBottomColor: '#b2b2b2',
                borderBottomWidth: 1,
                padding: 10,
                fontSize: 18,
                fontFamily: 'Raj',
                marginVertical: 15,
              }}
            />
            {/* <WideButton
              onPress={comment_text==""?{}:onPostComment}
              isRaj={true}
              isLGreen={true}
              loadingState={load}
              borderRadius={15}
              childText="POST COMMENT"
              height={40}
              isDisabled={comment_text==""?true:false}
              width={'150px'}
              buttonType={'solid'}
              gradiant
              alignSelf={'flex-end'}
              textSize={'18px'}
            /> */}
            <ODSButton restStyle={{alignSelf:'flex-end'}} height={40} disabled={comment_text==""?true:false} width={150} onPress={comment_text==""?{}:onPostComment}>
              POST COMMENT
            </ODSButton>
          </>
        )}
      </View>
    </View>
  );
};
const styles = StyleSheet.create({});
export default CommentBox;
