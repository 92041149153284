import React from 'react';
import { useDispatch } from 'react-redux';
import { Text, TouchableOpacity, View } from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import { COLORS } from '../../constants/colorPallete';
import getPlatformType from '../../helpers/getPlatform';
import DropDownPicker from '../../commonComponents/DropDownPicker.web';
import { openFlow } from '../../constants/openFlo';
import CustomTextInput from '../../commonComponents/customTextInput/customTextInput';
import { createArray } from '../../helpers/functionHelpers';
import { LineSaperator } from '../tds/style';
import { ODSText } from '../../../openbrix-design-system/ui/atoms/ODSText';
import { getTokens } from '../../../openbrix-design-system/design-tokens/color-tokens';
import ODSRadioGroup from '../../../openbrix-design-system/ui/atoms/ODSRadioGroup';
const TenantInfoFormComponent = (props) => {
  const themeObj = getTokens('light').colors;
  const dispatch = useDispatch();
  const layoutType = getPlatformType();
  let state = props?.initialValue;
  let onSelect = props?.onSelect;
  return (
    <>
      <View style={{ flexDirection: 'row' }}>
          <ODSRadioGroup
            width={'100%'}
            label={state.Label+ " *"}
            checked={state?.value=="Yes"?'yes':state?.value=="No"?"no":""}
            onChange={(value) => {
              console.log(value);
              if (value == 'yes') {
                onSelect({ ...state, value: 'Yes' });
              } else {
                onSelect({ ...state, value: 'No', [state.level_1_key]: [] });
              }
            }}
          />
      
      </View>
      {state?.level_1_key && state?.value == 'Yes' ? (
        <View
          style={{
            width: '100%',
            marginTop: 20,
          }}
        >
          <ODSText type="sm-bold" color={themeObj.text.primary}>
            {state?.level_1_Label} 
          </ODSText>

          <DropDownPicker
            items={createArray(state?.formType == 'Children' ? 'childrenCount' : '').map(
              (salutation) => {
                return {
                  value: salutation,
                  label: salutation,
                };
              }
            )}
            id="openflo-Title"
            placeholder="Title"
            defaultValue={
              state?.[state?.level_1_key]?.length ? state?.[state?.level_1_key]?.length : ''
            }
            onChangeItem={(e) => {
              const selectedValue = e.value;
              const newArray = Array.from({ length: selectedValue }, () => ({
                [state.level_2_key]: "",
              }));

              onSelect({
                ...state,
                [state.level_1_key]: newArray,
              });
            }}
            dropDownStyle={{ backgroundColor: 'white' }}
            zIndex={1001}
            bColor={'#B2B2B2'}
          />
        </View>
      ) : null}
      {state?.[state?.level_1_key]?.length ? (
        <>
          <Text
            style={{
              fontSize: 14,
              color: 'black',
            }}
          ></Text>
          <ODSText marginTop={23} type="sm-bold" color={themeObj.text.primary}>
            {' '}
            {state?.level_2_Label}
          </ODSText>
          {state?.[state?.level_1_key].map((item, index) => {
            return (
              <View style={{ flexDirection: 'row', marginTop: 8, alignItems: 'center' }}>
                <Text
                  style={{
                    fontSize: 14,
                    color: 'black',
                    marginRight: 16,
                  }}
                >
                  {index + 1}
                </Text>
                <View style={{ flex: 1 }}>
                  {state.level_2_Feild ? (
                    <CustomTextInput
                      id="level_2_Feild"
                      width="100%"
                      title=""
                      type="text"
                      onChangeText={(firstName) => {
                        const selectedValue = firstName;
                        let newArr = state?.[state?.level_1_key];
                        newArr[index][state?.level_2_key] = selectedValue;
                        onSelect({
                          ...state,
                          [state.level_1_key]: newArr,
                        });
                      }}
                      bColor={'#B2B2B2'}
                      name="firstName"
                      defaultValue={item[state?.level_2_key]}
                      errorMessage={''}
                      disabled={false}
                    />
                  ) : (
                    <DropDownPicker
                      items={createArray(state?.formType == 'Children' ? 'childrenAge' : '').map(
                        (salutation) => {
                          return {
                            value: salutation,
                            label: salutation,
                          };
                        }
                      )}
                      id="level_2_Feild"
                      placeholder="Title "
                      defaultValue={item[state?.level_2_key] ? item[state?.level_2_key] : ''}
                      onChangeItem={(e) => {
                        if(e.value=="Please Select"){
                          let newArr = state?.[state?.level_1_key];
                          newArr[index][state?.level_2_key] = "";
                          onSelect({
                            ...state,
                            [state.level_1_key]: newArr,
                          });
                        }else{
                          const selectedValue = e.value;
                          let newArr = state?.[state?.level_1_key];
                          newArr[index][state?.level_2_key] = selectedValue;
                          onSelect({
                            ...state,
                            [state.level_1_key]: newArr,
                          });
                        }
                       
                      }}
                      dropDownStyle={{ backgroundColor: 'white' }}
                      zIndex={1001}
                      disabled={false}
                      bColor={'#B2B2B2'}
                    />
                  )}
                </View>
              </View>
            );
          })}
        </>
      ) : null}
      {state?.level_1_key ? (
        <LineSaperator style={{ marginVertical: 32, opacity: 0.4, backgroundColor: 'black' }} />
      ) : (
        <View style={{ marginBottom: 31 }} />
      )}
    </>
  );
};

export default TenantInfoFormComponent;
