import React from "react";
import { Image, TouchableOpacity, View } from "react-native";
import Header from "./Header";
import { useTheme } from "@emotion/react";
import { ODSText } from "../../../openbrix-design-system/ui/atoms/ODSText";
import getScreenType from "../../../openbrix-design-system/utils/getScreenType";
import { Body, Content, HeaderContent, HeaderImage, Picture1, VideoWrapper } from "./styles";
import Footer from "./Footer";
import Wrapper from "./Wrapper";
import VimeoPlayer from "../../commonComponents/youtubePlayer/vimeoPlayer";
import Constant from "../tds/tdsRedux/Constant";
import { navigateAction } from "../../helpers/navigationHelpers";
import { useNavigation } from "@react-navigation/native";
import ODSButton from "../../../openbrix-design-system/ui/atoms/ODSButton/index.native";
const HeaderWeb = require('../../../openbrix-design-system/images/tlyfe-pages/i-desktop.png');
const HeaderTab = require('../../../openbrix-design-system/images/tlyfe-pages/i-tab.png');
const HeaderMobile = require('../../../openbrix-design-system/images/tlyfe-pages/i-mobile.png');
const Picture = require('../../../openbrix-design-system/images/tlyfe-pages/introduction/picture1.png');
const FooterWeb=require('../../../openbrix-design-system/images/tlyfe-pages/introduction/footer-web.png');
const FooterTab=require('../../../openbrix-design-system/images/tlyfe-pages/introduction/footer-tab.png');
const FooterMobile=require('../../../openbrix-design-system/images/tlyfe-pages/introduction/footer-mobile.png');


const Introduction = () => {
  const { colors } = useTheme();
  const layout = getScreenType();
  const navigation=useNavigation();
  return (
    <><Wrapper
    theme={colors.transparency.purple}
    type={"intro"}
    drawerColor={colors.theme.purple}
    headerTitle={'Secure your rental home with tlyfe'}
    headerBody={'Pre-qualify yourself to stand out from the crowd!'}
    headerImage={layout == "web" ? HeaderWeb : layout == "tab" ? HeaderTab : HeaderMobile}
      rightImage={Picture}
      type="intro"
      leftText={`Introducing the UK’s tenant lifecycle app, taking the stress out of the moving-in process and making move-in day that little bit more special!`}
      footerImage={layout == "web" ? FooterWeb : layout == "tab" ? FooterTab : FooterMobile}
    >
      <Body>
        <View style={{ padding: layout == "phone" ? 24 : 0 }}>
          <ODSText marginTop={layout == "phone" ? 16 : 0} type='h2-light' color={colors.text.primary}>Discover the benefits</ODSText>
          <View style={{ flexDirection: 'row', flexWrap: 'wrap', marginTop: layout == "web" ? 48 : 24, gap: layout == "web" ? 32 : 24 }}>
            <Content>
              <ODSText type="h4" color={colors.text.primary}>Pre-qualification</ODSText>
              <ODSText marginTop={20} type="h6-light" color={colors.text.primary}>{`Give yourself the best chance of securing your next rental property by using our Pre-Qualification tool to become a ‘Rent Ready’ Tenant.

Share the results with your agent to secure priority viewings!`}</ODSText>
            </Content>
            {layout!='web' && <View style={{ width: '100%', height: 0.25, backgroundColor: colors.text.primary }} />}
            <Content>
              <ODSText type="h4" color={colors.text.primary}>Improve your credit score with rent payments</ODSText>
              <ODSText marginTop={20} type="h6-light" color={colors.text.primary}>{`Keep an ongoing digital record of all your rental payments and, provided you pay on time, help to build your credit score. Life just got a whole lot easier!`}</ODSText>
            </Content>
             <View style={{ width: '100%', height: 0.25, backgroundColor: colors.text.primary }} />
            <Content>
              <ODSText type="h4" color={colors.text.primary}>Deposit protection with TDS</ODSText>
              <ODSText marginTop={20} type="h6-light" color={colors.text.primary}>{`View your deposit protection, download your protection certificate and manage the return of your deposit at the end of your tenancy, with the Government backed Tenancy Deposit Scheme.`}</ODSText>
            </Content>
            {layout!='web' && <View style={{ width: '100%', height: 0.25, backgroundColor: colors.text.primary }} />}
            <Content>
              <ODSText type="h4" color={colors.text.primary}>Tenant insurance</ODSText>
              <ODSText marginTop={20} type="h6-light" color={colors.text.primary}>{`A small price to pay for big protection!

Get a good nights sleep knowing your valuables are covered with Tenant Contents Insurance!`}</ODSText>
            </Content>
           <View style={{ width: '100%', height: 0.25, backgroundColor: colors.text.primary }} />
            <Content>
              <ODSText type="h4" color={colors.text.primary}>Verified digital ID</ODSText>
              <ODSText marginTop={20} type="h6-light" color={colors.text.primary}>{`Verify your identity digitally in a secure environment without having to leave your home or risk losing ID documents in the post. Quick, safe and simple verification that works!`}</ODSText>
            </Content>
            {layout!='web' && <View style={{ width: '100%', height: 0.25, backgroundColor: colors.text.primary }} />}
            <Content>
              <ODSText type="h4" color={colors.text.primary}>Tenant loan solution</ODSText>
              <ODSText marginTop={20} type="h6-light" color={colors.text.primary}>{`Struggling with move-in costs? Whether its help with your deposit, a trip to Ikea or buying essential items in move-in day, our competitive solutions will help you get settled in your new home.`}</ODSText>
            </Content>
          </View>
        </View>
      </Body>
      <VideoWrapper>
      <VimeoPlayer url={Constant.TLYFE_URL} height={"100%"} width={"100%"}/>

      </VideoWrapper>
      <View style={{width:'100%',height:layout=="phone"?450:400,backgroundColor:colors.elements.color,justifyContent:'center',alignItems:'center'}}>
        <ODSText type="h3" marginTop={80} color={colors.text.inverted}>Download today!</ODSText>
        <View>
        <ODSButton type={'secondary'} onPress={()=>navigateAction(navigation, "Sign in")} restStyle={{width:270,marginBottom:12,marginTop:24,alignSelf:'center'}}>Web login / sign up</ODSButton>
        <View style={{ flexDirection: layout == "phone" ? 'column' : 'row', gap: 16, alignItems: 'center' ,marginTop:32}}>
        <TouchableOpacity onPress={() => window.open('https://apps.apple.com/in/app/tlyfe/id6449941281')}>
              <Image source={require('../../../openbrix-design-system/images/appstore.png')} style={{ width: 180, height: 52 }} />
             </TouchableOpacity>
             <TouchableOpacity onPress={() => window.open('https://play.google.com/store/apps/details?id=com.tlyfe&hl=en_IN&gl=US')}>
              <Image source={require('../../../openbrix-design-system/images/playstore.png')} style={{ width: 180, height: 52 }} />
            </TouchableOpacity>
            </View>
            </View>
      </View>

    </Wrapper>
</>
  )
}
export default Introduction;