import { Alert, Platform } from 'react-native';
import { types } from '../../actionTypes';
import idx from 'idx';
import { store } from '../../store/setup';

import { _delete, _get, _post, _put, SERVER_URL, URL } from '../../../helpers/connectionHelpers';
import ReactGA from 'react-ga4';

export const login = (bodyofRequest, navigation,cb) => {
  return (dispatch) => {
    dispatch({ type: types.LOGIN_REQUEST });
    _post(
      `${SERVER_URL}${URL.loginURL}`,
      {}, // all headers here (if)
      JSON.stringify(bodyofRequest), // body here (if)
      (res) => {
        let responseObject = res;
        console.log('responseObject', responseObject);
        if (responseObject.status) {
          const response = fetch(`${SERVER_URL}${URL.profile}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${responseObject.data.token}`,
            },
          })
            .then((response) => response.text())
            .then((result) => {
              console.log('result', result);
              if (result) {
                let parsedResult = JSON.parse(result);
                console.log('parsedResult', parsedResult);
                if (
                  parsedResult?.data?.first_name == 'new_obx' &&
                  parsedResult?.data?.appUser?.registered_from == 'tds'
                ) {
                  cb({...parsedResult?.data,token:responseObject.data.token})
                } else {
                  dispatch({
                    type: types.SAVE_AUTH_TOKEN,
                    payload: responseObject,
                  });
                  navigation.replace('Dashboard');
                }
              }
            });
        } else {
          dispatch({
            type: types.SHOW_ALERT,
            text: res?.message,
          });
        }
        dispatch({
          type: types.LOGIN_SUCCESS,
          payload: responseObject,
        });
      },
      (err) => {
        // function to perform on failure
        dispatch({
          type: types.LOGIN_FAILED,
          error: err.message,
        });
        dispatch({
          type: types.SHOW_ALERT,
          alertType: 'error',
          text: err.message,
        });
      }
    );
  };
};
export const appUserTracking = (bodyofRequest) => {
  return () => {
    _post(
      `${SERVER_URL}${URL.userTracking}`,
      {}, // all headers here (if)
      JSON.stringify({
        ...bodyofRequest,
        platform: Platform.OS,
      }), // body here (if)
      (res) => {
        let responseObject = res;
        console.log('ressss from usertracking', responseObject);
      },
      (err) => {}
    );
  };
};
export const appUserTrackingFirstTime = (bodyofRequest) => {
  return () => {
    _post(
      `${SERVER_URL}${URL.userTrackingUserLanding}`,
      {}, // all headers here (if)
      JSON.stringify({ ...bodyofRequest, platform: Platform.OS }), // body here (if)
      (res) => {
        let responseObject = res;
        console.log('ressss from usertracking', responseObject);
      },
      (err) => {}
    );
  };
};
export const appUserTrackingProductHover = (bodyofRequest) => {
  return () => {
    _post(
      `${SERVER_URL}${URL.appUserTrackingProductHover}`,
      {}, // all headers here (if)
      JSON.stringify({ ...bodyofRequest, platform: Platform.OS }), // body here (if)
      (res) => {
        let responseObject = res;
        console.log('ressss from usertracking', responseObject);
      },
      (err) => {}
    );
  };
};
export const facebookLogin = (bodyofRequest, navigation) => {
  return (dispatch) => {
    dispatch({ type: types.LOGIN_REQUEST });
    _post(
      `${SERVER_URL}${URL.fbloginURL}`,
      {}, // all headers here (if)
      JSON.stringify(bodyofRequest), // body here (if)
      (res) => {
        let responseObject = res;
        if (responseObject.status) {
          if (responseObject?.data) {
            dispatch({
              type: types.SAVE_AUTH_TOKEN,
              payload: { data: { token: responseObject?.data } },
            });
            navigation.navigate('Dashboard');
          }
        } else {
          dispatch({
            type: types.SHOW_ALERT,
            text: res?.message,
          });
        }
        dispatch({
          type: types.LOGIN_SUCCESS,
          payload: responseObject,
        });

        // Alert.alert(JSON.stringify(res));
      },
      (err) => {
        // function to perform on failure
        dispatch({
          type: types.LOGIN_FAILED,
          error: err.message,
        });
        dispatch({
          type: types.SHOW_ALERT,
          alertType: 'error',
          text: err.message,
        });
      }
    );
  };
};
export const googleLogin = (bodyofRequest, navigation) => {
  return (dispatch) => {
    // dispatch({ type: types.LOGIN_REQUEST });
    _post(
      `${SERVER_URL}${URL.gloginURL}`,
      {}, // all headers here (if)
      JSON.stringify(bodyofRequest), // body here (if)
      (res) => {
        let responseObject = res;
        if (responseObject.status) {
          if (responseObject?.data) {
            dispatch({
              type: types.SAVE_AUTH_TOKEN,
              payload: { data: { token: responseObject?.data } },
            });
            dispatch({
              type: types.LOGIN_SUCCESS,
              payload: responseObject,
            });
            console.log('navigation', navigation);
            navigation.navigate('Dashboard');
          }
        } else {
          dispatch({
            type: types.SHOW_ALERT,
            text: res?.message,
          });
        }
        dispatch({
          type: types.LOGIN_SUCCESS,
          payload: responseObject,
        });

        // Alert.alert(JSON.stringify(res));
      },
      (err) => {
        // function to perform on failure
        dispatch({
          type: types.LOGIN_FAILED,
          error: err.message,
        });
        dispatch({
          type: types.SHOW_ALERT,
          alertType: 'error',
          text: err.message,
        });
      }
    );
  };
};
export const sharePayHistory = (value) => {
  return (dispatch) => {
    _put(
      `${SERVER_URL}${URL.toggleLedgerhistory}`,
      {}, // all headers here (if)
      JSON.stringify({ show_ledger_history: value }), // body here (if)
      (res) => {
        let responseObject = res;
        if (responseObject.status) {
          dispatch(getProfile());
        } else {
          dispatch({
            type: types.SHOW_ALERT,
            text: res?.message,
          });
        }

        // Alert.alert(JSON.stringify(res));
      },
      (err) => {
        // function to perform on failure
        dispatch({
          type: types.SHOW_ALERT,
          alertType: 'error',
          text: err.message,
        });
      }
    );
  };
};
export const getData = (bodyofRequest, navigation) => {
  return (dispatch) => {
    dispatch({ type: types.LOGIN_REQUEST });

    _get(
      `${SERVER_URL}${URL.getListFromReqRes}`,
      {}, // all headers here (if)
      {}, // body here (if)
      (res) => {
        // function to perform on success
        dispatch({
          type: types.LOGIN_SUCCESS,
          payload: res,
        });
        Alert.alert(res.message);
      },
      (err) => {
        // function to perform on failure
        dispatch({
          type: types.LOGIN_FAILED,
          error: err.message,
        });
        Alert.alert(err.message);
      }
    );
  };
};
export const signup = (bodyofRequest, navigation, cb) => {
  return (dispatch) => {
    dispatch({ type: types.SIGNUP_REQUEST });
    // ReactGA.initialize("G-084ENPRZN7");
    ReactGA.event({
      category: 'User',
      action: 'Sign Up',
      label: bodyofRequest.search, // optional
    });
    _post(
      `${SERVER_URL}${URL.signupURL}`,
      {}, // all headers here (if)
      JSON.stringify(bodyofRequest), // body here (if)
      (res) => {
        // function to perform on success
        let responseObject = res;
        dispatch({
          type: types.SIGNUP_SUCCESS,
          payload: responseObject,
        });
        if (responseObject.status) {
          if (cb) {
            cb();
          }
        } else {
          dispatch({
            type: types.SHOW_ALERT,
            alertType: 'failure',
            text: responseObject.message,
          });
        }

        // Alert.alert(JSON.stringify(res));
      },
      (err) => {
        // function to perform on failure
        dispatch({
          type: types.SIGNUP_FAILED,
          error: err.message,
        });

        Alert.alert(err.message);
      }
    );
  };
};

export const getProfile = (bodyofRequest, navigation) => {
  return (dispatch) => {
    dispatch({ type: types.PROFILE_REQUEST });
    _get(
      `${SERVER_URL}${URL.profile}`,
      {}, // all headers here (if)
      {}, // body here (if)
      (res) => {
        // function to perform on success
        let responseObject = res;
        if (responseObject.status) {
          // navigation('AgentConfirmation');
        } else {
        }
        dispatch({
          type: types.PROFILE_REQUEST_SUCCESS,
          payload: responseObject,
        });
        // Alert.alert(JSON.stringify(res));
      },
      (err) => {
        // function to perform on failure
        dispatch({
          type: types.PROFILE_REQUEST_FAILED,
          error: err.message,
        });
        Alert.alert(err.message);
      }
    );
  };
};

export const resetPassword = (bodyofRequest, navigation, cb) => {
  return (dispatch) => {
    dispatch({ type: types.RESET_PASSWORD_REQUEST });

    _post(
      `${SERVER_URL}${URL.resetPasswordURL}`,
      {}, // all headers here (if)
      JSON.stringify(bodyofRequest), // body here (if)
      (res) => {
        // function to perform on success
        let responseObject = res;
        if (responseObject.status) {
          dispatch({
            type: types.RESET_PASSWORD_SUCCESS,
            payload: responseObject,
          });
          dispatch({
            type: types.SHOW_ALERT,
            alertType: 'success',
            text: res.data,
          });
          cb(true);
        } else {
          dispatch({
            type: types.RESET_PASSWORD_FAILED,
            error: res.message,
          });
          dispatch({
            type: types.SHOW_ALERT,
            alertType: 'error',
            text: res.message,
          });
          cb(true);
        }
      },
      (err) => {
        // function to perform on failure
        dispatch({
          type: types.RESET_PASSWORD_FAILED,
          error: err.message,
        });
        dispatch({
          type: types.SHOW_ALERT,
          alertType: 'error',
          text: err.message,
        });
        Alert.alert(err.message);
      }
    );
  };
};

export const changePassword = (bodyofRequest, navigation) => {
  return (dispatch) => {
    dispatch({ type: types.CHANGE_PASSWORD_REQUEST });

    _post(
      `${SERVER_URL}${URL.changePasswordURL}`,
      {}, // all headers here (if)
      JSON.stringify(bodyofRequest), // body here (if)
      (res) => {
        // function to perform on success
        let responseObject = res;
        if (responseObject.status) {
          dispatch({
            type: types.SHOW_ALERT,
            alertType: 'success',
            text: res.data,
          });
          dispatch({
            type: types.CHANGE_PASSWORD_SUCCESS,
            payload: responseObject,
          });
          dispatch({
            type: types.REMOVE_AUTH_TOKEN,
            payload: responseObject,
          });
          navigation('Sign in');
        } else {
          dispatch({
            type: types.CHANGE_PASSWORD_FAILED,
            error: res.message,
          });
          dispatch({
            type: types.SHOW_ALERT,
            alertType: 'error',
            text: res.message,
          });
        }

        // Alert.alert(JSON.stringify(res));
      },
      (err) => {
        // function to perform on failure
        dispatch({
          type: types.CHANGE_PASSWORD_FAILED,
          error: err.message,
        });
        dispatch({
          type: types.SHOW_ALERT,
          alertType: 'error',
          text: err.message,
        });
        Alert.alert(err.message);
      }
    );
  };
};

export const uploadFiles = (files, type, cb) => {
  return (dispatch) => {
    dispatch({ type: types.UPLOAD_FILE });
    let token = idx(store, (_) => _.getState().appReducer.loginData.data.token);
    var myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${token}`);
    var formdata = new FormData();
    formdata.append('type', type);
    files.forEach((item) => {
      formdata.append('files', item);
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow',
    };

    fetch(`https://assets.openbrix.co.uk/uploads`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        const response = JSON.parse(result);
        if (response.status) {
          cb(response);
          dispatch({
            type: types.UPLOAD_FILE_SUCCESS,
            data: response,
          });
        } else {
          dispatch({
            type: types.UPLOAD_FILE_FAIL,
          });
          dispatch({
            type: types.SHOW_ALERT,
            alertType: 'error',
            text: 'Unable to upload doc',
          });
          // Alert.alert("Unable to upload doc");
        }
      })
      .catch((error) =>
        dispatch({
          type: types.SHOW_ALERT,
          alertType: 'error',
          text: 'Unable to upload doc',
        })
      );
  };
};

export const clearuploadedFiles = () => {
  return (dispatch) => {
    dispatch({ type: types.UPLOAD_FILE_RESET });
  };
};
export const uploadDoc = (bodyofRequest, navigation, cb) => {
  return (dispatch) => {
    dispatch({ type: types.UPLOAD_DOC });
    let token = idx(store, (_) => _.getState().appReducer.loginData.data.token);
    var myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${token}`);
    myHeaders.append('Content-type', `application/json`);
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify({
        name: bodyofRequest.name,
        filepath: bodyofRequest.file.openbrixName,
      }),
    };
    fetch(`${SERVER_URL}${URL.uploaddoc}`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        const response = JSON.parse(result);
        if (response.status) {
          dispatch({
            type: types.UPLOAD_DOC_SUCCESS,
            alertType: 'success',
            text: 'Document uploaded',
          });
          dispatch(fetchDoc());
          if (cb) {
            cb();
          } else {
            navigation('Document Share Center');
          }
        } else {
          dispatch({
            type: types.SHOW_ALERT,
            alertType: 'error',
            text: 'Unable to upload doc',
          });
          Alert.alert('Unable to upload doc');
          if (cb) {
            cb();
          }
        }
      })
      .catch((error) => {
        if (cb) {
          cb();
        }
      });
  };
};

export const fetchDoc = () => {
  return (dispatch) => {
    dispatch({ type: types.GET_DOC });
    _get(
      `${SERVER_URL}${URL.getDoc}`,
      {}, // all headers here (if)
      {}, // body here (if)
      (res) => {
        // function to perform on success
        let responseObject = res;
        if (responseObject.status) {
          // navigation('AgentConfirmation');
        } else {
        }
        dispatch({
          type: types.GET_DOC_SUCCESS,
          payload: responseObject,
        });
        // Alert.alert(JSON.stringify(res));
      },
      (err) => {
        // function to perform on failure
        dispatch({
          type: types.GET_DOC_FAIL,
          error: err.message,
        });
        Alert.alert(err.message);
      }
    );
  };
};

export const updateSharedStatusOfDoc = (id, status) => {
  return (dispatch) => {
    dispatch({ type: types.UPDATE_SHARE_STATUS_DOC });
    _put(
      `${SERVER_URL}${URL.updatesharestatus}/${id}/${status}`,
      {}, // all headers here (if)
      JSON.stringify({}), // body here (if)
      (res) => {
        // function to perform on success
        let responseObject = res;

        if (responseObject.status) {
          dispatch({
            type: types.UPDATE_SHARE_STATUS_DOC_SUCCESS,
            payload: responseObject,
          });
          dispatch(fetchDoc());
          // navigation('AgentConfirmation');
        } else {
        }

        // Alert.alert(JSON.stringify(res));
      },
      (err) => {
        dispatch({
          type: types.UPDATE_SHARE_STATUS_DOC_FAIL,
          error: err.message,
        });
        Alert.alert(err.message);
      }
    );
  };
};

export const deleteDoc = (id) => {
  return (dispatch) => {
    dispatch({ type: types.UPDATE_SHARE_STATUS_DOC });
    _delete(
      `${SERVER_URL}${URL.deleteDoc}/${id}`,
      {}, // all headers here (if)
      JSON.stringify({}), // body here (if)
      (res) => {
        // function to perform on success

        let responseObject = res;
        if (responseObject.status) {
          dispatch({
            type: types.SHOW_ALERT,
            alertType: 'success',
            text: 'Document deleted successfully',
          });
          dispatch(fetchDoc());
          // navigation('AgentConfirmation');
        } else {
        }
        dispatch({
          type: types.DELETE_DOC_SUCCESS,
          payload: responseObject,
        });
        // Alert.alert(JSON.stringify(res));
      },
      (err) => {
        // function to perform on failure
        dispatch({
          type: types.DELETE_DOC_FAIL,
          error: err.message,
        });
        Alert.alert(err.message);
      }
    );
  };
};

export const updateProfile = (payload) => {
  return (dispatch) => {
    dispatch({ type: types.UPDATE_PROFILE });
    _put(
      `${SERVER_URL}${URL.updateProfile}`,
      {}, // all headers here (if)
      JSON.stringify(payload), // body here (if)
      (res) => {
        // function to perform on success
        let responseObject = res;

        dispatch({
          type: types.UPDATE_PROFILE_SUCCESS,
          payload: responseObject,
        });
        dispatch({
          type: types.SHOW_ALERT,
          alertType: 'success',
          text: res.data,
        });
        // Alert.alert(JSON.stringify(res));
      },
      (err) => {
        // function to perform on failure
        dispatch({
          type: types.UPDATE_PROFILE_FAIL,
          error: err.message,
        });
        dispatch({
          type: types.SHOW_ALERT,
          text: res.error,
        });
      }
    );
  };
};
export const referFriend = (payload, cb) => {
  return (dispatch) => {
    dispatch({ type: types.CONTACTUS_REQUEST });
    _post(
      `${SERVER_URL}${URL.referFriend}`,
      {}, // all headers here (if)
      JSON.stringify(payload), // body here (if)
      (res) => {
        let responseObject = res;
        if (responseObject.status) {
          if (cb) {
            cb();
          }
          dispatch({
            type: types.SHOW_ALERT,
            alertType: 'success',
            text: 'Referral has been sent',
          });
          dispatch({
            type: types.CONTACTUS_SUCCESS,
            payload: responseObject,
          });
        } else {
          dispatch({
            type: types.CONTACTUS_FAILED,
            error: res.error,
          });
        }
      },
      (err) => {
        // function to perform on failure
        dispatch({
          type: types.UPDATE_PROFILE_FAIL,
          error: err.message,
        });
        dispatch({
          type: types.SHOW_ALERT,
          text: res.error,
        });
      }
    );
  };
};
export const contactUs = (bodyofRequest, cb) => {
  return (dispatch) => {
    dispatch({ type: types.CONTACTUS_REQUEST });

    _post(
      `${SERVER_URL}${URL.contactusURL}`,
      {}, // all headers here (if)
      JSON.stringify(bodyofRequest), // body here (if)
      (res) => {
        // function to perform on success
        let responseObject = res;
        if (cb) {
          cb(res);
        }
        if (responseObject.status) {
          dispatch({
            type: types.SHOW_ALERT,
            alertType: 'success',
            text: res.data,
          });
          dispatch({
            type: types.CONTACTUS_SUCCESS,
            payload: responseObject,
          });
        } else {
          dispatch({
            type: types.CONTACTUS_FAILED,
            error: res.error,
          });
          dispatch({
            type: types.SHOW_ALERT,
            alertType: 'error',
            text: res.error,
          });
        }

        // Alert.alert(JSON.stringify(res));
      },
      (err) => {
        // function to perform on failure
        dispatch({
          type: types.CONTACTUS_FAILED,
          error: err.message,
        });
        dispatch({
          type: types.SHOW_ALERT,
          alertType: 'error',
          text: err.message,
        });
        Alert.alert(err.message);
      }
    );
  };
};
export const LogoutApi = (isDelete = false, navigation) => {
  return (dispatch) => {
    if (isDelete) {
      _get(
        `${SERVER_URL}${URL.deleteAccount}`,
        {}, // all headers here (if)
        null, // body here (if)
        (res) => {
          // function to perform on success
          // dispatch({
          //   type: types.REMOVE_AUTH_TOKEN,
          // });
          if (res.status) {
            dispatch({
              type: types.REMOVE_AUTH_TOKEN,
            });
            navigation.navigate('Sign in');
          } else {
            alert(res.message);
          }
        },
        (err) => {
          // function to perform on failure
          // dispatch({
          //   type: types.LOGIN_FAILED,
          //   error: err.message,
          // });
          Alert.alert(err.message);
        }
      );
    } else {
      _post(
        `${SERVER_URL}${URL.logout}`,
        null, // all headers here (if)
        null, // body here (if)
        (res) => {
          // function to perform on success
          // dispatch({
          //   type: types.REMOVE_AUTH_TOKEN,
          // });
          if (res.status) {
            dispatch({
              type: types.REMOVE_AUTH_TOKEN,
            });
            navigation.navigate('Sign in');
          } else {
            alert(res.message);
          }
        },
        (err) => {
          // function to perform on failure
          // dispatch({
          //   type: types.LOGIN_FAILED,
          //   error: err.message,
          // });
          Alert.alert(err.message);
        }
      );
    }
  };
};
export const saveAuthTokenFromMobile = (data) => {
  console.log('payload', data);

  return (dispatch) => {
    dispatch({
      type: types.saveAuthTokenFromMobile,
      payload: {
        status: true,
        data: {
          token: data?.authData,
          isWebView: true,
          subUrl: data?.subUrl,
        },
      },
    });
  };
};

export const updateNotiSettings = (payload) => {
  return (dispatch) => {
    dispatch({ type: types.UPDATE_NOTIFICATION_REQUEST });
    _put(
      `${SERVER_URL}${URL.updateNotiSettings}`,
      {}, // all headers here (if)
      JSON.stringify(payload), // body here (if)
      (res) => {
        // function to perform on success

        dispatch({
          type: types.UPDATE_NOTIFICATION_SUCCESS,
          payload: res,
        });
        dispatch({
          type: types.SHOW_ALERT,
          alertType: 'success',
          text: res.data,
        });
        dispatch(getProfile());
      },
      (err) => {
        // function to perform on failure
        dispatch({
          type: types.UPDATE_NOTIFICATION_FAILED,
          error: err.message,
        });
        dispatch({
          type: types.SHOW_ALERT,
          text: res.error,
        });
      }
    );
  };
};
export const updateIsBritishPassword = (payload) => {
  return (dispatch) => {
    dispatch({
      type: types.UPDATE_IS_BRITISH_PASSWORD,
      payload: payload,
    });
  };
};
