import React from "react";
import { useTheme } from "@emotion/react";
import { View, Image, TouchableOpacity, Linking, Platform } from "react-native";
import { Link, useNavigation } from "@react-navigation/native";
import { ODSText } from "../../../../openbrix-design-system/ui/atoms/ODSText";
import getScreenType from "../../../../openbrix-design-system/utils/getScreenType";
import { ODSIcon } from "../../../../openbrix-design-system/ui/atoms/ODSIcon";
import { Center, Container, Left, Right } from "./styles";
import { navigateAction } from "../../../helpers/navigationHelpers";
const Footer = ({ bgColor }) => {
    const { colors } = useTheme();
    const layout = getScreenType();
    const navigation=useNavigation();
    const _redirectSocial = (url) => {
        window.open(url);
      };
    return (
        <Container style={{ backgroundColor: bgColor }}>
            <Left>
                <ODSText type="h2-light" color={colors.elements.invert} textAlign='left'>A place for everything. Everything in it’s place.</ODSText>
               <View style={{flexDirection:'row',marginTop:12,gap:24,alignItems:'center'}}>
               <Image source={require('../../../../openbrix-design-system/images/logos/tlyfe_logo_white.png')} style={{ width: 87, height: 47,resizeMode:'contain' }} />
               <Image source={require('../../../../openbrix-design-system/images/logos/tds-logo.png')} style={{ width: 130, height: 60,resizeMode:'contain' }} />
               </View>
               </Left>
            <Center>
                <View style={{flexDirection:'row',gap:24}}>
                <View style={{gap:18}}>
                <TouchableOpacity onPress={() => navigateAction(navigation, 'User Details')}>
                        <ODSText type='md' color={colors.text.primary} >— About Us</ODSText>
                </TouchableOpacity>
                <TouchableOpacity  onPress={() => navigateAction(navigation, 'MediaHub')}>
                        <ODSText type='md' color={colors.text.primary} >— Info Hub</ODSText>
                    </TouchableOpacity>
                    <TouchableOpacity  onPress={() => navigateAction(navigation, 'Contact Us')}>
                        <ODSText type='md' color={colors.text.primary} >— Contact Us</ODSText>
                    </TouchableOpacity>
                </View>
                <View style={{gap:18}}>
                <TouchableOpacity onPress={()=>navigateAction(navigation, 'Legal Bits',{to:"policy"})}>
                        <ODSText type='md' color={colors.text.primary} >— Privacy Policy</ODSText>
                </TouchableOpacity>
                <TouchableOpacity onPress={() => navigateAction(navigation, 'Charity')}>
                        <ODSText type='md' color={colors.text.primary} >— Charity</ODSText>
                    </TouchableOpacity>
                    <TouchableOpacity onPress={() => navigateAction(navigation, 'Legal Bits',{to:"t/c"})}>
                        <ODSText type='md' color={colors.text.primary} >— T&C</ODSText>
                    </TouchableOpacity>
                </View>
                </View>
                <View style={{flexDirection:'row',gap:16,marginTop:24}}>
                    <TouchableOpacity onPress={()=>_redirectSocial('https://www.facebook.com/openbrix/')}>
                    <ODSIcon icon="Social-Facebook"  size={32}/>
                    </TouchableOpacity>
                    <TouchableOpacity onPress={()=>_redirectSocial('https://www.instagram.com/openbrix/')}>
                    <ODSIcon icon="Social-Instagram"  size={32}/>
                    </TouchableOpacity>
                    <TouchableOpacity onPress={()=>_redirectSocial('https://www.linkedin.com/company/openbrix/')}>
                    <ODSIcon icon="Social-linkedin" size={32}/>
                    </TouchableOpacity>
                    <TouchableOpacity onPress={()=>_redirectSocial('https://twitter.com/OpenBrix')}>
                    <ODSIcon icon="Social-Twitter" size={32}/>
                    </TouchableOpacity>

                </View>
                </Center>
                <Right>  
                <ODSText type="xs" color={colors.text.secondary}>{`Openbrix Ltd - 2024 All rights reserved. Company Registration No. 11305881 Company VAT No 32 12 11 960.

Openbrix Ltd is authorised and regulated by the Financial Conduct Authority. Our FCA Firm Reference Number is 968596.

Openbrix Ltd is an Introducer Appointed Representative of Paragon Advance Limited, whose FCA Firm Reference is Number is 304595

Our trading address is 4-5 Quay Point, Northarbour Road, Portsmouth, PO6 3TD `}</ODSText>

        </Right>
          

        </Container>
    )
}
export default Footer;