import React, { useState } from 'react';
import { StyleSheet, View, Platform, Switch } from 'react-native';
import { useTheme } from '@emotion/react';
import { ODSIcon } from '../../../atoms/ODSIcon';
import { ODSText } from '../../../atoms/ODSText';
import ODSButton from '../../../atoms/ODSButton';

const SettingsCards = ({
    onClose,
    data,
    onSave
}) => {
  const { colors } = useTheme();
  const [form, setForm] = useState({
    property_notification: data.property_notification,
    marketplace_notification: data.marketplace_notification,
    maintenance_notification: data.maintenance_notification,
    rental_notification: data.rental_notification,
    openflow_notification: data.openflow_notification,
  });

  const handleSwitchChange = (name, value) => {
    setForm({ ...form, [name]: value });
  };

  const handleSave = () => {
    onSave(form);
    onClose();
  };

  return (
    <View style={{ flex: 1 }}>
      <View style={{ justifyContent: 'center', alignItems: 'center', flexDirection: 'row', marginBottom: 12 }}>
        <ODSIcon icon={'TB-Notifications-Settings'} size={24} color={colors.button.main} />
        <ODSText type='h5' marginLeft={12} color={colors.text.primary}>Notification Settings</ODSText>
      </View>
      <ODSText textAlign='center' type='sm' marginTop={12} color={colors.text.primary}>
        Please select the notifications you want to receive.
      </ODSText>
      
      <View style={{ flexDirection: 'row', alignItems: 'center', marginTop: 32 }}>
        <Switch
          value={form.openflow_notification}
          onValueChange={(value) => handleSwitchChange('openflow_notification', value)}
        />
        <ODSText type='md' marginLeft={12} color={colors.text.primary}>Openflo</ODSText>
      </View>
      
      <View style={{ flexDirection: 'row', alignItems: 'center', marginTop: 24 }}>
        <Switch
          value={form.marketplace_notification}
          onValueChange={(value) => handleSwitchChange('marketplace_notification', value)}
        />
        <ODSText type='md' marginLeft={12} color={colors.text.primary}>Marketplace</ODSText>
      </View>
      
      <View style={{ flexDirection: 'row', alignItems: 'center', marginTop: 24 }}>
        <Switch
          value={form.rental_notification}
          onValueChange={(value) => handleSwitchChange('rental_notification', value)}
        />
        <ODSText type='md' marginLeft={12} color={colors.text.primary}>Rental Transaction</ODSText>
      </View>
      
      <View style={{ flexDirection: 'row', alignItems: 'center', marginTop: 24 }}>
        <Switch
          value={form.maintenance_notification}
          onValueChange={(value) => handleSwitchChange('maintenance_notification', value)}
        />
        <ODSText type='md' marginLeft={12} color={colors.text.primary}>Maintenance</ODSText>
      </View>
      
      <View style={{ flexDirection: 'row', alignItems: 'center', marginVertical: 24 }}>
        <Switch
          value={form.property_notification}
          onValueChange={(value) => handleSwitchChange('property_notification', value)}
        />
        <ODSText type='md' marginLeft={12} color={colors.text.primary}>Property Alert</ODSText>
      </View>
      
      <ODSButton onPress={handleSave}>Save</ODSButton>
      <ODSButton onPress={() => onClose()} restStyle={{ marginTop: 24 }} type='secondary'>Close</ODSButton>
    </View>
  );
};

const styles = StyleSheet.create({
  shadowBox: {
    backgroundColor: '#fff',
    marginHorizontal: 20,
    padding: 32,
    borderRadius: 12,
    ...Platform.select({
      ios: {
        shadowColor: '#000',
        shadowOffset: { width: 1, height: 3 },
        shadowOpacity: 0.15,
        shadowRadius: 8,
      },
      android: {
        elevation: 4,
      },
      web: {
        shadowColor: '#000',
        shadowOffset: { width: 1, height: 3 },
        shadowOpacity: 0.15,
        shadowRadius: 8,
      }
    }),
  },
});

export default SettingsCards;
