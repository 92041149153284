import React, { useState } from "react";

import MediaHubList from "./mediaHubList";
import BackGroundGenerator from "../LandingPages/common/BackGroundGenerator";
import { backgroundSearchArr } from "../LandingPages/images/tlyfe/searchArrayTlyfe";
import { Image, View } from "react-native";
import { ODSText } from "../../../openbrix-design-system/ui/atoms/ODSText";
import { useTheme } from "@emotion/react";
import getPlatformType from "../../helpers/getPlatform";

const MediaHub = (props) => {
  const [type, setType] = useState("web");
  const {colors}=useTheme();
  const layout =getPlatformType();

  return (
    <BackGroundGenerator
        props={props}
        layoutType={(data) => setType(data)}
        backgroundArr={backgroundSearchArr}
        isTLYF={true}
        isLanding
        hideMiddleLogo
      >
        <View>
          <Image source={require('../../../openbrix-design-system/images/dashboard/infohub-web.png')} style={{width:'100%',height:400,marginBottom:layout=='web'?-170:-90,marginTop:layout!="web"?-40:0}}/>
      <View style={{justifyContent:'center',alignItems:'center',paddingVertical:layout=="web"?48:32,zIndex:1000,backgroundColor:'#fafafa'}}>  
        <ODSText type="h3" color={colors.text.primary} >Info Hub</ODSText>
        <ODSText marginVertical={14} type='h4-light' color={colors.text.primary} >in collaboration with</ODSText>
        <Image source={require('../../../openbrix-design-system/images/logos/tds-black-logo.png')} style={{width:104,height:60,resizeMode:'contain'}}/>
      </View>
      <MediaHubList navigation={props.navigation} detailsLink="MediaHubDetailsPage" />
      </View>
    </BackGroundGenerator>
  );
};

export default MediaHub;
