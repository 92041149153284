import React, { useEffect } from 'react';
import { Dimensions, Image, ImageBackground, TouchableOpacity, View } from 'react-native';
import { Entypo } from '@expo/vector-icons';
import stylesMobile from './stylesMobile';
import stylesTab from './stylesTab';
import stylesWeb from './stylesWeb';
import { Typography } from '../../openFlow/style';
import WideButton from '../../../commonComponents/wideButton';
import LandingSignin from '../landingSignin/landingSignin';
import Modal from 'react-native-modal';
import getPlatformType from '../../../helpers/getPlatform';
import LandingRegister from '../landingRegister/landingRegister';
import ResetPassword from '../../resetPassword';
import { COLORS } from '../../../constants/colorPallete';
import ODSButton from '../../../../openbrix-design-system/ui/atoms/ODSButton/index.native';
import { useTheme } from '@emotion/react';

const CommonAuth = (props) => {
  const {
    type,
    heading,
    subheading,
    desc,
    hideButton,
    isTLYF,
    navigation,
    bigbuttonfunction,
    logoName,
    hideAuth,
    showRegistrationForm,
    customSubHeadingStyle,
    params,
    customImageHeader,
    showTdsLogo,
    onScrollButtonClick,
    showIntro
  } = props;
  const { width } = Dimensions.get('window');
  const layoutType = getPlatformType();
  const [showModal, setShowModal] = React.useState(false);
  const [showRegister, setShowRegister] = React.useState(params ? true : !showRegistrationForm);
  const styles = type == 'web' ? stylesWeb : type == 'mobile' ? stylesMobile : stylesTab;
  const isSmallWeb = width >= 1025 && width <= 1200;
  const upperCaseFirstLetter = (string) => {
    let str = string.slice(0, 1).toUpperCase() + string.slice(1).toLowerCase();
    return capUk(str);
  };
  const { colors } = useTheme();
  useEffect(
    (_) => {
      window.scrollTo(0, null);
    },
    [showRegister]
  );
  const capUk = (str) => {
    return str.replace("uk's", "UK's");
  };

  return (
    <ImageBackground
      resizeMode={customImageHeader ? 'stretch' : 'cover'}
      source={
        isTLYF
          ? require(type == 'web'
              ? './images/homeleftGreen.png'
              : './images/homeleftMobileGreen.png')
          : require(type == 'web' ? './images/homeleft.png' : './images/homeleftMobile.png')
      }
      style={
        customImageHeader
          ? customImageHeader
          : isSmallWeb
          ? {
              paddingVertical: 150,
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'row',
              paddingHorizontal: '8%',
            }
          : type == 'mobile' && hideButton
          ? { paddingHorizontal: '6%', paddingVertical: 150 }
          : styles.container
      }
    >
      <View
        style={{
          flex: layoutType == 'web' ? 0.58 : {},
        }}
      >
        <Typography
          raj
          bold={'700'}
          color={'white'}
          size={type !== 'web' ? '50px' : '75px'}
          line={type !== 'web' ? '48px' : '61px'}
          align={type == 'web' ? 'left' : 'center'}
        >
          {heading ? heading : ''}
          {/* {logoName ? logoName : "tlyfe"} */}
        </Typography>

        <Typography
          raj
          color={'white'}
          bold={'700'}
          top={'10px'}
          size={type !== 'web' ? '28px' : '35px'}
          line={type !== 'web' ? '29px' : '35px'}
          align={type == 'web' ? 'left' : 'center'}
          style={customSubHeadingStyle ? customSubHeadingStyle : {}}
        >
          {subheading ? subheading : ''}
        </Typography>
        <Typography
          raj
          top={'20px'}
          color={'white'}
          size={type !== 'web' ? '24px' : '27px'}
          line={type !== 'web' ? '29px' : '35px'}
          align={type == 'web' ? 'left' : 'center'}
        >
          {desc ? upperCaseFirstLetter(desc) : ''}
        </Typography>
        {hideButton ? null : (
          <View style={{ marginTop: 30, alignSelf: layoutType == 'web' ? 'flex-start' : 'center' }}>
            <TouchableOpacity
              onPress={onScrollButtonClick}
              style={{
                height: 70,
                width: 306,
                borderWidth: 1,
                borderRadius: 100,
                borderColor: 'white',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Typography size={24}>
                LEARN MORE
                <Entypo name="arrow-long-down" size={24} color="white" style={{ marginLeft: 15 }} />
              </Typography>
            </TouchableOpacity>
            {/* <WideButton
              childText={"LEARN MORE"}
              buttonType={"stripe"}
              alignSelf={"center"}
              width={"306px"}
              height={"70px"}
              backgroundColor={"white"}
              textSize={"24px"}
              onPress={() => {
                // bigbuttonfunction();
              }}
              style={{ alignSelf: "center" }}
              ricon={<Entypo name="arrow-long-down" size={24} color="white" style={{marginLeft:15}}/>}
            /> */}
          </View>
        )}
        {showTdsLogo ? (
          <Image
            source={require('./images/tds.png')}
            style={{ width: 190, height: 70, alignSelf: type == 'web' ? 'flex-start' : 'center' }}
          ></Image>
        ) : null}
      </View>
      <View style={styles.formWrapper}>
        {hideAuth ?null:showIntro? (
          <View
            style={{
              flexDirection: type == 'web' ? 'row' : 'column',

              alignSelf: 'center',
              marginBottom:20
            }}
          >
            <View
              style={[
                styles.shawow,
                {
                  width: 315,
                  borderRadius: 20,
                  margin: 20,
                  backgroundColor: '#fff',
                },
              ]}
            >
              <View
                style={{
                  ...styles.shawow,
                  flex: 0.3,
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderBottomWidth: 1,
                  borderColor: '#dadada',
                }}
              >
                <Typography raj bold={'900'} color={COLORS.LGREEN} size={'26px'} align={'center'}>
                  Login
                </Typography>
              </View>
              <View style={{ flex: 0.7, padding: 20 }}>
                <Typography
                  line={'35px'}
                  raj
                  bold={'600'}
                  top={'10px'}
                  color={'#1C1C1C'}
                  size={'26px'}
                  align={'center'}
                >
                  Are you a tenant? We just moved!
                </Typography>
                <Typography
                  line={'26px'}
                  raj
                  bold={'500'}
                  top={'6px'}
                  color={'#737373'}
                  size={'20px'}
                  align={'center'}
                >
                  Please go to tlyfe.co.uk to register or login to your account.
                </Typography>
                <ODSButton
                  restStyle={{ width: '100%', marginTop: 20, height: 40,marginBottom: 50 }}
                  type="primary"
                  onPress={() => window.open('https://www.tlyfe.co.uk/', '_blank')}
                  disabled={false}
                >
                  {'Go to tlyfe.co.uk'.toUpperCase()}
                </ODSButton>
              </View>
            </View>
            <View
              style={[
                styles.shawow,
                {
                  width: 315,

                  borderRadius: 20,
                  margin: 20,
                  backgroundColor: '#fff',
                },
              ]}
            >
              <View
                style={{
                  ...styles.shawow,
                  flex: 0.3,
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderBottomWidth: 1,
                  borderColor: '#dadada',
                }}
              >
                <Typography raj bold={'900'} color={COLORS.MAINPINK} size={'26px'} align={'center'}>
                  Login
                </Typography>
              </View>
              <View style={{ flex: 0.7, padding: 20 }}>
                <Typography
                  line={'35px'}
                  raj
                  bold={'600'}
                  top={'10px'}
                  color={'#1C1C1C'}
                  size={'26px'}
                  align={'center'}
                >
                  Are you a Agent?
                </Typography>
                <Typography
                  line={'26px'}
                  raj
                  bold={'500'}
                  top={'6px'}
                  color={'#737373'}
                  size={'20px'}
                  align={'center'}
                >
                  Please go to agent.openbrix.co.uk to register or login to your account.
                </Typography>
                <ODSButton
                  restStyle={{
                    width: '100%',
                    marginTop: 20,
                    alignItems: 'center',
                    marginBottom: 50 
                  }}
                  themeColor={colors.main.red}
                  type="primary"
                  onPress={() => window.open('https://www.agent.openbrix.co.uk/', '_blank')}
                  disabled={false}
                >
                  {'Go to agent.openbrix.co.uk'.toUpperCase()}
                </ODSButton>
              </View>
            </View>
          </View>
        ) : (
          <View
            style={{
              backgroundColor: '#FFFFFF',
              borderRadius: 20,
              minWidth: layoutType == 'phone' ? '0px' : '455px',
            }}
          >
            {showRegister ? (
              <LandingRegister
                isTLYF={isTLYF}
                navigation={navigation}
                params={params}
                onRegisterClick={() => setShowRegister(false)}
                {...props}
              />
            ) : (
              <LandingSignin
                onRegisterClick={() => {
                  setShowRegister(true);
                }}
                onForgetClick={() => {
                  setShowModal(true);
                }}
                params={params}
                isTLYF={isTLYF}
                navigation={navigation}
                {...props}
              />
            )}
          </View>
        )}
      </View>
      <Modal
        isVisible={showModal}
        transparent={true}
        coverScreen={false}
        hasBackdrop={false}
        backdropOpacity={0}
        onBackdropPress={() => setShowModal(false)}
        style={{
          margin: 0,
          width: '100%',
          flex: 1,
          backgroundColor: '#00000080',
          paddingVertical: layoutType == 'phone' ? '0%' : '1%',
          paddingHorizontal: layoutType == 'phone' ? '0%' : '3%',
        }}
      >
        <View
          style={{
            width: layoutType == 'phone' ? '100%' : '600px',
            backgroundColor: '#FAFAFA',
            borderRadius: layoutType == 'phone' ? 0 : 20,
            overflow: 'hidden',
            alignSelf: 'center',
            height: layoutType == 'phone' ? '100%' : 'inherit',
          }}
        >
          <ResetPassword
            navigation={navigation}
            {...props}
            closePopup={() => {
              setShowModal(false);
            }}
          />
        </View>
      </Modal>
    </ImageBackground>
  );
};
export default CommonAuth;
