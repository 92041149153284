import React, { useState, useEffect } from 'react';
import { Dimensions, Image, TouchableOpacity, View } from 'react-native';
import Header from './Header';
import { useTheme } from '@emotion/react';
import { ODSText } from '../../../openbrix-design-system/ui/atoms/ODSText';
import getScreenType from '../../../openbrix-design-system/utils/getScreenType';
import {
  Body,
  Content,
  HeaderContent,
  HeaderImage,
  LoginCard,
  Picture1,
  RegisterCard,
  VideoWrapper,
} from './styles';
import Footer from './Footer';
import Wrapper from './Wrapper';
import VimeoPlayer from '../../commonComponents/youtubePlayer/vimeoPlayer';
import Constant from '../tds/tdsRedux/Constant';
import { ODSInput } from '../../../openbrix-design-system/ui/atoms/ODSInput';
import ODSButton from '../../../openbrix-design-system/ui/atoms/ODSButton';
import { GOOGELELOGIKEY, SERVER_URL, URL } from '../../helpers/connectionHelpers';
import {
  GoogleLogin,
  GoogleOAuthProvider,
  useGoogleLogin,
  useGoogleOneTapLogin,
} from '@react-oauth/google';

import ODSCheckbox from '../../../openbrix-design-system/ui/atoms/ODSCheckbox';
import { ODSIcon } from '../../../openbrix-design-system/ui/atoms/ODSIcon';
import ODSCombobox from '../../../openbrix-design-system/ui/atoms/ODSComboBox';
import label from '../openFlow/constants';
import { ODSModal } from '../../../openbrix-design-system/ui/atoms/ODSModal/ODSModal.web';
import { useDispatch } from 'react-redux';
import { EmailValidation, MobileValidation, NameValidation, PasswordValidation } from '../../helpers/validation';
import { googleLogin, login, resetPassword } from '../../redux/actions/auth';
import RegisterLanding from './RegisterLanding';
import { types } from '../../redux/actionTypes';
import Regex from '../../helpers/regex';
const HeaderWeb = require('../../../openbrix-design-system/images/tlyfe-pages/i-desktop.png');
const HeaderTab = require('../../../openbrix-design-system/images/tlyfe-pages/i-tab.png');
const HeaderMobile = require('../../../openbrix-design-system/images/tlyfe-pages/login-mobile.png');
const FooterWeb = require('../../../openbrix-design-system/images/tlyfe-pages/introduction/footer-web.png');
const FooterTab = require('../../../openbrix-design-system/images/tlyfe-pages/introduction/footer-tab.png');
const FooterMobile = require('../../../openbrix-design-system/images/tlyfe-pages/introduction/footer-mobile.png');

const LoginLanding = (props) => {
  const { colors } = useTheme();
  const layout = getScreenType();
  const [show, setShow] = useState('login');
  const [tdsRegstration, setTdsRegistration] = useState(null);
  const [tenencyList, setTenancyList] = useState([
    { name: "Currently Renting", key: "currently_renting" },
    { name: "Not Renting", key: "not_renting" },
    {name: "Moving in next 30 days", key: "next_30"},
    { name: "N/A", key: "N/A" },
  ]);
  const [showModal, setShowModal] = useState(false);
  const [reset_email, setResetEmail] = useState('');
  const [resetemailErrorMsg, setEmailErrorMsg] = useState('');
  const _onResetPassword = () => {
    let emailCheck = EmailValidation(reset_email);
    if (emailCheck.status) {
      dispatch(
        resetPassword(
          {
            email: reset_email,
            user_type: 0,
          },
          props.navigation.navigate,
          () => {
            setResetEmail('');
            setShowModal(false);
          }
        )
      );
    } else {
      setEmailErrorMsg(emailCheck.status ? '' : emailCheck.msg);
    }
  };
  const [state, setState] = useState({
    tncStatus: false,
    contactStatus: false,
    buyerEmail: '',
    buyerPassword: '',
    buyerEmailErrorMessage: '',
    buyerPasswordErrorMessage: '',
    selectedTab: 'Tab1',
  });
  const [secureText, setSecureText] = useState(true);
  React.useEffect(() => {
    if (props?.route?.params?.from) {
      setState({
        ...state,
        from: props?.route?.params?.from ? props?.route?.params?.from : '',
      });
    }
  }, [props?.route?.params?.from]);

  useEffect(() => {
    if (props?.route?.params?.email) {
      setState({
        ...state,
        buyerEmail: props?.route?.params?.email ? props?.route?.params?.email : '',
      });
    }
  }, [props?.route?.params?.email]);

  const dispatch = useDispatch();
  const _onLogin = () => {
    const { buyerEmail, buyerPassword } = state;
    let emailCheck = EmailValidation(state?.buyerEmail);

    let passwordCheck = PasswordValidation(state?.buyerPassword);
    if (emailCheck.status && passwordCheck.status) {
      dispatch(
        login(
          {
            email: buyerEmail.trim(),
            password: buyerPassword,
            user_type: 0,
          },
          props.navigation,
          (tdsUserData) => {
            setShow("tdsRegster")
            setTdsRegistration(tdsUserData)
          }
        )
      );
    } else {
      setState({
        ...state,
        buyerEmailErrorMessage: emailCheck.status ? '' : emailCheck.msg,
        buyerPasswordErrorMessage: passwordCheck.status ? '' : passwordCheck.msg,
      });
    }
  };
  const _onGoogleLogin = (googleResponse) => {
    if (googleResponse?.access_token) {
      dispatch(
        googleLogin(
          {
            accessToken: googleResponse?.access_token,
            platform: 'web',
            registered_from: 'web',
          },
          props.navigation
        )
      );
    } else {
      dispatch({
        type: types.SHOW_ALERT,
        text: "Google login failed with an unknown error, please contact support, in the meantime, you can login using email and password. If you've signed up using google, you can reset your password to continue",
      });
    }
  };
  const loginWithGoogle = useGoogleLogin({
    onSuccess: (tokenResponse) => _onGoogleLogin(tokenResponse),
  });
  const loginView = () => {
    return (
      <LoginCard>
        <ODSText textAlign="center" type="h3" color={colors.text.secondary}>
          Login
        </ODSText>
        <ODSText type="md" color={colors.text.secondary} marginTop={12} textAlign={'center'}>
          Don’t have an account?{' '}
          <TouchableOpacity onPress={() => setShow('register')}>
            <ODSText textDecorationLine="underline" type="md-bold" color={colors.main.purple[700]}>
              Register Now
            </ODSText>
          </TouchableOpacity>{' '}
        </ODSText>

        <View style={{ marginTop: 32, gap: 12 }}>
          <ODSInput
            label="Email"
            placeholder="email"
            onChange={(email) =>
              setState({
                ...state,
                buyerEmail: email,
                buyerEmailErrorMessage: '',
              })
            }
            hints={
              state?.buyerEmailErrorMessage == ''
                ? []
                : [{ hint: state?.buyerEmailErrorMessage, state: 'error' }]
            }
            value={state?.buyerEmail}
          />
          <ODSInput
            label="Password"
            placeholder="*****"
            secureTextEntry={secureText}
            onChange={(password) =>
              setState({
                ...state,
                buyerPassword: password,
                buyerPasswordErrorMessage: '',
              })
            }
            onKeyPress={(e) => {
              if (e.key == 'Enter') {
                _onLogin();
              }
            }}
            renderRightIcon={
              <TouchableOpacity
                onPress={() => {
                  setSecureText(!secureText);
                }}
              >
                <ODSIcon icon={secureText ? 'eye-blocked' : 'eye'} size={20} />
              </TouchableOpacity>
            }
            hints={
              state?.buyerPasswordErrorMessage == ''
                ? []
                : [{ hint: state?.buyerPasswordErrorMessage, state: 'error' }]
            }
          />
          <ODSButton
            themeColor={colors.main.purple}
            type="primary"
            restStyle={{ marginTop: 24 }}
            disabled={false}
            onPress={() => _onLogin()}
          >
            Login
          </ODSButton>
          <TouchableOpacity onPress={() => setShowModal(true)}>
            <ODSText
              marginTop={16}
              marginBottom={20}
              textAlign="center"
              textDecorationLine="underline"
              type="md-bold"
              color={colors.main.purple[700]}
            >
              Forgotten password?
            </ODSText>
          </TouchableOpacity>

          <View style={{ alignSelf: 'center' }}></View>
          <TouchableOpacity
            onPress={() => loginWithGoogle()}
            style={{
              height: 50,
              width: '100%',
              justifyContent: 'center',
              alignItems: 'center',
              boxShadow: '0px 0px 3px rgba(0, 0, 0, 0.084), 0px 2px 3px rgba(0, 0, 0, 0.168)',
              borderRadius: '10px',
              flexDirection: 'row',
            }}
          >
            <Image
              source={require('../../../openbrix-design-system/images/icons/Google.png')}
              style={{
                marginRight: 14,
                height: 24,
                width: 24,
              }}
            />
            <ODSText type="md-bold" color={'rgba(0, 0, 0, 0.54)'}>
              {' '}
              Continue with Google
            </ODSText>
          </TouchableOpacity>
          {/* <GoogleLogin
          clientId={GOOGELELOGIKEY}
          icon={true}
          id={"googlelogin"}
        
          onSuccess={(responseGoogle) => {
            _onGoogleLogin(responseGoogle);
          }}
          onFailure={(responseGoogle) => {
            _onGoogleLogin(responseGoogle);
          }}
          render={(renderProps) => (
            <TouchableOpacity
            onPress={renderProps.onClick}
            disabled={renderProps.disabled}
              style={{
                height: 50,
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                boxShadow: "0px 0px 3px rgba(0, 0, 0, 0.084), 0px 2px 3px rgba(0, 0, 0, 0.168)",
                borderRadius: "10px",
                flexDirection: "row",
                marginTop:16
              }}
            >
              <Image
                source={require("../../../openbrix-design-system/images/icons/Google.png")}
                style={{
                  marginRight: 14,
                  height: 24,
                  width: 24,
                }}
              />
              <ODSText type='md-bold' color={'rgba(0, 0, 0, 0.54)'}> Continue with Google</ODSText>
            </TouchableOpacity>
          )}
        /> */}
          <View
            style={{ width: '100%', height: 0.25, backgroundColor: '#2B3541', marginVertical: 30 }}
          />
          <View style={{ flexDirection: 'row', gap: 24, justifyContent: 'center' }}>
            <TouchableOpacity
              onPress={() => window.open('https://apps.apple.com/in/app/tlyfe/id6449941281')}
            >
              <Image
                source={require('../../../openbrix-design-system/images/appstore.png')}
                style={{ width: 138, height: 40 }}
              />
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() =>
                window.open(
                  'https://play.google.com/store/apps/details?id=com.tlyfe&hl=en_IN&gl=US'
                )
              }
            >
              <Image
                source={require('../../../openbrix-design-system/images/playstore.png')}
                style={{ width: 138, height: 40 }}
              />
            </TouchableOpacity>
          </View>
        </View>
      </LoginCard>
    );
  };
  const _onSignup = () => {
    const {
      buyerFirstName = "",
      buyerMobile = "",
      buyerLastName = "",
      tenancy_reg_status = ""
    } = state;

    let firstNameCheck = NameValidation(buyerFirstName);
    let lastNameCheck = NameValidation(buyerLastName);
    let mobileCheck = MobileValidation(buyerMobile);
    let tenancy_reg_statusCheck = NameValidation(tenancy_reg_status);


    if (
      mobileCheck.status &&
      firstNameCheck.status &&
      lastNameCheck.status &&
      tenancy_reg_statusCheck.status
    ) {
      setState({
        ...state,
        validateCheckbox: false,
      });
      updateData(buyerFirstName, buyerLastName, buyerMobile,tenancy_reg_status)
    } else {
      setState({
        ...state,
        buyerFirstNameErrorMsg: buyerFirstName ? "" : "This is a required field",
        buyerLastNameErrorMsg: buyerLastName ? "" : "This is a required field",
        buyerMobileErrorMsg: mobileCheck.status ? "" : mobileCheck.msg,
        tenancy_reg_statusMsg: tenancy_reg_status ? "" : "This is a required field"
      });
    }
  };
  const updateData = async (buyerFirstName, buyerLastName, buyerMobile,tenancy_reg_status) => {
    const payload = {
      user: {
        first_name: buyerFirstName,
        last_name: buyerLastName,
        contact_number: buyerMobile,
        user_id: tdsRegstration?.user_id,
        user_type: 0,
        is_tenant_concierge_service: state.is_tenant_concierge_service,
        tenancy_reg_status: tenancy_reg_status,
        title: '',
      }
    };
    const { buyerEmail, buyerPassword } = state;
    try {
      dispatch({ type: types.LOGIN_REQUEST });
      fetch(`${SERVER_URL}${URL.profile}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${tdsRegstration?.token}`
        },
        body: JSON.stringify(payload)
      })
        .then((response) => response.text())
        .then((result) => {
          if (result) {
            let ParsedResult = JSON.parse(result)
            if (ParsedResult.status) {
              dispatch(
                login(
                  {
                    email: buyerEmail.trim(),
                    password: buyerPassword,
                    user_type: 0,
                  },
                  props.navigation,
                  (tdsUserData) => {
                    setShow("tdsRegster")
                    setTdsRegistration(tdsUserData)
                  }
                )
              );
            }
          }
        });

    } catch (error) {
      console.error(error);
    }
  };
  const registerView = () => {
    const {

      buyerFirstNameErrorMsg,
      buyerMobileErrorMsg,
      buyerLastNameErrorMsg,
      is_tenant_concierge_service,
      tenancy_reg_statusMsg,
      tenancy_reg_status
    } = state;
    return (
      <RegisterCard>
        <ODSText textAlign="center" type="h3" color={colors.text.secondary}>
          Complete Registration
        </ODSText>
        <View style={{ marginTop: 32, gap: 32 }}>
          <View style={{ flexDirection: layout == "phone" ? 'column' : 'row', justifyContent: 'space-between', flexWrap: 'wrap', gap: 12, zIndex: 10000  }}>
            <ODSInput
              label="First Name"
              placeholder="first name"
              style={{ width: layout == 'phone' ? '100%' : 259 }}
              value={state?.buyerFirstName}
              onChange={(firstName) => {
                Regex.validateWithSpecialName(firstName) &&
                  setState({
                    ...state,
                    buyerFirstName: firstName,
                    buyerFirstNameErrorMsg: "",
                  });
              }}
              hints={buyerFirstNameErrorMsg == "" ? [] : [{ hint: buyerFirstNameErrorMsg, state: 'error' }]}
            />
            <ODSInput
              label="Last Name"
              placeholder="last name"
              style={{ width: layout == 'phone' ? '100%' : 259 }}
              value={state?.buyerLastName}
              onChange={(lastName) =>

                Regex.validateWithSpecialName(lastName) &&
                setState({
                  ...state,
                  buyerLastName: lastName,
                  buyerLastNameErrorMsg: "",
                })
              }
              hints={buyerLastNameErrorMsg == "" ? [] : [{ hint: buyerLastNameErrorMsg, state: 'error' }]}

            />
            <ODSInput
              label="Contact number (optional)"
              placeholder="contact"
              style={{ width: layout == 'phone' ? '100%' : 259 }}
              value={state?.buyerMobile}
              onChange={(mobile) =>
                setState({
                  ...state,
                  buyerMobile: mobile,
                  buyerMobileErrorMsg: "",
                })
              }
              keyboardType="number-pad"
              hints={buyerMobileErrorMsg == "" ? [] : [{ hint: buyerMobileErrorMsg, state: 'error' }]}

            />
            <View style={{ width: layout == 'phone' ? '100%' : 259,}}>
              <ODSCombobox
                width={"100%"}
                onChange={(value) => {
                  setState({
                    ...state,
                    buyerEmailErrorMsg: "",
                    tenancy_reg_statusMsg: "",
                    tenancy_reg_status:value.value
                  })
                }}
                hints={tenancy_reg_statusMsg == "" ? [] : [{ hint: tenancy_reg_statusMsg, state: 'error' }]}
                placeholder={tenancy_reg_status ? tenancy_reg_status : ''}
                label="What is your tenancy status? *"
                data={
                  tenencyList && tenencyList.length
                    ? tenencyList.map((salutation) => {
                      return { value: salutation.key, label: salutation.name };
                    })
                    : []
                } />

            </View>
          </View>
         
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              <ODSCheckbox checked={is_tenant_concierge_service} onPress={() =>
                setState({
                  ...state,
                  is_tenant_concierge_service: !state.is_tenant_concierge_service,
                })
              } />
              <View style={{ flexDirection: 'row', flexWrap: "wrap", flex: 1 }}>
                <ODSText marginLeft={8} type="sm" color={colors.text.secondary}>
                  I wish to have access to the free Home concierge service, to help with my tenancy{" "}
                  <TouchableOpacity onPress={() => window.open("https://my.home.cc/terms", '_blank')}>
                    <ODSText textDecorationLine="underline" type="md-bold" color={colors.main.purple[700]}>
                      (Terms apply)
                    </ODSText>
                  </TouchableOpacity>
                </ODSText>

              </View>
            </View>
            <ODSButton
              themeColor={colors.main.purple}
              type="primary"
              disabled={false}
              restStyle={{ width: 250, alignSelf: 'center' ,marginTop:20}}
              onPress={_onSignup}
            >
              Complete
            </ODSButton>
        </View>




      </RegisterCard>
    );
  };
  return (
    <>
      <Wrapper
        theme={colors.transparency.purple}
        type="login"
        drawerColor={colors.theme.purple}
        headerImage={layout == 'web' ? HeaderWeb : layout == 'tab' ? HeaderTab : HeaderMobile}
        footerImage={layout == 'web' ? FooterWeb : layout == 'tab' ? FooterTab : FooterMobile}
      >
        {show === 'login' && loginView()}
        {show === 'register' && <RegisterLanding changeView={() => setShow('login')} {...props} />}
        {show === 'tdsRegster' && registerView()}

        <ODSModal
          hasBackdrop
          visible={showModal}
          onBackdropPress={() => {
            setShowModal(false);
          }}
          backdropOpacity={0}
          onBackdropPress={() => setShowModal(false)}
        >
          <LoginCard style={{ marginTop: '8%' }}>
            <ODSText textAlign="center" type="h3" color={colors.text.secondary}>
              Reset Password
            </ODSText>
            <ODSText type="md" color={colors.text.secondary} marginTop={12} textAlign={'center'}>
              If the email you enter below is in our database, we’ll send you a link to reset your
              password safely
            </ODSText>
            <View style={{ marginTop: 32, gap: 16 }}>
              <ODSInput
                label="Email address"
                placeholder="email"
                onChange={(emailOfUser) => {
                  setResetEmail(emailOfUser);
                }}
                value={reset_email}
                hints={
                  resetemailErrorMsg == '' ? [] : [{ hint: resetemailErrorMsg, state: 'error' }]
                }
              />
              <ODSButton
                themeColor={colors.main.purple}
                type="primary"
                disabled={false}
                onPress={_onResetPassword}
                restStyle={{ marginTop: 12 }}
              >
                Reset Password
              </ODSButton>
              <ODSButton
                themeColor={colors.main.purple}
                type="secondary"
                disabled={false}
                onPress={() => setShowModal(false)}
              >
                Close
              </ODSButton>
            </View>
          </LoginCard>
        </ODSModal>
      </Wrapper>
    </>
  );
};
export default LoginLanding;