import React, { useEffect } from 'react';
import { Image, ImageBackground, StyleSheet, Text, View } from 'react-native';
import Modal  from "react-native-modal";
import { useDispatch, useSelector } from 'react-redux';
import { BoxShadow, Wrapper } from './styles';
import { getCcqStatus, removeAffordibility } from '../../redux/actions/preReferencing';
import { COLORS } from '../../constants/colorPallete';
import getPlatformType from '../../helpers/getPlatform';
import { Typography } from '../openFlow/style';
import WideButton from '../../commonComponents/wideButton';
import AffordNew from '../identifyAndFinance/affordNew';
import { CustomButton } from '../identifyAndFinance/stylesNew';
import { FontAwesome } from '@expo/vector-icons';
import { AntDesign } from '@expo/vector-icons';
import { navigate } from '../../navigation/RootNavigation';
import ODSBadges from '../../../openbrix-design-system/ui/atoms/ODSBadges';
import { ODSText } from '../../../openbrix-design-system/ui/atoms/ODSText';
import { getTokens } from '../../../openbrix-design-system/design-tokens/color-tokens';
import ODSButton from '../../../openbrix-design-system/ui/atoms/ODSButton';
import Portal from '../../../openbrix-design-system/ui/atoms/ODSModal/potral';

const PreQualification = (props) => {
  const [showModal, setShowModal] = React.useState(false);
  const themeObj = getTokens('light').colors;

  const layoutType = getPlatformType();

  const dispatch = useDispatch();
  const affordData = useSelector((state) => state?.preReferencing?.ccqData);

  useEffect(() => {
    dispatch(getCcqStatus());
  }, []);
  const removeAfford = (isGuarantor) => {
    dispatch(removeAffordibility(isGuarantor));
  };
const renderStatus =()=>{
  if(affordData?.affordibilityCheck?.affordbility_amount){
return(
  <ODSBadges restStyle={{ marginBottom: 10, alignSelf: 'flex-end' }} type={'Completed'}>
  Completed
</ODSBadges>
)}else if(affordData?.affordibilityCheck?.affordbility_amount|| affordData?.guarantorAffordibilityCheck?.affordbility_amount){
  return(
    <ODSBadges restStyle={{ marginBottom: 10, alignSelf: 'flex-end' }} type={'In Progress'}>
   In Progress
  </ODSBadges>
  )} else{
  return(
    <ODSBadges restStyle={{ marginBottom: 10, alignSelf: 'flex-end' }} type={'Not Started'}>
    Not Started
  </ODSBadges>
  )
}
 
}
  return (
    <>
      <Wrapper>
        <BoxShadow wWidth={'515px'} bottom={'10px'} right={'19px'}>
  {renderStatus()}
          <ODSText style={{ alignSelf: 'center' }} type={'h5'}>
            How much can you afford?
          </ODSText>
          <ODSText
            color={themeObj.neutral[700]}
            marginTop={20}
            style={{ alignSelf: 'center' }}
            type={'md'}
          >
            Affordability is based upon your annual income. To calculate your affordability, enter
            your annual income and we will tell you how much rental you can afford.
          </ODSText>
          <ODSText
            color={themeObj.neutral[700]}
            marginTop={20}
            style={{ alignSelf: 'center' }}
            type={'md'}
          >
            If you feel you can pay more than the calculation advises, your agent may take this into
            consideration but may ask you to provide a guarantor.
          </ODSText>
          <ODSText
            color={themeObj.neutral[700]}
            marginTop={20}
            style={{ alignSelf: 'center' }}
            type={'md'}
          >
            Affordability is the first step towards giving yourself the best chance of securing your
            dream rental property and to place yourself ahead of the competition.
          </ODSText>
          <ODSText
            color={themeObj.neutral[700]}
            marginTop={20}
            style={{ alignSelf: 'center' }}
            type={'md'}
          >
            Powered by
          </ODSText>

          <ImageBackground
            source={require('./../../assets/images/Image.png')}
            style={{
              height: 89,
              width: 238,
            }}
            resizeMode={'cover'}
          />
        </BoxShadow>
        <View style={{ flexDirection: 'column' }}>
          <BoxShadow wWidth={'515px'}>
            <ODSText style={{ alignSelf: 'center' }} type={'button'}>
              Affordability Amount:
            </ODSText>
           {affordData?.affordibilityCheck?.affordbility_amount? <ODSText
              color={themeObj.text['secondary']}
              marginTop={'6px'}
              style={{ alignSelf: 'center' }}
              type={'md'}
            >
              {affordData?.affordibilityCheck?.affordbility_amount
                ? '£' + affordData?.affordibilityCheck?.affordbility_amount.toFixed(2)
                : ''}
            </ODSText>:null}

            {!affordData?.affordibilityCheck?.affordbility_amount ? (
              <View style={{ marginTop: 10 }}>
               
                  <ODSButton
                  restStyle={{ width: 230, height: 40, alignSelf: 'flex-end'  }}
                  type="primary"
                  disabled={false}
                  onPress={() => setShowModal('aff')}
                >
                 Get started
                </ODSButton>
                 <ODSText
                  color={themeObj.neutral[700]}
                  marginTop={10}
                  style={{ alignSelf: 'center' }}
                  type={'xs'}
                >
                Individual agents may have different affordability criteria. 
                </ODSText>
                <ODSText
                  color={themeObj.neutral[700]}
                  marginTop={10}
                  style={{ alignSelf: 'center' }}
                  type={'xs'}
                >
               You are ‘self certifying’ your income. Based on the information you have provided, FCC Paragon can provisionally accept that you can afford this rent, providing you pass either a rent ready reference, digital reference or full reference,
                </ODSText>
        
              </View>
            ) : (
              <>
                <ODSText
                  color={themeObj.neutral[700]}
                  marginTop={10}
                  style={{ alignSelf: 'center' }}
                  type={'xs'}
                >
                  If you believe you can afford more but can’t prove your income, you can get a
                  guarantor. You can check their affordability below.
                </ODSText>
                <ODSButton
                  rIcon={'TB-Rotating'}
                  restStyle={{ marginTop: 20,  width: 230, height: 40, alignSelf: 'flex-end'}}
                  type="secondary"
                  themeColor={themeObj.main.pink}
                  disabled={false}
                  onPress={() => removeAfford()}
                >
                  Restart
                </ODSButton>
              </>
            )}
          </BoxShadow>
          <BoxShadow wWidth={'515px'} style={{ marginTop: 20 }}>
            
               <ODSText style={{ alignSelf: 'center' }} type={'button'}>
               Guarantor Affordability Amount:
            </ODSText>
            {affordData?.guarantorAffordibilityCheck?.affordbility_amount? <ODSText
              color={themeObj.text['secondary']}
              marginTop={'6px'}
              style={{ alignSelf: 'center' }}
              type={'md'}
            >
              {affordData?.guarantorAffordibilityCheck?.affordbility_amount
                  ? '£' + affordData?.guarantorAffordibilityCheck?.affordbility_amount.toFixed(2)
                  : ''}
            </ODSText>:null}
            {!affordData?.guarantorAffordibilityCheck?.affordbility_amount ? (
              <View style={{ marginTop: 10 }}>
                 <ODSButton
                  restStyle={{  width: 230, height: 40, alignSelf: 'flex-end' }}
                  
                  type="primary"
                  disabled={false}
                  onPress={() => setShowModal('aff_guarantor')}
                >
                 Get started
                </ODSButton>
               
                <ODSText
                  color={themeObj.neutral[700]}
                  marginTop={10}
                  style={{ alignSelf: 'center' }}
                  type={'xs'}
                >
                   A guarantor may be necessary if you consider you will not meet the referencing
                  criteria or you are not able to prove sufficient income to afford the rental.
                </ODSText>
                <ODSText
                  color={themeObj.neutral[700]}
                  marginTop={10}
                  style={{ alignSelf: 'center' }}
                  type={'xs'}
                >
                   Individual agents may have different affordability criteria
                </ODSText>
                <ODSText
                  color={themeObj.neutral[700]}
                  marginTop={10}
                  style={{ alignSelf: 'center' }}
                  type={'xs'}
                >
                    You are "self certifying" your guarantor's income. Based on the information you
                  have provided. FCC Paragon can provisional accept that you can afford to guarantee
                  this rent, providing your pass either a pre-qualification reference, digital
                  reference or full reference.
                </ODSText>
              
              </View>
            ) : (
              <>
               <ODSText
                  color={themeObj.neutral[700]}
                  marginTop={10}
                  style={{ alignSelf: 'center' }}
                  type={'xs'}
                >
                    A guarantor may be necessary if you consider you will not meet the referencing
                  criteria or you are not able to prove sufficient income to afford the rental.
                </ODSText>
                <ODSText
                  color={themeObj.neutral[700]}
                  marginTop={10}
                  style={{ alignSelf: 'center' }}
                  type={'xs'}
                >
                    Individual agents may have different affordability criteria
                </ODSText>
                <ODSText
                  color={themeObj.neutral[700]}
                  marginTop={10}
                  style={{ alignSelf: 'center' }}
                  type={'xs'}
                >
                 You are "self certifying" your guarantor's income. Based on the information you
                  have provided. FCC Paragon can provisional accept that you can afford to guarantee
                  this rent, providing your pass either a pre-qualification reference, digital
                  reference or full reference.
                </ODSText>
                <ODSButton
                  rIcon={'TB-Rotating'}
                  restStyle={{ marginTop: 20,  width: 230, height: 40, alignSelf: 'flex-end'}}
                  type="secondary"
                  themeColor={themeObj.main.pink}
                  disabled={false}
                  onPress={() => removeAfford(true)}
                >
                  Restart
                </ODSButton>
              
              </>
            )}
            
          </BoxShadow>
          {affordData?.affordibilityCheck?.affordbility_amount ?   (
            <>
             <ODSButton
          rIcon={'TB-Share'}
          restStyle={{ width: 300, marginTop: 20,alignSelf:"center" }}
          type="secondary"
          themeColor={themeObj.main.pink}
          disabled={false}
          onPress={() => navigate('Document Share Center',{activeIndex: 2 })}
        >
          My Share Centre
        </ODSButton>
        <ODSButton
         
          restStyle={{ width: 300, marginTop: 20,alignSelf:"center"  }}
          type="primary"
          themeColor={themeObj.main.pink}
          disabled={false}
          onPress={() => navigate('PreQualificationTenantInfo')}
        >
         Next: Tenant Information
        </ODSButton>
            
            </>
          ):null}
        </View>
      </Wrapper>
      <Portal>
      <Modal
        isVisible={showModal}
        transparent={true}
        coverScreen={true}
        hasBackdrop={true}
        backdropOpacity={0}
        onBackdropPress={() => setShowModal(false)}
        style={{
          margin: 0,
          width: '100%',
          flex: 1,
          backgroundColor: '#00000080',
          paddingVertical: 28,
          paddingHorizontal: 23,
        }}
      >
        <View
          style={{
            width: '365px',
            backgroundColor: '#FAFAFA',
            borderRadius: 20,
            overflow: 'hidden',
            alignSelf: 'center',
          }}
        >
          <AffordNew
            showClose={true}
            isGuarantor={showModal == 'aff_guarantor'}
            onCloseClick={() => {
              setShowModal(false);
            }}
          />
        </View>
      </Modal>
      </Portal>
    </>
  );
};
const styles = StyleSheet.create({});
export default PreQualification;
