import React, { useState } from 'react';
import { View, StyleSheet, TextInput, TouchableOpacity } from 'react-native';
import { Typography } from '../openFlow/style';
import getPlatformType from '../../helpers/getPlatform';
import NameAvatar from '../LandingPages/common/NameAvatar';
import { timeAgo } from '../../helpers/functionHelpers';
import { MaterialIcons } from '@expo/vector-icons';
import { COLORS } from '../../constants/colorPallete';
import { ODSText } from '../../../openbrix-design-system/ui/atoms/ODSText';
import { useTheme } from '@emotion/react';
export const renderComments = (item,profile,onDelete,colors) => {
  const layout = getPlatformType();
  return (
    <View
      style={{
        width: layout == 'phone' ? '100%' : '50%',
        borderBottomColor: '#D0D6DD',
        borderBottomWidth: 1,
        paddingVertical: 25,
        paddingHorizontal: 5,
      }}
    >
      <View style={{ flexDirection: 'row', alignItems: 'center' ,justifyContent:'space-between'}}>
       <View style={{flexDirection:'row',alignItems:'center'}}>
        <NameAvatar size={40} name={item?.user?.first_name+' '+item?.user?.last_name} verified={item?.user?.app_user?.idverification==2?true:false}  />
        <ODSText type='md-bold' marginLeft={10} color={colors.text.primary}>{item?.user?.first_name+' '+item?.user?.last_name}</ODSText>
        </View>
       {item?.user?.app_user?.app_user_id==profile?.appUser?.app_user_id &&
        <TouchableOpacity onPress={()=>onDelete(item?.comment_id)} >
         <MaterialIcons name="delete" size={20} color={COLORS.LGREEN} />
        </TouchableOpacity>}
        
      </View>

      <ODSText type='md' marginTop={10} color={colors.text.primary}>{item?.comment}</ODSText>
      <ODSText type='sm' marginTop={20} color={colors.disabled.text}>{timeAgo(item.createdAt)}</ODSText>
    </View>
  );
};
const styles = StyleSheet.create({});
